import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import { Context } from "../../Utils/Context";

// mutation for  company payroll default data
const DEFAULT_PAYROLL = gql`
  mutation(
    $IsMetro: String!
    $FBP: String!
    $AdminBank: String!
    $PayMode: String
    $IsEPSentitle: String!
    $HasESI: String!
  ) {
    UpdateOrganisationDefaultPayroll(
      IsMetro: $IsMetro
      FBP: $FBP
      AdminBank: $AdminBank
      PayMode: $PayMode
      IsEPSentitle: $IsEPSentitle
      HasESI: $HasESI
    ) {
      Message
      Confirmed
    }
  }
`;
// mutation for  company access default data
const ACCESS_DEFAULT = gql`
  mutation(
    $ScheduleGroupID: String!
    $WeekOffGroupID: String!
    $LeaveGroup: String!
  ) {
    UpdateOrganisationDefaultAccess(
      ScheduleGroupID: $ScheduleGroupID
      WeekOffGroupID: $WeekOffGroupID
      LeaveGroup: $LeaveGroup
    ) {
      Message
      Confirmed
    }
  }
`;

export default class DefaultCompConfig extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      // state for payroll default data
      isMetro: "",
      fbp: "",
      adminBank: "",
      payMode: "",
      isEPSentitle: "",
      hasESI: "",
      // state for access default data
      scheduleGroupID: "",
      weekOffGroupID: "",
      leaveGroup: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Function to handle company default payroll data

  handleProllDflt(data) {
    console.log(data.UpdateOrganisationDefaultPayroll);
    if (data.UpdateOrganisationDefaultPayroll.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Payroll default details added Successfully",
        "Successful!",
        2000
      );
    } else if (data.UpdateOrganisationDefaultPayroll.Confirmed === "1") {
      NotificationManager.error(
        "Error while adding Payroll default data",
        "Error!"
      );
    }
  }

  // Function to handle company access default  data

  handleAccessDflt(data) {
    console.log(data.UpdateOrganisationDefaultAccess);
    if (data.UpdateOrganisationDefaultAccess.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Access default details added Successfully",
        "Successful!",
        2000
      );
    } else if (data.UpdateOrganisationDefaultAccess.Confirmed === "1") {
      NotificationManager.error(
        "Error while adding Access default data",
        "Error!"
      );
    }
  }

  async componentDidMount() {
    // Query to fetch payroll default details data
    const GET_PROLL_DFLT = gql`
      {
        GetOrganisationDefaultPayroll {
          AdminBank
          FBP
          Paymode
          IsMetro
          IsEPSEntitle
          HasESI
        }
      }
    `;
    var res = await this.context.client.query({
      query: GET_PROLL_DFLT,
    });
    console.log(res);
    this.setState({
      isMetro: res.data?.GetOrganisationDefaultPayroll[0]?.IsMetro,
    });
    this.setState({
      fbp: res.data?.GetOrganisationDefaultPayroll[0]?.FBP,
    });
    this.setState({
      adminBank: res.data?.GetOrganisationDefaultPayroll[0]?.AdminBank,
    });
    this.setState({
      payMode: res.data?.GetOrganisationDefaultPayroll[0]?.Paymode,
    });
    this.setState({
      isEPSentitle: res.data?.GetOrganisationDefaultPayroll[0]?.IsEPSEntitle,
    });
    this.setState({
      hasESI: res.data?.GetOrganisationDefaultPayroll[0]?.HasESI,
    });

    // query to fetch access default data

    const GET_ACCESSS_DFLT = gql`
      {
        GetOrganisationDefaultAccess {
          WeekOffGroupID
          ScheduleGroupID
          LeaveGroup
        }
      }
    `;
    var resAcsDflt = await this.context.client.query({
      query: GET_ACCESSS_DFLT,
    });
    console.log(resAcsDflt);
    this.setState({
      scheduleGroupID:
        resAcsDflt.data?.GetOrganisationDefaultAccess[0]?.ScheduleGroupID,
    });
    this.setState({
      weekOffGroupID:
        resAcsDflt.data?.GetOrganisationDefaultAccess[0]?.WeekOffGroupID,
    });
    this.setState({
      leaveGroup: resAcsDflt.data?.GetOrganisationDefaultAccess[0]?.LeaveGroup,
    });
  }
  render() {
    return (
      <div className="card cmp-card-wid ">
        <div className="card-header cmp-headr-styl">
          Default Company Configuration
        </div>
        <div className="card-body">
          <div className="card">
            <div className="card-header branch-headr-styl">
              Payroll Default Data
            </div>
            <div className="card-body">
              <div className="row">
                <div class="form-group col">
                  <label className="labl-font">FBP</label>
                  <input
                    type="text"
                    name="fbp"
                    onChange={this.handleChange}
                    value={this.state.fbp}
                    class="form-control fld-wid "
                    placeholder="FBP"
                  />
                </div>
                <div class="form-group col">
                  <label className="labl-font">Admin Bank</label>
                  <input
                    type="text"
                    name="adminBank"
                    onChange={this.handleChange}
                    value={this.state.adminBank}
                    class="form-control fld-wid"
                    placeholder="Admin Bank"
                  />
                </div>
              </div>
              <div className="row">
                <div class="form-group col">
                  <label className="labl-font">Pay Mode</label>
                  <input
                    type="text"
                    name="payMode"
                    onChange={this.handleChange}
                    value={this.state.payMode}
                    class="form-control fld-wid"
                    placeholder="Pay Mode"
                  />
                </div>
                <div class="form-group col">
                  <label className="labl-font">Is Metro</label>
                  <form className="col info-col">
                    <select
                      name="isMetro"
                      onChange={this.handleChange}
                      value={this.state.isMetro}
                      class="custom-select gender-select"
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </form>
                </div>
              </div>
              <div className="row">
                <div class="form-group col">
                  <label className="labl-font">Is EPS Entitled</label>
                  <form className="col info-col">
                    <select
                      name="isEPSentitle"
                      onChange={this.handleChange}
                      value={this.state.isEPSentitle}
                      class="custom-select gender-select"
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </form>
                </div>
                <div class="form-group col">
                  <label className="labl-font">Has ESI</label>
                  <form className="col info-col">
                    <select
                      name="hasESI"
                      onChange={this.handleChange}
                      value={this.state.hasESI}
                      class="custom-select gender-select"
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </form>
                </div>
              </div>
              <div className="marg-lft-15">
                <Mutation
                  mutation={DEFAULT_PAYROLL}
                  onCompleted={(data) => {
                    this.handleProllDflt(data);
                  }}
                >
                  {(addProllDflt, { data, loading, error }) => (
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={(e) => {
                          e.preventDefault();
                          addProllDflt({
                            variables: {
                              IsMetro: this.state.isMetro,
                              FBP: this.state.fbp,
                              AdminBank: this.state.adminBank,
                              PayMode: this.state.payMode,
                              IsEPSentitle: this.state.isEPSentitle,
                              HasESI: this.state.hasESI,
                            },
                          });
                        }}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </Mutation>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header branch-headr-styl">
              Access Default Data
            </div>
            <div className="card-body">
              <div className="row">
                <div class="form-group col">
                  <label className="labl-font">Schedule Group Id</label>
                  <input
                    type="text"
                    name="scheduleGroupID"
                    onChange={this.handleChange}
                    value={this.state.scheduleGroupID}
                    class="form-control "
                    placeholder="Schedule Group Id"
                  />
                </div>
                <div class="form-group col">
                  <label className="labl-font">Week Off Group</label>
                  <input
                    type="text"
                    name="weekOffGroupID"
                    onChange={this.handleChange}
                    value={this.state.weekOffGroupID}
                    class="form-control "
                    placeholder="Week Off Group"
                  />
                </div>
              </div>
              <div className="row">
                <div class="form-group col">
                  <label className="labl-font">Leave Group</label>
                  <input
                    type="text"
                    name="leaveGroup"
                    onChange={this.handleChange}
                    value={this.state.leaveGroup}
                    class="form-control acess-fld-wid"
                    placeholder="Leave Group"
                  />
                </div>
              </div>
              <div className="marg-lft-15">
                <Mutation
                  mutation={ACCESS_DEFAULT}
                  onCompleted={(data) => {
                    this.handleAccessDflt(data);
                  }}
                >
                  {(addAccessDflt, { data, loading, error }) => (
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary "
                        onClick={(e) => {
                          e.preventDefault();
                          addAccessDflt({
                            variables: {
                              ScheduleGroupID: this.state.scheduleGroupID,
                              WeekOffGroupID: this.state.weekOffGroupID,
                              LeaveGroup: this.state.leaveGroup,
                            },
                          });
                        }}
                      >
                        Save
                      </button>
                      {/* {data && this.handleAccessDflt(data)} */}
                    </div>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
