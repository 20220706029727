import React, { Component } from "react";
import EmployeeDataService from "../../Services/EmployeeData";
import queryString from "query-string";
import "./EmployeeData.css";
import { times } from "chartist";

export default class EmployeeData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenExpiry: "",
      // logoutQb: "",
      btnText: "Login to Quickbook",
      btnColor: false,
    };
  }

  componentDidMount() {
    let queryparamobj = queryString.parse(this.props.location.search);
    console.log(queryparamobj);

    if (queryparamobj.token === "true") {
      let tokenExpiryTime = new Date().getTime() + 120000;
      localStorage.setItem("tokenExpiry", tokenExpiryTime);

      this.setState({ btnText: "LoggedIn" });
      this.interval = setInterval(() => {
        console.log("Interval triggered");
        if (tokenExpiryTime < new Date().getTime()) {
          this.setState({ btnText: "Login to Quickbook" });
          this.setState({ btnColor: true });
          localStorage.removeItem("tokenExpiry");
          clearInterval(this.interval);
        }
      }, 1000);
    } else {
      if (typeof localStorage.getItem("tokenExpiry") !== "null") {
        if (localStorage.getItem("tokenExpiry") < new Date().getTime()) {
          this.setState({ btnText: "Login to Quickbook" });
          this.setState({ btnColor: true });
          localStorage.removeItem("tokenExpiry");
        } else if (localStorage.getItem("tokenExpiry") > new Date().getTime()) {
          this.setState({ btnText: "LoggedIn", btnColor: false });

          this.interval = setInterval(() => {
            console.log("Interval triggered");
            if (localStorage.getItem("tokenExpiry") < new Date().getTime()) {
              this.setState({ btnText: "Login to Quickbook" });
              this.setState({ btnColor: true });
              localStorage.removeItem("tokenExpiry");
              clearInterval(this.interval);
            }
          }, 1000);
        }
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  // function to make rest api call
  connect2Qb = () => {
    fetch("https://onboarding.kibbcom.in:10443/connect2qb")
      .then((response) => response.text())
      .then((response) => {
        console.log(response);
        window.location = response.substring(24);
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="empData-div">
        {this.props.location.state?.backBtnEnable !== "undefined" &&
        this.props.location.state?.backBtnEnable === "true" ? (
          <div style={{ textAlign: "right" }}>
            <button className="btn btn-primary" onClick={this.goBack}>
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              Back
            </button>
          </div>
        ) : (
          <button
            className={
              this.state.btnColor ? "btn btn-warning" : "btn btn-success"
            }
            onClick={this.connect2Qb}
          >
            <img
              src="/Images/qb_logo.png"
              width="20"
              height="20"
              alt="Quickbooks"
              style={{ marginRight: "10px" }}
            />
            {this.state.btnText}
          </button>
        )}

        <div id="all" class="card allEmp-card ">
          <div class="card-header allEmp-header">
            Employee Mangement table {this.state.tokenExpiry}{" "}
          </div>
          <div class="card-body emp-body">
            <EmployeeDataService />
          </div>
        </div>
      </div>
    );
  }
}
