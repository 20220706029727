import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { NotificationManager } from "react-notifications";
import gql from "graphql-tag";
import { Context } from "../../Utils/Context";

const OFFICE_INFO = gql`
  mutation OfficeInfo(
    $EmpID: String!
    $Branch: String
    $Department: String
    $IIReportingManager: String
    $IIReportingManageremail: String
    $IsReportingManager: String
  ) {
    OfficeInfo(
      EmpID: $EmpID
      Branch: $Branch
      Department: $Department
      IIReportingManager: $IIReportingManager
      IIReportingManageremail: $IIReportingManageremail
      IsReportingManager: $IsReportingManager
    ) {
      Message
      Confirmed
    }
  }
`;

export default class OfficeInfo extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    if (!localStorage.hasOwnProperty("idofcmail")) {
      localStorage.setItem(
        "idofcmail",
        JSON.stringify(this.props.empDet.location.idofcmail)
      );
    }

    this.state = {
      empID: "",
      branch: "",
      department: "",
      IIReportingManager: "",
      IIReportingManageremail: "",
      isReportingManager: "",

      branchDetails: [],
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOfcInfoData(data) {
    console.log(data);
    if (data.OfficeInfo.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Data added Successfully",
        "Successful!",
        2000
      );
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else if (data.OfficeInfo.Confirmed === "1") {
      NotificationManager.error(
        "Error while adding Office Info data",
        "Error!"
      );
    }
  }

  async componentDidMount() {
    // Query to fetch company branch
    const GET_BRANCH = gql`
      {
        GetManageBranch {
          BranchName
          BranchLocation
          BranchCode
          InitialID
          CountryCode
        }
      }
    `;
    const resBranch = await this.context.client.query({
      query: GET_BRANCH,
    });

    console.log(resBranch);
    if (resBranch.data && resBranch.data.GetManageBranch) {
      this.setState({ branchDetails: [...resBranch.data.GetManageBranch] });
    }

    // Query to fetch payroll config data
    let id;
    if (this.props?.empDet?.location?.idofcmail?.id !== "undefined") {
      id = this.props?.empDet?.location?.idofcmail?.id;
    }
    if (localStorage.hasOwnProperty("idofcmail")) {
      console.log(localStorage.getItem("idofcmail") != "");
      console.log(JSON.parse(localStorage.getItem("idofcmail")).id);
      id = JSON.parse(localStorage.getItem("idofcmail")).id;
    }

    //query to fetch office info data
    const GET_OFC_INFO = gql`
      {
        GetOfficeInfo(EmpID: "${id}") {
          Branch
          Department
          IIReportingManager
          IIReportingManageremail
          IsReportingManager
        }
      }
    `;

    var res = await this.context.client.query({
      query: GET_OFC_INFO,
    });
    console.log(res);
    Object.keys(res.data.GetOfficeInfo[0]).forEach(
      (k) =>
        (res.data.GetOfficeInfo[0][k] =
          res.data.GetOfficeInfo[0][k] === "N/A"
            ? ""
            : res.data.GetOfficeInfo[0][k])
    );

    this.setState({
      branch: res.data.GetOfficeInfo[0].Branch,
    });
    this.setState({
      department: res.data.GetOfficeInfo[0].Department,
    });
    this.setState({
      IIReportingManager: res.data.GetOfficeInfo[0].IIReportingManager,
    });
    this.setState({
      IIReportingManageremail:
        res.data.GetOfficeInfo[0].IIReportingManageremail,
    });
    this.setState({
      isReportingManager: res.data.GetOfficeInfo[0].IsReportingManager,
    });
  }

  componentWillUnmount() {
    localStorage.removeItem("idofcmail");
  }

  render() {
    return (
      <div class="card">
        <div class="card-header ofcInfo-header"> Office Information</div>
        <div class="card-body">
          <div class="row identity-info">
            <span class="ofcspan-text">Branch</span>
            <div className="col info-col">
              <select
                name="branch"
                value={this.state.branch}
                onChange={this.handleChange}
                class="custom-select gender-select"
              >
                <option value="" disabled>
                  Select
                </option>

                {this.state.branchDetails.length > 0
                  ? this.state.branchDetails.map((branch,i) => (
                      <option value={branch.BranchName} key={i}>
                        {branch.BranchName}
                      </option>
                    ))
                  : ""}
              </select>
            </div>

            <span class="ofcsecondcoltxt-wid">Department</span>
            <div class="col info-col">
              <input
                type="email"
                name="department"
                value={this.state.department}
                onChange={this.handleChange}
                class="form-control"
                placeholder="Department"
              />
            </div>
          </div>
          <div class="row identity-info">
            <span class="ofcspan-text">II Reporting Manager</span>
            <div class="col info-col">
              <input
                type="text"
                name="IIReportingManager"
                value={this.state.IIReportingManager}
                onChange={this.handleChange}
                class="form-control "
                placeholder="IIReportingManager"
              />
            </div>

            <span class="ofcsecondcoltxt-wid">II Reporting Manager Email</span>
            <div class="col info-col">
              <input
                type="email"
                name="IIReportingManageremail"
                value={this.state.IIReportingManageremail}
                onChange={this.handleChange}
                class="form-control"
                placeholder="abc@kibbcom.in"
              />
            </div>
          </div>
          <div class="row identity-info">
            <span class="ofcspan-text">Is Reporting Manager</span>
            <form className="col info-col">
              <select
                name="isReportingManager"
                value={this.state.isReportingManager}
                onChange={this.handleChange}
                class="custom-select gender-select"
              >
                <option disabled value="">
                  Select
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </form>
            <span class="ofcsecondcoltxt-wid"></span>
            <div class="col info-col"></div>
          </div>
        </div>
        <div className="button-div">
          <div>
            <Mutation
              mutation={OFFICE_INFO}
              onCompleted={(data) => {
                this.handleOfcInfoData(data);
              }}
            >
              {(addOfcInfo, { data, loading, error }) => (
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      addOfcInfo({
                        variables: {
                          EmpID: localStorage.hasOwnProperty("idofcmail")
                            ? JSON.parse(localStorage.getItem("idofcmail")).id
                            : this.props?.empDet?.location?.idofcmail?.id,
                          Branch: this.state.branch,
                          Department: this.state.department,
                          IIReportingManager: this.state.IIReportingManager,
                          IIReportingManageremail: this.state
                            .IIReportingManageremail,
                          IsReportingManager: this.state.isReportingManager,
                        },
                      });
                    }}
                  >
                    Save
                  </button>
                  {/* {data && this.handleOfcInfoData(data)} */}
                </div>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    );
  }
}
