import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Context } from "../../Utils/Context";
import { NotificationManager } from "react-notifications";
import "./ForgotPasswordEmail.css";

export default class ForgotPasswordEmail extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      enableSubmit: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  requestResetPasswordMail = async () => {
    let GET_MAIL = gql`
      {
        GetEmail(Email: "${this.state.email}") {
          Message
          Confirmed
        }
      }
    `;
    var res = await this.context.client.query({
      query: GET_MAIL,
    });
    console.log(res);
    if (res.data.GetEmail.Confirmed === "0") {
      NotificationManager.success(
        "Email sent successfully",
        "Successful!",
        2000
      );
      // this.props.history.push("/");
    } else if (res.data.GetEmail.Confirmed === "1") {
      NotificationManager.error("Email not found", "Error!");
    }
  };

  render() {
    const enableSubmit = this.state.email.length > 0;
    return (
      <div className="div-style">
        <div className="left-style">
          <img
            src="/Images/forgot-password-initial.jpg"
            alt="forgot password"
            className="img-style"
          />
        </div>
        <div className="right-style">
          <div className="align-centre">
            <img
              src="/Images/forgotpwd.png"
              alt="forgot password"
              style={{
                width: "135px",
                height: "124px",
                borderRadius: "50%",
                marginTop: "5vh",
              }}
            />
          </div>
          <div className="forgotPwd-style">Forgot Password ?</div>
          <div className="card card-forgotmail">
            <div className="card-body">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="mail@kibbcom.in"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="btn-centre">
                <button
                  disabled={!enableSubmit}
                  className="btn btn-primary"
                  type="button"
                  onClick={this.requestResetPasswordMail}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
