import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import { Button, Modal } from "react-bootstrap";
import EmployeeProfile from "../../Services/EmpProfileData";
import { Context } from "../../Utils/Context"
import * as FileSaver from 'file-saver';
// Mutation for adding employee details(self-onboarding)
const ADD_EMP_DATA = gql`
  mutation(
    $PAN: String
    $EmpID: String!
    $AdhaarNo: String!
    $VisaNo: String!
    $PassportNo: String!
    $AdhaarBase64: String!
    $PANBase64: String!
    $PassportBase64: String
    $VisaBase64: String
  ) {
    OtherDet(
      EmpID: $EmpID
      PAN: $PAN
      AdhaarNo: $AdhaarNo
      VisaNo: $VisaNo
      PassportNo: $PassportNo
      AdhaarBase64: $AdhaarBase64
      PANBase64: $PANBase64
      PassportBase64: $PassportBase64
      VisaBase64: $VisaBase64
    ) {
      Message
      Confirmed
    }
  }
`;

export default class OtherDetailTab extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      adharImg: "",
      panImg: "",
      visaImg: "",
      passportImg: "",
      executeEmpService: true,
      updateText: false,
      errorMsg: {},
      EmpID:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).EmpId
          : this.props.empData.EmpID,
      PAN:
        typeof this.props?.empData == "undefined" ? "" : this.props.empData.PAN,
      AdhaarNo:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.AdhaarNo,
      PassportNo:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PassportNo,
      VisaNo:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.VisaNO,
        uploadedAadharImg: "",
        uploadedPanImg: "",
        uploadedVisaImg: "",
        uploadedPassportImg: "",    
        docmodal:false, 
        modalDoc:'',   
    };
  }

  async componentDidMount() { console.log("hello",this.props?.empData?.EmpID);
    //Query to fetch payroll config data
    const GET_UPLOADED_DOC = gql`{
      GetDocBase64String(EmpID: "${this.props?.empData?.EmpID ?? JSON.parse(localStorage.getItem("EmployeeData")).EmpId}") {
        AdhaarBase64
        PANBase64
        PassportBase64
        VisaBase64
      }
    }`;
    var res = await this.context.client.query({ query: GET_UPLOADED_DOC });
    //console.log(res);
    if(res?.data?.GetDocBase64String.length>0){
      this.setState({
        uploadedAadharImg: res?.data?.GetDocBase64String[0]?.AdhaarBase64 ?? '',
        uploadedPanImg: res?.data?.GetDocBase64String[0]?.PANBase64 ?? '',
        uploadedPassportImg: res?.data?.GetDocBase64String[0]?.PassportBase64 ?? '',
        uploadedVisaImg: res?.data?.GetDocBase64String[0]?.VisaBase64 ?? '',
      })
    }
  }  
  // Function to handle mutation for other details data
  handleMutation = (data) => {
    if (data.OtherDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Other data added Successfully",
        "Successful!",
        2000
      );
    } else if (data.OtherDet.Confirmed === "1") {
      NotificationManager.error("Error while adding data", "Error!");
    }
  };

  //Function to handle change
  handleChange = (e) => {
    if (e.target.name === "AdhaarNo" || e.target.name === "VisaNo") {
      this.setState({ [e.target.name]: e.target.value.replace(/[^0-9]/g, "") });
      if (!/^[0-9]+$/.test(e.target.value)) {
        this.setState({
          errorMsg: { ...this.state.errorMsg, [e.target.name]: e.target.name },
        });
      } else {
        if (this.state.errorMsg.hasOwnProperty(e.target.name)) {
          const { [e.target.name]: name, ...newObject } = this.state.errorMsg;
          this.setState({ errorMsg: {} });
          this.setState({ errorMsg: newObject });
          console.log(this.state.errorMsg);
        }
      }
    } else this.setState({ updateText: true });
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
      if (this.state.AdhaarNo.length > 0 && this.state.PAN.length > 0)
        this.props.parentCallback("bank");
    });
    if (e.target.name === "adharImg" || e.target.name === "panImg") {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let name = e.target.name;
      reader.onload = () => {
        console.log(reader, e, this.state);
        this.setState({ [name]: reader.result });
      };
      reader.onerror = function (error) {
        console.log(error);
      };
    }
  };

  // Function to set state for previously added data
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (!this.props.viewEmpDet && !this.state.updateText) {
      this.setState({
        PAN: nextProps.empData.PAN,
      });
      this.setState({
        AdhaarNo: nextProps.empData.AdhaarNo,
      });
      this.setState({
        PassportNo: nextProps.empData.PassportNo,
      });
      this.setState({
        VisaNo: nextProps.empData.VisaNo,
      });
    }
  }

  handledocDownload=(type)=>{
    if(type=="Aadhar")
      FileSaver.saveAs(this.state.uploadedAadharImg, `Aadhar EMP ID-${this.props?.empData?.EmpID?? JSON.parse(localStorage.getItem("EmployeeData")).EmpId}.png`);
    else if(type=="Pan")
      FileSaver.saveAs(this.state.uploadedPanImg, `Pan EMP ID-${this.props?.empData?.EmpID?? JSON.parse(localStorage.getItem("EmployeeData")).EmpId}.png`);
    else if(type=="Passport")
      FileSaver.saveAs(this.state.uploadedPassportImg, `Passport EMP ID-${this.props?.empData?.EmpID?? JSON.parse(localStorage.getItem("EmployeeData")).EmpId}.png`);
    else if(type=="Visa")
      FileSaver.saveAs(this.state.uploadedVisaImg, `Visa EMP ID-${this.props?.empData?.EmpID?? JSON.parse(localStorage.getItem("EmployeeData")).EmpId}.png`);  
  }

  docPreview=(type)=>{
    this.setState({docmodal:true, modalDoc:type})
  }
 
  render() {
    const { AdhaarNo, PAN } = this.state;

    const activeOther =
      typeof AdhaarNo !== "undefined" &&
      AdhaarNo.length > 0 &&
      typeof PAN !== "undefined" &&
      PAN.length > 0;
    return (
      <div>
        {console.log(this.state)}
        <div class="card  adress-card">
          <div class="card-header localAdd-header">Other Information</div>
          <div class="card-body">
            <div class="row address-row">
              <span className="otherSpan-text ">
                <span className="color-red" style={{ marginLeft: "-6px" }}>
                  *&nbsp;
                </span>
                Adhaar Number
              </span>
              <div class="col ">
                <input
                  type="text"
                  name="AdhaarNo"
                  value={this.state.AdhaarNo}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Adhaar Number"
                  maxLength={12}
                />
                {this.state.errorMsg.hasOwnProperty("AdhaarNo") && (
                  <span
                    style={{
                      color: "#ff0000",
                      fontSize: ".8em",
                    }}
                  >
                    Only Numbers are Allowed
                  </span>
                )}
              </div>

              <form class="col">
                <div class="row">
                  <div class="col-8" style={{paddingLeft: "0"} }>
                    <div class="custom-file">
                      <input type="file" accept="image/*" name="adharImg" onChange={this.handleChange} className="form-control pading-ht" id="adharImg"/>
                    </div>
                  </div>
                  <div class="col-4">
                    <span class="btn btn-primary" title="download" style={{cursor:this.state.uploadedAadharImg=='' ? 'not-allowed':'pointer'}} onMouseOver={this.state.uploadedAadharImg=='' ? null : ()=>this.docPreview('Aadhar Card')}><i class="fa fa-file-image-o" aria-hidden="true" style={{fontSize: "22px;", color: 'white'}}></i></span>
                    <span class="btn btn-info" title="download" style={{cursor:this.state.uploadedAadharImg=='' ? 'not-allowed':'pointer'}} onClick={this.state.uploadedAadharImg=='' ? null : ()=>this.handledocDownload('Aadhar')}><i class="fa fa-download" aria-hidden="true" style={{fontSize: "22px;"}}></i></span>
                    {/* <i aria-hidden="true" class="fa fa-eye"></i><i class="fa fa-download" aria-hidden="true" style={{marginLeft: "20px",fontSize: "22px"}}></i> */}
                  </div>
                </div>
                {/* <div class="custom-file">
                  <input type="file" accept="image/*" name="adharImg" onChange={this.handleChange} className="form-control pading-ht" id="adharImg"/>
                </div> */}
              </form>
            </div>

            <div class="row address-row">
              <span className="otherSpan-text ">
                <span className="color-red" style={{ marginLeft: "-6px" }}>
                  *&nbsp;
                </span>
                PAN Number
              </span>
              <div class="col ">
                <input
                  type="text"
                  name="PAN"
                  value={this.state.PAN}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="PAN number"
                  maxLength={10}
                />
              </div>

              <form class="col">
                <div class="row">
                  <div class="col-8" style={{paddingLeft: "0"} }>
                    <div class="custom-file">
                    <input type="file" accept="image/*"  name="panImg" onChange={this.handleChange} className="form-control pading-ht" id="panImg" />
                    </div>
                  </div>
                  <div class="col-4">
                    <span class="btn btn-primary" title="download" style={{cursor:this.state.uploadedPanImg=='' ? 'not-allowed':'pointer'}} onMouseOver={this.state.uploadedPanImg=='' ? null : ()=>this.docPreview('Pan Card')}><i class="fa fa-file-image-o" aria-hidden="true" style={{fontSize: "22px;", color: 'white'}}></i></span>
                    <span class="btn btn-info" title="download" style={{cursor:this.state.uploadedPanImg=='' ? 'not-allowed':'pointer'}} onClick={this.state.uploadedPanImg=='' ? null : ()=>this.handledocDownload('Pan')}><i class="fa fa-download" aria-hidden="true" style={{fontSize: "22px;"}}></i></span>
                  </div>
                </div>
                {/* <div class="custom-file">
                  <input type="file" accept="image/*"  name="panImg" onChange={this.handleChange} className="form-control pading-ht" id="panImg" />
                </div> */}
              </form>
            </div>

            <div class="row address-row">
              <span className="otherSpan-text ">Visa Number</span>
              <div class="col ">
                <input
                  type="text"
                  name="VisaNo"
                  value={this.state.VisaNo}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Visa Number"
                  maxLength={10}
                />
                {this.state.errorMsg.hasOwnProperty("VisaNo") && (
                  <span
                    style={{
                      color: "#ff0000",
                      fontSize: ".8em",
                    }}
                  >
                    Only Numbers are Allowed
                  </span>
                )}
              </div>

              <form class="col">
                <div class="row">
                  <div class="col-8" style={{paddingLeft: "0"} }>
                    <div class="custom-file">
                      <input type="file" accept="image/*" name="visaImg" onChange={this.handleChange} className="form-control pading-ht" id="visaImg" />
                    </div>
                  </div>
                  <div class="col-4">
                    <span class="btn btn-primary" title="download" style={{cursor:this.state.uploadedVisaImg=='' ? 'not-allowed':'pointer'}} onMouseOver={this.state.uploadedVisaImg=='' ? null : ()=>this.docPreview('Visa')}><i class="fa fa-file-image-o" aria-hidden="true" style={{fontSize: "22px;", color: 'white'}}></i></span>
                    <span class="btn btn-info" title="download" style={{cursor:this.state.uploadedVisaImg=='' ? 'not-allowed':'pointer'}} onClick={this.state.uploadedVisaImg=='' ? null : ()=>this.handledocDownload('Visa')}><i class="fa fa-download" aria-hidden="true" style={{fontSize: "22px;"}}></i></span>
                  </div>
                </div>
                {/* <div class="custom-file">
                  <input type="file" accept="image/*" name="visaImg" onChange={this.handleChange} className="form-control pading-ht" id="visaImg" />
                </div> */}
              </form>
            </div>

            <div class="row address-row">
              <span className="otherSpan-text ">Passport Number</span>
              <div class="col ">
                <input
                  type="text"
                  name="PassportNo"
                  class="form-control"
                  value={this.state.PassportNo}
                  onChange={this.handleChange}
                  placeholder="PassportNo"
                  maxLength={30}
                />
                {this.state.errorMsg.hasOwnProperty("PassportNo") && (
                  <span
                    style={{
                      color: "#ff0000",
                      fontSize: ".8em",
                    }}
                  >
                    Only Numbers are Allowed
                  </span>
                )}
              </div>

              <form class="col">
                <div class="row">
                  <div class="col-8" style={{paddingLeft: "0"} }>
                    <div class="custom-file">
                      <input type="file" accept="image/*" name="passportImg" onChange={this.handleChange} className="form-control pading-ht" id="passportImg" />
                    </div>
                  </div>
                  <div class="col-4">
                    <span class="btn btn-primary" title="download" style={{cursor:this.state.uploadedPassportImg=='' ? 'not-allowed':'pointer'}} onMouseOver={this.state.uploadedPassportImg=='' ? null : ()=>this.docPreview('Passport')}><i class="fa fa-file-image-o" aria-hidden="true" style={{fontSize: "22px;", color: 'white'}}></i></span>
                    <span class="btn btn-info" title="download" style={{cursor:this.state.uploadedPassportImg=='' ? 'not-allowed':'pointer'}}  onClick={this.state.uploadedPassportImg=='' ? null : ()=>this.handledocDownload('Passport')}><i class="fa fa-download" aria-hidden="true" style={{fontSize: "22px;"}}></i></span>
                  </div>
                </div>
                {/* <div class="custom-file">
                  <input type="file" accept="image/*" name="passportImg" onChange={this.handleChange} className="form-control pading-ht" id="passportImg" />
                </div> */}
              </form>
            </div>

            <div className="button-div">
              {this.props.viewEmpDet ? (
                <Mutation
                mutation={ADD_EMP_DATA}
                onCompleted={(data) => {
                  this.handleMutation(data);
                }}
              >
                {(addOtherDet, { data, loading, error }) => (
                  <div>
                    <button
                      type="submit"
                      className={
                        !activeOther
                          ? "btn btn-primary disablebtn "
                          : "btn btn-primary"
                      }
                      //  className={ "btn btn-primary btn-cursor"}
                      name="personal"
                      disabled={this.state.PAN==''||this.state.AdhaarNo==''}
                      onClick={(e) => {
                        e.preventDefault();
                        addOtherDet({
                          variables: {
                            EmpID: this.state.EmpID,
                            PAN: this.state.PAN,
                            AdhaarNo: this.state.AdhaarNo,
                            VisaNo: this.state.VisaNo,
                            PassportNo: this.state.PassportNo,
                            AdhaarBase64: this.state.adharImg,
                            PANBase64: this.state.panImg,
                            PassportBase64: this.state.passportImg,
                            VisaBase64: this.state.visaImg,
                          },
                        });
                      }}
                    >
                      Update Emp Detials
                    </button>
                  </div>
                )}
              </Mutation>
              ) : this.props.onboardingStatus === "APPROVED" ||
                JSON.parse(localStorage.getItem("EmployeeData"))
                  .OnboardingStatus === "APPROVED" ||
                this.props.onboardingStatus === "SUBMITTED" ||
                JSON.parse(localStorage.getItem("EmployeeData"))
                  .OnboardingStatus === "SUBMITTED" ? (
                ""
              ) : (
                <Mutation
                  mutation={ADD_EMP_DATA}
                  onCompleted={(data) => {
                    this.handleMutation(data);
                    this.setState({ activeTab: "other-details" });
                    this.props.tabName("bank");
                    //      this.aOther.click(); // temp solution to cause app to re-render
                  }}
                >
                  {(addOtherDet, { data, loading, error }) => (
                    <div>
                      <button
                        type="submit"
                        className={
                          !activeOther
                            ? "btn btn-primary disablebtn "
                            : "btn btn-primary"
                        }
                        //  className={ "btn btn-primary btn-cursor"}
                        name="personal"
                        disabled={!activeOther}
                        onClick={(e) => {
                          e.preventDefault();
                          addOtherDet({
                            variables: {
                              EmpID: JSON.parse(
                                localStorage.getItem("EmployeeData")
                              ).EmpId,
                              PAN: this.state.PAN,
                              AdhaarNo: this.state.AdhaarNo,
                              VisaNo: this.state.VisaNo,
                              PassportNo: this.state.PassportNo,
                              AdhaarBase64: this.state.adharImg,
                              PANBase64: this.state.panImg,
                              PassportBase64: this.state.passportImg,
                              VisaBase64: this.state.visaImg,
                            },
                          });
                        }}
                      >
                        Save and Next
                      </button>
                    </div>
                  )}
                </Mutation>
              )}
            </div>
          </div>
        </div>
        <Modal show={this.state.docmodal} onHide={()=>{this.setState({docmodal:false})}} >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalDoc}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={
              this.state.modalDoc=='Aadhar Card' ? this.state.uploadedAadharImg : 
              this.state.modalDoc=='Pan Card' ? this.state.uploadedPanImg :
              this.state.modalDoc=='Passport' ? this.state.uploadedPassportImg :
              this.state.modalDoc=='Visa' ? this.state.uploadedVisaImg :'' } 
            class="imagepreview" style={{width: "100% "}}></img>
          </Modal.Body>
          <Modal.Footer>
            
          </Modal.Footer>
      </Modal>
      </div>
    );
  }
}
