

import React, { Component, useState, useEffect, useRef, usePrevious } from "react";
import { NotificationManager } from "react-notifications";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { COUNTRY_LIST, CUSTOM_LABELS, COUNTRY_NAME } from "../../Utils/Data";
import ReactFlagSelectCustom from "../../Utils/ReactFlagSelectCustom";
//import "react-flags-select/css/react-flags-select.css";

export default function ManageDesignation() {
  const [deg, setdeg] = useState("");
  //const [location, setLocation] = useState("");
  //const [code, setCode] = useState("");
  //const [initialId, setInitialId] = useState("");
  //const [countryCode, setCountryCode] = useState("");
  const [disableSave, setdisableSave] = useState(true);

 // const countryCodeRefs = useRef([]); // multiple which is used inside the map
  //const countryCodeRef = useRef([]); // ref for single country code slect flag

  // const Get_Manage_Branch1 = gql`
  //   {
  //     GetManageBranch {
  //       BranchName
  //       BranchLocation
  //       BranchCode
  //       InitialID
  //       CountryCode
  //     }
  //   }
  // `;
   const Get_All_Designations = gql`
    {
      GetAllDesignations {
        Designation
      }
    }
  `;


// Mutation to add  Designation
  const Add_Designation = gql`
    mutation AddDesignation(
      $Designation: String! 
    ) {
      AddDesignation(
        Designation: $Designation
      ) {
        Message
        Confirmed
      }
    }
  `;

  // // Mutation to add a branch
  // const INSERT_Manage_Branch = gql`
  //   mutation InsertManageBranch(
  //     $BranchName: String!
  //     $BranchLocation: String!
  //     $BranchCode: String
  //     $InitialID: String!
  //     $CountryCode: String!
  //   ) {
  //     InsertManageBranch(
  //       BranchName: $BranchName
  //       BranchLocation: $BranchLocation
  //       BranchCode: $BranchCode
  //       InitialID: $InitialID
  //       CountryCode: $CountryCode
  //     ) {
  //       Message
  //       Confirmed
  //     }
  //   }
  // `;


  //Mutation to update a UpdateDesignations
  const  Update_Designations = gql`
    mutation  UpdateDesignations(
      $OldDesignation: String!
      $NewDesignation: String!
    ) {
      UpdateDesignations(
        OldDesignation: $OldDesignation
        NewDesignation: $NewDesignation
      ) {
        Message
        Confirmed
      }
    }
  `;



  // Mutation to Delete-Designation
  const Delete_Designation = gql`
    mutation DeleteDesignation(
      $Designation: String!
    ) {
      DeleteDesignation(
        Designation: $Designation
      ) {
        Message
        Confirmed
      }
    }
  `;

  const { data = { GetAllDesignations: [] }, refetch: refetchDesignation } = useQuery(
    Get_All_Designations
  );



  console.log('count count', data.GetAllDesignations);

  

  const [allData, setAllData] = useState([]);
  const [allOldData, setAllOldData] = useState([]);



  useEffect(() => {
    if (Object.keys(data.GetAllDesignations).length !== 0) {
     // alert('',JSON.stringify(data));
      console.log(data);
      for(let i=0; i<data.GetAllDesignations.length; i++){
        data.GetAllDesignations[i].addOldDesignation = '';
        data.GetAllDesignations[i].addOldDesignation = JSON.parse(JSON.stringify(data.GetAllDesignations[i].Designation));
      }
      console.log('data added added', data);
      setAllData(data);
      setAllOldData(data);
      localStorage.setItem('addOldDesignation', JSON.stringify(data));
      console.log('allData allData',allData);
     console.log('allOldData', allOldData);
      // alert(JSON.stringify(getAllData));
 
    }
    checkInput();
  });

  const [createDesignation] = useMutation(Add_Designation);
  const [updateDesignation] = useMutation(Update_Designations);
  const [deleteDesignation] = useMutation(Delete_Designation);
  const buttonRef = useRef(null);

  async function createNewDesignation() {
    var response = await createDesignation({
      variables: {
        Designation: deg,
      },
    });
    NotificationManager.success("Designation added", "Successful!", 2000);
    setdeg("");
    refetchDesignation();
  }

  async function updateNewDesignation(branchDetail, index) {
    //alert(branchDetail);
    console.log('branchDetail',branchDetail, index);
    //  alert(branchDetail);    
    // var response = await updateDesignation({
    //   variables: {
    //     // OldDesignation: branchDetail.OldDesignation,
    //     // NewDesignation: branchDetail.NewDesignation,
    //     OldDesignation: branchDetail,
    //     NewDesignation: branchDetail,
    //   },
    // });
    // NotificationManager.success("Designation updated", "Successful!", 2000);
    // refetchDesignation();
  }
  async function deleteNewDesignation(branchDetail) {
    console.log(branchDetail);

    var response = await deleteDesignation({
      variables: {
        Designation: branchDetail.Designation,
      },
    });
    NotificationManager.success("Designation deleted", "Successful!", 2000);
    refetchDesignation();
  }

  function handleChange(e) {
    let arr = e.target.getAttribute("data-key").split("_");
    // allData.GetAllDesignations[arr[1]][arr[0]].Designation = e.target.value;
    debugger;
    allData.GetAllDesignations[arr[1]].Designation= e.target.value;
    console.log(arr, allData.GetAllDesignations[arr[1]]);
    console.log(arr, allData);

    setAllData({ ...allData });
    // console.log('allOldData',  arr, allData.GetAllDesignations);
  }

  // const onSelectFlag = (e, i) => {
  //   console.log(e, i);
  //   allData.GetManageBranch[i].CountryCode = e;
  //   setAllData({ ...allData });
  //   console.log(allData);
  // };

  function checkInput() {
    if (
      deg.length > 0 
      
    ) {
      setdisableSave(false);
    } else {
      setdisableSave(true);
    }
  }

  return (
    <div className="card cmp-card-wid ">
      <div className="card-header cmp-headr-styl"> Manage Designation</div>
      <div className="card-body">
        <div class="row">
          <div className="col-md-4 form-group">
            <label className="labl-font"> Designation </label>
          </div>
          <div className="col-md-4 form-group">
            {/* <label className="labl-font"> Location </label> */}
             <label className="labl-font">
              {typeof allData?.GetAllDesignations !== "undefined" &&
              Object.keys(allData?.GetAllDesignations).length > 0
                ? "Action"
                : ""}
            </label>
          </div>
          <div className="col-md-4 form-group">
            {/* <label className="labl-font"> Code </label> */}
          </div>
        </div>
        {typeof allData?.GetAllDesignations !== "undefined" &&
        Object.keys(allData?.GetAllDesignations).length > 0
          ? allData.GetAllDesignations.map((branchDetail, i) => {
              return (
                <div className="row" key={i} style={{ marginTop: "15px" }}>
                  <div className="col-md-4 form-group">
                    <input
                      type="text"
                      data-key={"Designation_" + i}
                      value={branchDetail.Designation}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 form-group">
                  <i
                      title="Delete"
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      onClick={() => deleteNewDesignation(branchDetail)}
                    ></i>
                    {/* <button
                      type="button"
                      className="btn btn-danger btn-clr-font"
                      onClick={() => updateNewDesignation(branchDetail.Designation, branchDetail.addOldDesignation)}
                    >
                      Update
                    </button> */}
                    {/* <input
                      type="text"
                      data-key={"BranchLocation_" + i}
                      value={branchDetail.BranchLocation}
                      onChange={handleChange}
                      className="form-control"
                    /> */}
                  </div>
                  <div className="col-md-4 form-group">
                    {/* <input
                      type="text"
                      data-key={"BranchCode_" + i}
                      value={branchDetail.BranchCode}
                      onChange={handleChange}
                      className="form-control"
                    /> */}
                  </div>
                </div>
              );
            })
          : ""}
        <hr />
        <div className="row">
          <div className="col-md-4 form-group">
            <input
              type="text"
              value={deg}
              onChange={(e) => setdeg(e.target.value)}
              className="form-control"
              name="name"
            />
          </div>
          <div className="col-md-4 form-group">
          <button
              className="btn btn-primary"
              onClick={createNewDesignation}
              disabled={disableSave}
            >
              Save
            </button>
            {/* <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="form-control"
              name="location"
            /> */}
          </div>
          <div className="col-md-4 form-group">
            {/* <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
              name="code"
            /> */}
          </div> 
        </div>
        
      </div>
    </div>
  );
}
