import React, { Component } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
// import { gql } from "apollo-boost";
import { NotificationManager } from "react-notifications";

export default function EmpProfileData(props) {
  var EmpID = JSON.parse(localStorage.getItem("EmployeeData")).EmpId;

  const EMP_PROFILE = gql`
    {
      GetEmpDet(EmpID: "${EmpID}") {
       EmpID
      AdhaarNo
        BankAcNo
        BloodGroup
        DOB
        DOJ
        Emergency_No
        EmployeeBank
        EmpID
        Fathersname
        EmployeeName
        Gender
        IFSCcode
        LocAdd1
        LocAdd2
        LocCity
        LocCountry
        LocPIN
        LocState
        MotherName
        OfficeMail
        OnboardingStatus
        PAN
        PassportNo
        PerAdd1
        PerAdd2
        PerCity
        PerCountry
        PerState
        PerPIN
        PersonalEmail
        PersonalMobile
        SpouseName
        SpouseAge
        Child
        Child2
        ChildAge
        ChildAge2
        MotherName
        VisaNo
      }
    }
  `;

  const { data, loading, error } = useQuery(EMP_PROFILE);
  console.log(data, "emp prof services");
  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log("Something went wrong");
    return <span>-</span>;
  }

  if (data) {
    console.log(data);
    if (data && typeof data.GetEmpDet[0] !== "undefined") {
      props.handleEmpData(data);
      return null;
    } else {
      return <span>Error</span>;
    }
  }
}
