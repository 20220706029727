import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Service from "./service";

class Login extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      email: "",
      password: "",
      loginButtonClicked: false,
      returnData: "",
      isPasswordShown: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleData = this.handleData.bind(this);
  }

  handleData(data) {
    if (data.FirstLogin === "TRUE") {
      localStorage.setItem("EmpId", data.EmpID);
      this.props.history.push("/resetPassword");
    } else {
      if (localStorage.getItem("EmpId")) {
        localStorage.removeItem("EmpId");
      }
      localStorage.setItem(
        "EmployeeData",
        JSON.stringify({
          EmpId: data.EmpID,
          EmpName: data.EmpName,
          VoiceGuidance: data.VoiceGuidance,
          UserType: data.UserType,
          OnboardingStatus: data.OnboardingStatus,
          PersonalMobile: data.PersonalMobile,
          OfficeMail: data.OfficeMail,
          FirstLogin: data.FirstLogin,
        })
      );
      this.props.handleLogin(data);
      this.props.history.push("/home/dashboard");
    }
  }

  handleSuperadminData = (data) => {
    if (localStorage.getItem("EmpId")) {
      localStorage.removeItem("EmpId");
    }
    localStorage.setItem(
      "EmployeeData",
      JSON.stringify({
        EmpId: data.EmpID,
        EmpName: data.EmpName,
        UserType: data.UserType,
        OfficeMail: data.OfficeMail,
        FirstLogin: data.FirstLogin,
      })
    );
    this.props.handleLogin(data);
    this.props.history.push("/home/superadmin/superadminconfig");
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      loginButtonClicked: true,
      email: event.target.email.value,
      password: event.target.password.value,
    });
  }

  togglePasswordVisibility = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  navigateToForgotPwd = () => {
    this.props.history.push("/forgotPasswordemail");
  };

  handleTTS = () => {
    var synthesis = window.speechSynthesis;
    var utterance1 = new SpeechSynthesisUtterance(
      "Welcome to Kibbcom Onboarding"
    );
    synthesis.speak(utterance1);
  };

  render() {
    const isPasswordShown = this.state.isPasswordShown;
    const loginButtonClicked = this.state.loginButtonClicked;
    let service;
    if (loginButtonClicked) {
      service = (
        <Service
          email={this.state.email}
          password={this.state.password}
          handleData={
            this.props.usertype !== "undefined" &&
            this.props.usertype === "superadmin"
              ? this.handleSuperadminData
              : this.handleData
          }
          usertype={
            this.props.usertype !== "undefined" &&
            this.props.usertype === "superadmin"
              ? this.props.usertype
              : ""
          }
        />
      );
    }

    return (
      <div
        class={
          this.props.usertype !== "undefined" &&
          this.props.usertype === "superadmin"
            ? "content bg-image-superadmin"
            : "content bg-image"
        }
      >
        <div class="container-fluid">
          <div class="kicoLabel">
            <span>
              <img className="logo-size" src="Images/Kico-logo.png" alt="img" />
            </span>
            Kibbcom India Private Limited
          </div>

          <div
            class={
              this.props.usertype !== "undefined" &&
              this.props.usertype === "superadmin"
                ? "outPopUp-superadmin"
                : "outPopUp"
            }
          >
            <div class="row">
              <div class="col-md-12">
                <div class="card wid-28 wid-19">
                  <div class="card-body">
                    <form onSubmit={this.handleSubmit}>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label className="font-wt" for="email">
                              Username
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              required
                            />
                          </div>
                          <div class="form-group">
                            <label className="font-wt" for="pwd">
                              Password:
                            </label>
                            <input
                              type={isPasswordShown ? "text" : "password"}
                              name="password"
                              class="form-control"
                              id="pwd"
                              placeholder="Password"
                              required
                            />
                            <span className="password-icon">
                              <i
                                className={
                                  isPasswordShown
                                    ? "ion-eye"
                                    : "ion-eye-disabled"
                                }
                                onClick={this.togglePasswordVisibility}
                              ></i>
                            </span>
                          </div>
                          <div>{service}</div>
                          <div style={{ textAlign: "center" }}>
                            <button type="submit" className="btn btn-primary ">
                              Login
                            </button>

                            <div className="hovr-clr">
                              <a
                                style={{ cursor: "pointer", fontWeight: "400" }}
                                onClick={this.navigateToForgotPwd}
                              >
                                Forgot Password?
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

            {this.props.usertype !== "undefined" &&
            this.props.usertype === "superadmin" ? (
              ""
            ) : (
              <div class="innerDiv2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="50px"
                  height="39px"
                  viewBox="0 0 655.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  onClick={this.handleTTS}
                  style={{ cursor: "pointer" }}
                >
                  <g
                    className="icon-clr"
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M3080 12714 c-443 -55 -837 -253 -1146 -577 -302 -316 -494 -735 -544 -1182 -8 -71 -10 -875 -8 -2805 l3 -2705 22 -110 c120 -597 439 -1072 918 -1365 711 -437 1643 -325 2243 269 300 296 481 640 575 1096 21 100 21 111 24 2825 3 2433 2 2737 -12 2840 -118 859 -727 1528 -1540 1690 -86 18 -153 23 -305 25 -107 2 -211 1 -230 -1z" />
                    <path d="M112 6962 c-29 -19 -32 -90 -32 -712 0 -860 13 -1067 84 -1377 216 -936 872 -1729 1765 -2133 235 -106 564 -207 789 -242 l82 -12 0 -518 0 -518 -790 0 -790 0 0 -690 0 -690 2055 0 2055 0 0 690 0 690 -790 0 -790 0 0 518 0 518 83 12 c45 7 146 28 223 47 1162 284 2067 1188 2330 2328 71 310 84 517 84 1377 0 622 -3 693 -32 712 -7 4 -223 8 -480 8 l-468 0 -14 -22 c-13 -20 -15 -140 -19 -808 -4 -849 -4 -851 -62 -1081 -183 -720 -756 -1302 -1490 -1512 -468 -134 -991 -113 -1435 58 -659 254 -1148 795 -1315 1454 -58 230 -58 232 -62 1081 -4 668 -6 788 -19 808 l-14 22 -468 0 c-257 0 -473 -4 -480 -8z" />
                  </g>
                </svg>
                Enable Voice guidance
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
