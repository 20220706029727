import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";

import EmployeeProfile from "../../Services/EmpProfileData";
// Mutation for adding employee details(self-onboarding)
const ADD_EMP_DATA = gql`
  mutation(
    $EmpID: String!
    $MotherName: String!
    $FatherName: String
    $SpouseName: String!
    $SpouseAge: String!
    $ChildAge: String!
    $Child: String!
    $ChildAge2: String
    $Child2: String
  ) {
    FamilyDet(
      EmpID: $EmpID
      MotherName: $MotherName
      FatherName: $FatherName
      SpouseName: $SpouseName
      SpouseAge: $SpouseAge
      ChildAge: $ChildAge
      Child: $Child
      ChildAge2: $ChildAge2
      Child2: $Child2
    ) {
      Message
      Confirmed
    }
  }
`;

export default class FamilyTab extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      executeEmpService: "",
      updateText: false,
      EmpID:
        typeof this.props?.empData === "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).EmpId
          : this.props.empData.EmpID,
      MotherName:
        typeof this.props?.empData === "undefined" || this.props.empData === ""
          ? ""
          : this.props.empData.MotherName,
      FatherName:
        typeof this.props?.empData === "undefined" || this.props.empData === ""
          ? ""
          : this.props.empData.Fathersname,
      ChildAge2:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.ChildAge2,
      Child2:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.Child2,
      SpouseName:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.SpouseName,
      SpouseAge:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.SpouseAge,
      ChildAge:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.ChildAge,
      Child:
        typeof this.props?.empData === "undefined"
          ? ""
          : this.props.empData.Child,
    };
  }

  // Function to set state for previously added data

  componentWillReceiveProps(nextProps) {
    if (!this.props.viewEmpDet && !this.state.updateText) {
      this.setState({
        MotherName: nextProps.empData.MotherName,
      });
      this.setState({
        FatherName: nextProps.empData.Fathersname,
      });
      this.setState({
        ChildAge2: nextProps.empData.ChildAge2,
      });
      this.setState({
        Child2: nextProps.empData.Child2,
      });
      this.setState({
        Child: nextProps.empData.Child,
      });
      this.setState({
        ChildAge: nextProps.empData.ChildAge,
      });
      this.setState({
        SpouseName: nextProps.empData.SpouseName,
      });
      this.setState({
        SpouseAge: nextProps.empData.SpouseAge,
      });
    }
  }

  // Functionn to handle change
  handleChange = (e) => {
    this.setState({ updateText: true });
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log(this.state);
        if (
          this.state.MotherName.length > 0 &&
          this.state.FatherName.length > 0
        )
          this.props.parentCallback("other");
      }
    );
  };

  //Function to handle tabs
  handleTab = (e) => {
    this.setState({ activeTab: e.target.name });
    e.preventDefault();
  };

  //Function to handle mutation for family data
  handleMutation = (data) => {
    if (data.FamilyDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Family added Successfully",
        "Successful!",
        2000
      );
    } else if (data.FamilyDet.Confirmed === "1") {
      NotificationManager.error("Error while adding data", "Error!");
    }
  };

  // sendBackData = (activeFamily) => {};

  render() {
    const { FatherName, MotherName } = this.state;
    const activeFamily =
      typeof FatherName !== "undefined" &&
      FatherName.length > 0 &&
      typeof MotherName !== "undefined" &&
      MotherName.length > 0;

    return (
      <div>
        <div class="card personal-Info">
          <div class="card-header employement-header">Family Details</div>
          <div class="card-body">
            <div class="row family-row">
              <span class="familytxt-width">
                <span className="color-red" style={{ marginLeft: "-6px" }}>
                  *&nbsp;
                </span>
                Father's Name
              </span>
              <div class="col info-col">
                <input
                  type="text"
                  name="FatherName"
                  value={this.state.FatherName}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Father's Name"
                  maxLength={20}
                />
              </div>

              <span class="familytxt-width">
                <span className="color-red" style={{ marginLeft: "-6px" }}>
                  *&nbsp;
                </span>
                Mother's Name
              </span>
              <div class="col info-col">
                <input
                  type="text"
                  name="MotherName"
                  value={this.state.MotherName}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Mother's Name"
                  maxLength={20}
                />
              </div>
            </div>
            <div class="row family-row">
              <span class="familytxt-width"> Spouse's Name</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="SpouseName"
                  class="form-control "
                  value={this.state.SpouseName}
                  onChange={this.handleChange}
                  placeholder="Spouse's Name"
                  maxLength={20}
                />
              </div>

              <span class="age-text"> Age</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="SpouseAge"
                  value={this.state.SpouseAge}
                  onChange={this.handleChange}
                  class="form-control"
                  maxLength={10}
                />
              </div>
            </div>

            <div class="row family-row">
              <span class="familytxt-width"> Child's Name</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="Child"
                  class="form-control "
                  value={this.state.Child}
                  onChange={this.handleChange}
                  placeholder="Child's Name"
                  maxLength={30}
                />
              </div>

              <span class="age-text"> Age</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="ChildAge"
                  value={this.state.ChildAge}
                  onChange={this.handleChange}
                  class="form-control"
                  maxLength={3}
                />
              </div>
            </div>

            {/* second child details */}
            <div class="row family-row">
              <span class="familytxt-width"> Child's Name</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="Child2"
                  class="form-control "
                  value={this.state.Child2}
                  onChange={this.handleChange}
                  placeholder="Child's Name"
                  maxLength={30}
                />
              </div>

              <span class="age-text"> Age</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="ChildAge2"
                  value={this.state.ChildAge2}
                  onChange={this.handleChange}
                  class="form-control"
                  maxLength={3}
                />
              </div>
            </div>

            <div className="button-div">
              {
                this.props.viewEmpDet ? (
                  <Mutation
                    mutation={ADD_EMP_DATA}
                    onCompleted={(data) => {
                      this.handleMutation(data);
                    }}
                  >
                    {
                      (addFamilylDet, { data, loading, error }) => (
                        <div>
                          <button
                            type="submit"
                            className={"btn btn-primary btn-cursor"}
                            name="personal"
                            disabled={this.state.MotherName==''||this.state.FatherName==''}
                            onClick={(e) => {
                              e.preventDefault();
                              addFamilylDet({
                                variables: {
                                  EmpID: this.state.EmpID,
                                  MotherName: this.state.MotherName,
                                  FatherName: this.state.FatherName,
                                  SpouseName: this.state.SpouseName,
                                  SpouseAge: this.state.SpouseAge,
                                  ChildAge: this.state.ChildAge,
                                  Child: this.state.Child,
                                  ChildAge2: this.state.ChildAge2,
                                  Child2: this.state.Child2,
                                },
                              });
                            }}
                          >
                            Update Emp Details
                          </button>
                        </div>
                      )}
                  </Mutation>) : 
                this.props.onboardingStatus === "APPROVED" || JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus === "APPROVED" ||
                this.props.onboardingStatus === "SUBMITTED" || JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus === "SUBMITTED" ? ("") : 
                (
                  <Mutation
                    mutation={ADD_EMP_DATA}
                    onCompleted={(data) => {
                      this.handleMutation(data);
                      this.setState({ activeTab: "other-details" });
                      this.props.tabName("other");
                    }}
                  >
                    {(addFamilylDet, { data, loading, error }) => (
                      <div>
                        <button
                          type="submit"
                          className={"btn btn-primary btn-cursor"}
                          name="personal"
                          disabled={!activeFamily}
                          onClick={(e) => {
                            e.preventDefault();
                            addFamilylDet({
                              variables: {
                                EmpID: JSON.parse(
                                  localStorage.getItem("EmployeeData")
                                ).EmpId,
                                MotherName: this.state.MotherName,
                                FatherName: this.state.FatherName,
                                SpouseName: this.state.SpouseName,
                                SpouseAge: this.state.SpouseAge,
                                ChildAge: this.state.ChildAge,
                                Child: this.state.Child,
                                ChildAge2: this.state.ChildAge2,
                                Child2: this.state.Child2,
                              },
                            });
                          }}
                        >
                          Save and Next
                        </button>
                      </div>
                    )}
                  </Mutation>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
