

import React, { Component } from "react";
import "./UpdateProfile.css";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import EmployeeProfile from "../../Services/EmpProfileData";

const UPDATE_EMPDETAILS_QUERY = gql`
  mutation(
    $PersonalEmail: String
    $LocAddress2: String
    $LocCity: String!
    $PerCity: String!
    $LocPIN: String!
    $PerPIN: String!
    $PerAddress1: String!
    $PerState: String!
    $PerCountry: String!
    $EmergencyNo: String
    $PerAddress2: String
    $PFNo: String
    $EmpID: String!
    $PersonalMobile: String!
    $LocAddress1: String!
    $LocState: String!
    $LocCountry: String!
  ) {
    afterAppupdateEmpdet(
      PersonalEmail: $PersonalEmail
      LocAddress2: $LocAddress2
      LocCity: $LocCity
      PerCity: $PerCity
      LocPIN: $LocPIN
      PerPIN: $PerPIN
      PerAddress1: $PerAddress1
      PerState: $PerState
      PerCountry: $PerCountry
      EmergencyNo: $EmergencyNo
      PerAddress2: $PerAddress2
      PFNo: $PFNo
      EmpID: $EmpID
      PersonalMobile: $PersonalMobile
      LocAddress1: $LocAddress1
      LocState: $LocState
      LocCountry: $LocCountry
    ) {
      Message
      Confirmed
    }
  }
`;

export default class UpdateProfile extends Component {
  data;
  constructor(props) {
    console.log(props);
    super(props);

    this.state = {
      activeTab: "personal-info",
      // to handle tab switch on button click//
      activePersonalInfo: true,
      activeFamilyInfo: false,
      visitedFamilyInfo: false,
      activeOtherInfo: false,
      visitedOtherInfo: false,
      activeBankInfo: false,
      visitedBankInfo: false,
      // mutations
      PersonalEmail: "",
      LocAddress2: "",
      LocCity: "",
      PerCity: "",
      LocPIN: "",
      PerPIN: "",
      PerAddress1: "",
      PerState: "",
      PerCountry: "",
      EmergencyNo: "",
      PerAddress2: "",
      PFNo: "123",
      EmpID: JSON.parse(localStorage.getItem("EmployeeData")).EmpId,
      PersonalMobile: "",
      LocAddress1: "",
      LocState: "",
      LocCountry: "",
      // emp data
      empProfData: "",
      executeEmpService: true,
      OfficeEmail: "",
      DOB: "",
      Gender: "",
      BloodGroup: "",
      FatherName: "",
      MotherName: "",
      SpouseName: "",
      SpouseAge: "",
      Child: "",
      ChildAge: "",
      Child2: "",
      ChildAge2: "",
      AdhaarNo: "",
      PAN: "",
      VisaNo: "",
      PassportNo: "",
      BankName: "",
      AccNO: "",
      IFSC: "",
      PersonalEmail: "",
      PersonalMobile: "",
      EmergencyNo: "",
      LocAddress1: "",
      LocAddress2: "",
      LocCity: "",
      LocState: "",
      LocPIN: "",
      LocCountry: "",
      PerAddress1: "",
      PerAddress2: "",
      PerState: "",
      PerCity: "",
      PerPIN: "",
      PerCountry: "",
    };
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEmpData = (data) => {
    console.log(data.GetEmpDet[0]);
    this.setState({ empProfData: data });
    this.setState({ executeEmpService: false });
    this.setState({ OfficeEmail: data.GetEmpDet[0].OfficeMail });
    this.setState({ DOB: data.GetEmpDet[0].DOB });
    this.setState({ Gender: data.GetEmpDet[0].Gender });
    this.setState({ BloodGroup: data.GetEmpDet[0].BloodGroup });
    this.setState({ FatherName: data.GetEmpDet[0].Fathersname });
    this.setState({ MotherName: data.GetEmpDet[0].MotherName });
    this.setState({ SpouseName: data.GetEmpDet[0].SpouseName });
    this.setState({ SpouseAge: data.GetEmpDet[0].SpouseAge });
    this.setState({ Child: data.GetEmpDet[0].Child });
    this.setState({ ChildAge: data.GetEmpDet[0].ChildAge });
    this.setState({ Child: data.GetEmpDet[0].Child2 });
    this.setState({ ChildAge: data.GetEmpDet[0].ChildAge2 });
    this.setState({ AdhaarNo: data.GetEmpDet[0].AdhaarNo });
    this.setState({ PAN: data.GetEmpDet[0].PAN });
    this.setState({ VisaNo: data.GetEmpDet[0].VisaNo });
    this.setState({ PassportNo: data.GetEmpDet[0].PassportNo });
    this.setState({ BankName: data.GetEmpDet[0].EmployeeBank });
    this.setState({ AccNO: data.GetEmpDet[0].BankAcNo });
    this.setState({ IFSC: data.GetEmpDet[0].IFSCcode });
    this.setState({ PersonalEmail: data.GetEmpDet[0].PersonalEmail });
    this.setState({ PersonalMobile: data.GetEmpDet[0].PersonalMobile });
    this.setState({ EmergencyNo: data.GetEmpDet[0].Emergency_No });
    this.setState({ LocAddress1: data.GetEmpDet[0].LocAdd1 });
    this.setState({ LocAddress2: data.GetEmpDet[0].LocAdd2 });
    this.setState({ LocCity: data.GetEmpDet[0].LocCity });
    this.setState({ LocCountry: data.GetEmpDet[0].LocCountry });
    this.setState({ LocPIN: data.GetEmpDet[0].LocPIN });
    this.setState({ LocState: data.GetEmpDet[0].LocState });
    this.setState({ PerAddress1: data.GetEmpDet[0].PerAdd1 });
    this.setState({ PerAddress2: data.GetEmpDet[0].PerAdd2 });
    this.setState({ PerCity: data.GetEmpDet[0].PerCity });
    this.setState({ PerCountry: data.GetEmpDet[0].PerCountry });
    this.setState({ PerPIN: data.GetEmpDet[0].PerPIN });
    this.setState({ PerState: data.GetEmpDet[0].PerState });
  };

  //handle tab switch on click
  loadNextSection = (loadTab) => {
    switch (loadTab) {
      case "family":
        this.setState({
          activePersonalInfo: false,
          activeEmpInfo: false,
          activeFamilyInfo: true,
          visitedFamilyInfo: true,
        });
        console.log(this.state);
        break;
      case "other":
        this.setState({
          activePersonalInfo: false,
          activeFamilyInfo: false,
          activeOtherInfo: true,
          visitedOtherInfo: true,
        });
        console.log(this.state);
        break;
      case "bank":
        this.setState({
          activePersonalInfo: false,
          activeFamilyInfo: false,
          activeOtherInfo: false,
          activeBankInfo: true,
          visitedBankInfo: true,
        });
        console.log(this.state);
        break;
    }
  };

  handleMutation(data) {
    console.log(data.afterAppupdateEmpdet);
    if (data.afterAppupdateEmpdet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Data updated Successfully",
        "Successful!",
        2000
      );
    } else if (data.afterAppupdateEmpdet.Confirmed === "1") {
      NotificationManager.error("Error while updating data", "Error!");
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      activePersonalInfo,
      activeFamilyInfo,
      activeBankInfo,
      visitedBankInfo,
      visitedFamilyInfo,
      activeOtherInfo,
      visitedOtherInfo,
    } = this.state;

    let empData = <EmployeeProfile handleEmpData={this.handleEmpData} />;

    return (
      <div className="update-row">
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <button className="btn btn-primary" onClick={this.goBack}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
        </div>
        {this.state.executeEmpService ? empData : null}
        {/* Tabs */}
        <ul class="nav nav-tabs tabClass" role="tablist">
          <li class="nav-item">
            <a
              class={activePersonalInfo ? "active nav-link" : "nav-link"}
              data-toggle="tab"
              href="#personal-info"
            >
              Personal Information
            </a>
          </li>

          <li
            className={`nav-item  ${visitedFamilyInfo ? "" : "disable-icon"}`}
          >
            <a
              className={`nav-link  ${visitedFamilyInfo ? "" : "disabled"}  ${activeFamilyInfo ? "active" : ""
                } `}
              data-toggle="tab"
              href="#family-details"
            >
              Family
            </a>
          </li>

          <li className={`nav-item  ${visitedOtherInfo ? "" : "disable-icon"}`}>
            <a
              className={`nav-link  ${visitedOtherInfo ? "" : "disabled"} ${activeOtherInfo ? "active" : ""
                }`}
              data-toggle="tab"
              href="#other-details"
            >
              Other details
            </a>
          </li>

          <li className={`nav-item  ${visitedBankInfo ? "" : "disable-icon"}`}>
            <a
              className={`nav-link  ${visitedBankInfo ? "" : "disabled"}  ${activeBankInfo ? "active" : ""
                } `}
              data-toggle="tab"
              href="#bank-details"
            >
              Bank Details
            </a>
          </li>
        </ul>
        {/* Tab content */}
        <div class="tab-content">
          <div
            id="personal-info"
            className={
              activePersonalInfo
                ? "active card personal-Info tab-pane"
                : "card personal-Info tab-pane"
            }
          >
            <div class="card-header personal-header">Personal Information</div>
            <div class="card-body">
              <div class="row identity-info">
                <span class="span-text">Office Email</span>
                <div class="col info-col">
                  {/* {console.log(this.state.data)} */}
                  <input
                    type="email"
                    name="OfficeEmail"
                    value={this.state.OfficeEmail}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Office Email"
                    disabled
                  />
                </div>

                <span class="secondcoltxt-wid"> Personal Email</span>
                <div class="col info-col">
                  <input
                    type="email"
                    name="PersonalEmail"
                    value={this.state.PersonalEmail}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Personal Email"
                  />
                </div>
              </div>

              <div class="row contact-info">
                <span class="span-text"> Contact no:</span>
                <div class="col info-col">
                  <input
                    type="text"
                    name="PersonalMobile"
                    value={this.state.PersonalMobile}
                    onChange={this.handleChange}
                    class="form-control contact-input"
                    placeholder="Contact no:"
                  />
                </div>

                <span class="secondcoltxt-wid"> Emergency Contact no:</span>
                <div class="col info-col">
                  <input
                    type="text"
                    name="EmergencyNo"
                    value={this.state.EmergencyNo}
                    onChange={this.handleChange}
                    class="form-control contact-input"
                    placeholder="Emergency Contact no:"
                  />
                </div>
              </div>

              <div class="row dob-row">
                <span class="pispan-text"> Date of birth</span>
                <div class="col info-col">
                  <input
                    type="date"
                    name="DOB"
                    value={this.state.DOB}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="DD/MM/YYYY"
                    maxlength="10"
                  />
                </div>

                <span class="gender-span"> Gender</span>
                <form className="col info-col">
                  <select
                    name="Gender"
                    value={this.state.Gender}
                    onChange={this.handleChange}
                    class="custom-select gender-select"

                  >
                    <option value="">Select</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                  </select>
                </form>
                <span class="bGroup-span"> Blood Group</span>
                <form className="col info-col">
                  <select
                    name="BloodGroup"
                    value={this.state.BloodGroup}
                    onChange={this.handleChange}
                    class="custom-select gender-select"
                  >
                    <option value="">Select</option>
                    <option value="o-">O-</option>
                    <option value="o+">O+</option>
                    <option value="a-">A-</option>
                    <option value="a+">A+</option>
                    <option value="b-">B-</option>
                    <option value="b+">B+</option>
                    <option value="ab-">AB-</option>
                    <option value="ab+">AB+</option>
                  </select>
                </form>
              </div>

              <div class="card  adress-card">
                <div class="card-header localAdd-header">Local Address:</div>
                <div class="card-body">
                  <div class="row address-row">
                    <span className="address-span"> Address Line 1</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="LocAddress1"
                        value={this.state.LocAddress1}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Address Line 1"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span className="address-span"> Address Line 2</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="LocAddress2"
                        value={this.state.LocAddress2}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Address Line 2"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span className="city-span">City</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="LocCity"
                        value={this.state.LocCity}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="City"
                      />
                    </div>

                    <span className="state-span"> State</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="LocState"
                        value={this.state.LocState}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="State"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span class="postal-span">Postal code</span>
                    <div class="col">
                      <input
                        type="text"
                        name="LocPIN"
                        value={this.state.LocPIN}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Postal code"
                      />
                    </div>

                    <span class=""> Country</span>
                    <div class="col">
                      <input
                        type="text"
                        name="LocCountry"
                        value={this.state.LocCountry}
                        onChange={this.handleChange}
                        class="form-control "
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="card  adress-card">
                <div class="card-header localAdd-header">
                  Permanent Address:
                </div>
                <div class="card-body">
                  <div class="row address-row">
                    <span className="address-span"> Address Line 1</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="PerAddress1"
                        value={this.state.PerAddress1}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Address Line 1"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span className="address-span"> Address Line 2</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="PerAddress2"
                        value={this.state.PerAddress2}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Address Line 2"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span className="city-span">City</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="PerCity"
                        value={this.state.PerCity}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="City"
                      />
                    </div>

                    <span className="state-span"> State</span>
                    <div class="col ">
                      <input
                        type="text"
                        name="PerState"
                        value={this.state.PerState}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="State"
                      />
                    </div>
                  </div>
                  <div class="row address-row">
                    <span class="postal-span">Postal code</span>
                    <div class="col">
                      <input
                        type="text"
                        name="PerPIN"
                        value={this.state.PerPIN}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Postal code"
                      />
                    </div>

                    <span class=""> Country</span>
                    <div class="col">
                      <input
                        type="text"
                        name="PerCountry"
                        value={this.state.PerCountry}
                        onChange={this.handleChange}
                        class="form-control "
                        placeholder="Country"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-div">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => this.loadNextSection("family")}
                >
                  Save and Next
                </button>
              </div>
            </div>
          </div>
          {/* Family Details tab */}
          <div
            id="family-details"
            className={activeFamilyInfo ? "active  tab-pane" : " tab-pane"}
          >
            <div class="card personal-Info">
              <div class="card-header employement-header">Family Details</div>
              <div class="card-body">
                <div class="row family-row">
                  <span class="familytxt-width"> Father's Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="FatherName"
                      value={this.state.FatherName}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Father's Name"

                    />
                  </div>

                  <span class="familytxt-width"> Mother's Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="MotherName"
                      value={this.state.MotherName}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Mother's Name"

                    />
                  </div>
                </div>
                <div class="row family-row">
                  <span class="familytxt-width"> Spouse's Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="SpouseName"
                      class="form-control"
                      value={this.state.SpouseName}
                      onChange={this.handleChange}
                      placeholder="Spouse's Name"

                    />
                  </div>

                  <span class="age-text"> Age</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="SpouseAge"
                      value={this.state.SpouseAge}
                      onChange={this.handleChange}
                      class="form-control"

                    />
                  </div>
                </div>

                <div class="row family-row">
                  <span class="familytxt-width"> Child's Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="Child"
                      class="form-control"
                      value={this.state.Child}
                      onChange={this.handleChange}
                      placeholder="Child's Name"

                    />
                  </div>

                  <span class="age-text"> Age</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="ChildAge"
                      value={this.state.ChildAge}
                      onChange={this.handleChange}
                      class="form-control"

                    />
                  </div>
                </div>

                {/* second child details */}
                <div class="row family-row">
                  <span class="familytxt-width"> Child's Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="Child"
                      class="form-control"
                      value={this.state.Child2}
                      onChange={this.handleChange}
                      placeholder="Child's Name"

                    />
                  </div>

                  <span class="age-text"> Age</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="ChildAge"
                      value={this.state.ChildAge2}
                      onChange={this.handleChange}
                      class="form-control"

                    />
                  </div>
                </div>

                <div className="button-div">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.loadNextSection("other")}
                  >
                    Save and Next
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Other Details tab*/}
          <div
            id="other-details"
            className={activeOtherInfo ? "active  tab-pane" : " tab-pane"}
          >
            <div class="card  adress-card">
              <div class="card-header localAdd-header">Other Information</div>
              <div class="card-body">
                <div class="row address-row">
                  <span className="otherSpan-text ">Adhaar Number</span>
                  <div class="col ">
                    <input
                      type="text"
                      name="AdhaarNo"
                      value={this.state.AdhaarNo}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Adhaar Number"

                    />
                  </div>

                  <span className="otherSpan-text "> PAN Number</span>
                  <div class="col ">
                    <input
                      type="text"
                      name="PAN"
                      value={this.state.PAN}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="PAN number"

                    />
                  </div>
                </div>

                <div class="row address-row">
                  <span className="otherSpan-text ">Visa Number</span>
                  <div class="col ">
                    <input
                      type="text"
                      name="VisaNo"
                      value={this.state.VisaNo}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Visa Number"

                    />
                  </div>

                  <span className="otherSpan-text "> Passport Number</span>
                  <div class="col ">
                    <input
                      type="text"
                      name="PassportNo"
                      class="form-control"
                      value={this.state.PassportNo}
                      onChange={this.handleChange}
                      placeholder="PAN number"

                    />
                  </div>
                </div>
                <div className="button-div">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => this.loadNextSection("bank")}
                  >
                    Save and Next
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* BankDetails tab */}
          <div
            id="bank-details"
            className={activeBankInfo ? "active  tab-pane" : " tab-pane"}
          >
            <div id="personal-info" class="card personal-Info">
              <div class="card-header bank-header">BankDetails</div>
              <div class="card-body">
                <div class="row">
                  <span class="banktxt-width">Bank Name</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      class="form-control"
                      name="BankName"
                      value={this.state.BankName}
                      onChange={this.handleChange}
                      placeholder="Bank Name"

                    />
                  </div>

                  <span class="banktxt-width">Account No.</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="AccNO"
                      value={this.state.AccNO}
                      onChange={this.handleChange}
                      class="form-control"
                      placeholder="Account No."

                    />
                  </div>
                </div>
                <div class="row address-info">
                  <span class="banktxt-width">IFSC Code</span>
                  <div class="col info-col">
                    <input
                      type="text"
                      name="IFSC"
                      value={this.state.IFSC}
                      onChange={this.handleChange}
                      class="form-control ifsc-code"
                      placeholder="IFSC Code"

                    />
                  </div>
                </div>
                <div className="button-div">
                  <Mutation mutation={UPDATE_EMPDETAILS_QUERY} onCompleted={(data) => {
                    this.handleMutation(data)
                  }}>
                    {(updateEmpDetails, { data, loading, error }) => (
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            updateEmpDetails({
                              variables: {
                                PersonalEmail: this.state.PersonalEmail,
                                LocAddress2: this.state.LocAddress2,
                                LocCity: this.state.LocCity,
                                PerCity: this.state.PerCity,
                                LocPIN: this.state.LocPIN,
                                PerPIN: this.state.PerPIN,
                                PerAddress1: this.state.PerAddress1,
                                PerState: this.state.PerState,
                                PerCountry: this.state.PerCountry,
                                EmergencyNo: this.state.EmergencyNo,
                                PerAddress2: this.state.PerAddress2,
                                PFNo: this.state.PFNo,
                                EmpID: this.state.EmpID,
                                PersonalMobile: this.state.PersonalMobile,
                                LocAddress1: this.state.LocAddress1,
                                LocState: this.state.LocState,
                                LocCountry: this.state.LocCountry,
                              },
                            });
                          }}
                        >
                          Submit
                        </button>
                        {/* {loading && <p>Loading...</p>}
                    {error && <p>Error :( Please try again</p>} */}
                        {/* {data && this.handleMutation(data)} */}
                      </div>
                    )}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
