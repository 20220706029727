// This is Dynamic component and it renders from two ways 1.when employee onboards himslef 2. when admin click Employeedetails > ViewDetails button

import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";

import EmployeeProfile from "../../Services/EmpProfileData";
import "./SelfOnboarding.css";
import ViewDetails from "./ViewDetails";
import FamilyTab from "./FamilyTab";
import OtherDetailTab from "./OtherDetailTab";
import BankDetailTab from "./BankDetailTab";

import ReactFlagSelectCustom from "../../Utils/ReactFlagSelectCustom";
import { COUNTRY_LIST, CUSTOM_LABELS, COUNTRY_NAME } from "../../Utils/Data";
import { Context } from "../../Utils/Context";
import PhoneInput from "react-phone-input-2";

// Mutation for adding employee details(self-onboarding)
const ADD_EMP_DATA = gql`
  mutation(
    $EmpID: String!
    $EmergencyNo: String!
    $PersonalEmail: String!
    $DOB: String!
    $Gender: String!
    $BloodGroup: String!
    $LocAddress1: String!
    $LocAddress2: String
    $LocState: String!
    $LocCity: String!
    $LocCountry: String!
    $LocPIN: String!
    $PerAddress1: String!
    $PerAddress2: String!
    $PerState: String!
    $PerCity: String!
    $PerCountry: String!
    $PerPIN: String!
  ) {
    PersonalDet(
      EmpID: $EmpID
      EmergencyNo: $EmergencyNo
      PersonalEmail: $PersonalEmail
      DOB: $DOB
      Gender: $Gender
      BloodGroup: $BloodGroup
      LocAddress1: $LocAddress1
      LocAddress2: $LocAddress2
      LocState: $LocState
      LocCity: $LocCity
      LocCountry: $LocCountry
      LocPIN: $LocPIN
      PerAddress1: $PerAddress1
      PerAddress2: $PerAddress2
      PerState: $PerState
      PerCity: $PerCity
      PerCountry: $PerCountry
      PerPIN: $PerPIN
    ) {
      Message
      Confirmed
    }
  }
`;

export default class SelfOnboarding extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      childProp: "",
      selectVal: "",
      executeEmpService: true,
      disableApprovebtn: false,
      disableRejectBtn: false,
      disableSubmitBtn: false,
      errorMsg: {},
      activeTab: "personal-info",

      // mutation// //empdata will be avail if admin will use this page else it will be undefined //
      LocState:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.LocState,
      // PerCountry:
      //   typeof this.props?.empData == "undefined"
      //     ? ""
      //     : this.props.empData.PerCountry,
      PerCountry: "",
      LocPIN:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.LocPIN,
      // LocCountry:
      //   typeof this.props?.empData == "undefined"
      //     ? ""
      //     : this.props.empData.LocCountry,
      LocCountry: "",
      LocCity:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.LocCity,
      PerAddress1:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PerAdd1,
      OfficeMail:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).OfficeMail
          : this.props.empData.OfficeMail,

      PersonalEmail:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PersonalEmail,
      PersonalMobile:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).PersonalMobile
          : this.props.empData.PersonalMobile,
      EmpID:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).EmpId
          : this.props.empData.EmpID,
      Gender:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.Gender == "N/A" ? "Male" :  this.props.empData.Gender, 
      PerAddress2:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PerAdd2,
      PerState:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PerState,

      EmpName:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).EmpName
          : this.props.empData.EmpName,
      EmergencyNo:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.Emergency_No,

      PerCity:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PerCity,

      LocAddress1:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.LocAdd1,
      PerPIN:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.PerPIN,
      BloodGroup:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.BloodGroup == "N/A" ? "" :  this.props.empData.BloodGroup, 
      LocAddress2:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.LocAdd2,
      OfficeMob:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.OfficeMob,

      Branch:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.Branch,
      DOB:
        typeof this.props?.empData == "undefined" ? "" : this.props.empData.DOB,
      empProfData: "",
    };
  }

  async componentDidMount() {
    const EMP_PROFILE = gql`
    {
      GetEmpDet(EmpID: "${this.state.EmpID}") {
        AdhaarNo
        BankAcNo
        BloodGroup
        DOB
        DOJ
        EmergencyNo
        EmpBank
        EmpID
        FatherName
        EmpName
        Gender
        IFSC
        LocAdd1
        LocAdd2
        LocCity
        LocCountry
        LocPIN
        LocState
        MotherName
        OfficeMail
        OnboardingStatus
        PAN
        PassportNo
        PerAdd1
        PerAdd2
        PerCity
        PerCountry
        PerState
        PerPIN
        PersonalEmail
        PersonalMobile
      SpouseName
      SpouseAge
      ChildAge
      MotherName
        VisaNo
      }
    }
  `;
    var resData = await this.context.client.query({
      query: EMP_PROFILE,
    });
    if (this.props.viewEmpDet) {
      this.refs.locCountryRef.updateSelected(this.props?.empData?.LocCountry);
      this.refs.perCountryRef.updateSelected(this.props?.empData?.PerCountry);
    } else {
      this.refs.locCountryRef.updateSelected(
        resData.data?.GetEmpDet[0]?.LocCountry
      );
      this.refs.perCountryRef.updateSelected(
        resData.data.GetEmpDet[0].PerCountry
      );
    }
  }

  // Function to handle tab from child=> Family tab and Bank details tab
  handleTabFromChild = (tabName) => {
    if (tabName === "other") this.aOther.click();
    else if (tabName === "bank") this.aBank.click();
  };

  // Function to handle change for dropdown
  handleSelectChange = (e) => {
    this.setState({ selectVal: e.target.value });
  };

  // Function to handle change
  handleChange = (e) => {
    if (
      e.target.name === "EmergencyNo" ||
      e.target.name === "LocPIN" ||
      e.target.name === "PerPIN"
    ) {
      this.setState({ [e.target.name]: e.target.value.replace(/[^0-9]/g, "") });
      if (!/^[0-9]+$/.test(e.target.value)) {
        this.setState({
          errorMsg: { ...this.state.errorMsg, [e.target.name]: e.target.name },
        });
      } else {
        if (this.state.errorMsg.hasOwnProperty(e.target.name)) {
          const { [e.target.name]: name, ...newObject } = this.state.errorMsg;
          this.setState({ errorMsg: {} });
          this.setState({ errorMsg: newObject });
          console.log(this.state.errorMsg);
        }
      }
    } else this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeEmgNo = (e) =>{
    this.setState({ EmergencyNo: e.replace(/[^0-9]/g, "") });
    // if (!/^[0-9]+$/.test(e.target.value)) {
    //   this.setState({
    //     errorMsg: { ...this.state.errorMsg, [e.target.name]: e.target.name },
    //   });
    // } else {
    //   if (this.state.errorMsg.hasOwnProperty(e.target.name)) {
    //     const { [e.target.name]: name, ...newObject } = this.state.errorMsg;
    //     this.setState({ errorMsg: {} });
    //     this.setState({ errorMsg: newObject });
    //     console.log(this.state.errorMsg);
    //   }
    // }
  }

  currentIp = (e) => {
    e.persist();
    this.setState({ currentElem: e });
    var myprop=this.props;
    //myprop.startListening();
  };

  // Function to handle muatation for personal details data
  handleMutation = (data) => {
    if (data.PersonalDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Personal details added Successfully",
        "Successful!",
        2000
      );
    } else if (data.PersonalDet.Confirmed === "1") {
      NotificationManager.error("Error while adding data", "Error!");
    }
  };

  // Function to set state for previously added data
  handleEmpData = (data) => {
    console.log(this.refs);
    console.log(data);
    Object.keys(data.GetEmpDet[0]).forEach(
      (k) =>
        (data.GetEmpDet[0][k] =
          data.GetEmpDet[0][k] === "N/A" ? "" : data.GetEmpDet[0][k])
    );
    this.setState({ executeEmpService: false });
    this.setState({ empProfData: data.GetEmpDet[0] });
    this.setState({
      PersonalEmail: data.GetEmpDet[0].PersonalEmail,
    });
    this.setState({
      EmergencyNo: data.GetEmpDet[0].Emergency_No,
    });

    let date = data.GetEmpDet[0].DOB;
    let dateStr = "";
    if (date != "" && date.includes("/")) {
      let dateArr = date.split("/");
      dateStr = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
      this.setState({ DOB: dateStr });
    } else if (date != "" && date.includes("-")) {
      let dateArr = date.split("-");
      if (
        dateArr[2].length == 4 &&
        dateArr[2].toString().match(/(19|20|21)?[0-9]{2}/g)
      ) {
        dateStr = dateArr[2]
          .toString()
          .concat("-", dateArr[1], "-", dateArr[0]);
      } else {
        dateStr = date;
      }
      this.setState({ DOB: dateStr });
    }
    this.setState({
      Gender: data.GetEmpDet[0].Gender==="" ? "Male" :  data.GetEmpDet[0].Gender,
    });
    this.setState({
      BloodGroup: data.GetEmpDet[0].BloodGroup,
    });
    this.setState({
      LocAddress1: data.GetEmpDet[0].LocAdd1,
    });
    this.setState({
      LocAddress2: data.GetEmpDet[0].LocAdd2,
    });
    this.setState({
      LocCity: data.GetEmpDet[0].LocCity,
    });
    this.setState({
      LocState: data.GetEmpDet[0].LocState,
    });
    this.setState({
      LocPIN: data.GetEmpDet[0].LocPIN,
    });
    this.setState({
      LocCountry: data.GetEmpDet[0].LocCountry,
    });
    this.setState({
      PerAddress1: data.GetEmpDet[0].PerAdd1,
    });
    this.setState({
      PerAddress2: data.GetEmpDet[0].PerAdd2,
    });
    this.setState({
      PerCity: data.GetEmpDet[0].PerCity,
    });
    this.setState({
      PerState: data.GetEmpDet[0].PerState,
    });
    this.setState({
      PerPIN: data.GetEmpDet[0].PerPIN,
    });
    this.setState({
      PerCountry: data.GetEmpDet[0].PerCountry,
    });

    if (
      data.GetEmpDet[0].AdhaarNo.length > 0 &&
      data.GetEmpDet[0].PAN.length > 0
    ) {
      this.setState({ activeTab: "bank-details" });
      this.setState({ childProp: "bank" });
    } else if (
      data.GetEmpDet[0].Fathersname.length > 0 &&
      data.GetEmpDet[0].MotherName.length > 0
    ) {
      this.setState({ activeTab: "other-details" });
      this.setState({ childProp: "other" });
    } else if (
      data.GetEmpDet[0].PersonalEmail.length > 0 &&
      data.GetEmpDet[0].Emergency_No.length > 0 &&
      data.GetEmpDet[0].DOB.length > 0 &&
      data.GetEmpDet[0].Gender.length > 0 &&
      data.GetEmpDet[0].BloodGroup.length > 0 &&
      data.GetEmpDet[0].LocAdd1.length > 0 &&
      data.GetEmpDet[0].LocCity.length > 0 &&
      data.GetEmpDet[0].LocState.length > 0 &&
      data.GetEmpDet[0].LocPIN.length > 0 &&
      data.GetEmpDet[0].LocCountry.length > 0 &&
      data.GetEmpDet[0].PerAdd1.length > 0 &&
      data.GetEmpDet[0].PerCity.length > 0 &&
      data.GetEmpDet[0].PerState.length > 0 &&
      data.GetEmpDet[0].PerCountry.length > 0
    ) {
      this.setState({ activeTab: "family-details" });
      this.setState({ childProp: "family" });
    }
  };

  //Function to handle text to speech
  handleTTS = () => {
    var synthesis = window.speechSynthesis;
    var utterance1 = new SpeechSynthesisUtterance(
      "Welcome. To start self Onboarding, enter all the details required and submit the form"
    );
    synthesis.speak(utterance1);
  };

  //Function to handle tab
  handleTab = (e) => {
    if (e.target.innerHTML === "Personal Information")
      this.setState({ activeTab: "personal-info" });
    else if (e.target.innerHTML === "Family")
      this.setState({ activeTab: "family-details" });
    else if (e.target.innerHTML === "Other Details")
      this.setState({ activeTab: "other-details" });
    else if (e.target.innerHTML === "Bank Details")
      this.setState({ activeTab: "bank-details" });
  };

  //CallBack function
  callbackFunction = (tab) => {
    if (tab === "other") this.setState({ childProp: tab });
    else if (tab === "bank") this.setState({ childProp: tab });
  };

  render() {
    const {
      PersonalEmail,
      EmergencyNo,
      DOB,
      Gender,
      BloodGroup,
      LocAddress1,
      LocCity,
      LocPIN,
      LocCountry,
      PerAddress1,
      PerCity,
      PerState,
      PerPIN,
      PerCountry,
    } = this.state;

    const activePersonal =
      PersonalEmail.length > 0 &&
      EmergencyNo.length > 0 &&
      DOB.length > 0 &&
      Gender.length > 0 &&
      BloodGroup.length > 0 &&
      LocAddress1.length > 0 &&
      LocCity.length > 0 &&
      LocPIN.length > 0 &&
      LocCountry.length > 0 &&
      PerAddress1.length > 0 &&
      PerCity.length > 0 &&
      PerState.length > 0 &&
      PerPIN.length > 0 &&
      PerCountry.length > 0;

    let onselfData = <EmployeeProfile handleEmpData={this.handleEmpData} />;

    return (
      <>
        <div>
          {this.props.viewEmpDet
            ? ""
            : this.state.executeEmpService
            ? onselfData
            : null}
          <div>
            {this.props.viewEmpDet ? (
              ""
            ) : (
              <div className="speech-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="50px"
                  height="39px"
                  viewBox="0 0 655.000000 1280.000000"
                  preserveAspectRatio="xMidYMid meet"
                  onClick={this.handleTTS}
                  style={{ cursor: "pointer" }}
                >
                  <g
                    className="text-black"
                    transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path d="M3080 12714 c-443 -55 -837 -253 -1146 -577 -302 -316 -494 -735 -544 -1182 -8 -71 -10 -875 -8 -2805 l3 -2705 22 -110 c120 -597 439 -1072 918 -1365 711 -437 1643 -325 2243 269 300 296 481 640 575 1096 21 100 21 111 24 2825 3 2433 2 2737 -12 2840 -118 859 -727 1528 -1540 1690 -86 18 -153 23 -305 25 -107 2 -211 1 -230 -1z" />
                    <path d="M112 6962 c-29 -19 -32 -90 -32 -712 0 -860 13 -1067 84 -1377 216 -936 872 -1729 1765 -2133 235 -106 564 -207 789 -242 l82 -12 0 -518 0 -518 -790 0 -790 0 0 -690 0 -690 2055 0 2055 0 0 690 0 690 -790 0 -790 0 0 518 0 518 83 12 c45 7 146 28 223 47 1162 284 2067 1188 2330 2328 71 310 84 517 84 1377 0 622 -3 693 -32 712 -7 4 -223 8 -480 8 l-468 0 -14 -22 c-13 -20 -15 -140 -19 -808 -4 -849 -4 -851 -62 -1081 -183 -720 -756 -1302 -1490 -1512 -468 -134 -991 -113 -1435 58 -659 254 -1148 795 -1315 1454 -58 230 -58 232 -62 1081 -4 668 -6 788 -19 808 l-14 22 -468 0 c-257 0 -473 -4 -480 -8z" />
                  </g>
                </svg>
                <span className="enable-text"> Enable Guidance</span>
                <div className="onboard-style">
                  Onboarding Status :{" "}
                  {this.props.data.OnboardingStatus ||
                    JSON.parse(localStorage.getItem("EmployeeData"))
                      .OnboardingStatus}
                </div>
              </div>
            )}
            {this.props.viewEmpDet ? (
              ""
            ) : (
              <div>
                <p className="self-onboard">Employee Self-Onboarding</p>
                {typeof this.props.viewEmpDet == "undefined" &&
                (this.props.data.OnboardingStatus === "SUBMITTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "SUBMITTED") ? (
                  <div className="submit-status">
                    Data submitted, pending approval!
                  </div>
                ) : (
                  ""
                )}
                {typeof this.props.viewEmpDet == "undefined" &&
                (this.props.data.OnboardingStatus === "REJECTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "REJECTED") ? (
                  <div className="submit-status">
                    Data rejected, submit again!
                  </div>
                ) : (
                  ""
                )}
                {typeof this.props.viewEmpDet == "undefined" &&
                (this.props.data.OnboardingStatus === "APPROVED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED") ? (
                  <div className="submit-status">Data submitted already!</div>
                ) : (
                  ""
                )}
                <hr />
              </div>
            )}

            <div class="row employee-row">
              <div className="employee-avtar">
                <img
                  src="/Images/avtar.jpg"
                  alt="image"
                  style={{
                    width: "49px",
                    height: "44px",
                    borderRadius: "50%",
                  }}
                />
              </div>
              <span class="span-head-text">Name</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="EmpName"
                  disabled
                  value={this.state.EmpName}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Name"
                />
              </div>

              <span class="span-head-text">Emp Id:</span>
              <div class="col info-col">
                <input
                  type="text"
                  name="EmpID"
                  value={this.state.EmpID}
                  disabled
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Emp Id"
                />
              </div>
            </div>

            {/* Tabs */}
            <div className="tab-row">
              <ul class="nav nav-tabs tabClass onbordNav" role="tablist">
                {this.props.viewEmpDet ? (
                  <li
                    className={
                      this.state.activeTab === "personal-info"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#personal-info"
                      name="personal-info"
                      className={
                        this.state.activeTab === "personal-info"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Personal Information
                    </a>
                  </li>
                ) : this.props.data.OnboardingStatus === "APPROVED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED" ||
                  this.props.data.OnboardingStatus === "SUBMITTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "SUBMITTED" ? (
                  <li
                    className={
                      this.state.activeTab === "personal-info"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#personal-info"
                      name="personal-info"
                      className={
                        this.state.activeTab === "personal-info"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Personal Information
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={this.handleTab}>
                    <a
                      // className="nav-link active"
                      className={
                        this.state.activeTab === "personal-info"
                          ? "nav-link active"
                          : " nav-link"
                      }
                      href="#personal-info"
                      data-toggle="tab"
                      ref={(input) => (this.aPersonal = input)}
                    >
                      Personal Information
                    </a>
                  </li>
                )}

                {this.props.viewEmpDet ? (
                  <li
                    class="nav-item"
                    className={
                      this.state.activeTab === "family-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#family-details"
                      name="family-details"
                      className={
                        this.state.activeTab === "family-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Family
                    </a>
                  </li>
                ) : this.props.data.OnboardingStatus === "APPROVED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED" ||
                  this.props.data.OnboardingStatus === "SUBMITTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "SUBMITTED" ? (
                  <li
                    className={
                      this.state.activeTab === "family-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#family-details"
                      name="family-details"
                      className={
                        this.state.activeTab === "family-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Family
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={this.handleTab}>
                    <a
                      data-toggle="tab"
                      href="#family-details"
                      name="family-details"
                      className={
                        this.state.activeTab === "family-details"
                          ? activePersonal
                            ? "nav-link active 1"
                            : "nav-link disabled 2"
                          : activePersonal
                          ? "nav-link 3"
                          : "nav-link disabled 4"
                      }
                      ref={(input) => (this.aFamily = input)}
                    >
                      Family
                    </a>
                  </li>
                )}

                {this.props.viewEmpDet ? (
                  <li
                    className={
                      this.state.activeTab === "other-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#other-details"
                      name="other-details"
                      className={
                        this.state.activeTab === "other-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Other Details
                    </a>
                  </li>
                ) : this.props.data.OnboardingStatus === "APPROVED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED" ||
                  this.props.data.OnboardingStatus === "SUBMITTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "SUBMITTED" ? (
                  <li
                    className={
                      this.state.activeTab === "other-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#other-details"
                      name="other-details"
                      className={
                        this.state.activeTab === "other-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Other Details
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={this.handleTab}>
                    <a
                      data-toggle="tab"
                      href="#other-details"
                      name="other-details"
                      className={
                        this.state.activeTab === "other-details"
                          ? this.state.childProp === "bank" ||
                            this.state.childProp === "other"
                            ? "nav-link active 1"
                            : "nav-link disabled 2"
                          : this.state.childProp === "bank" ||
                            this.state.childProp === "other"
                          ? "nav-link 3"
                          : "nav-link disabled disable-icon 4"
                      }
                      ref={(input) => (this.aOther = input)}
                    >
                      Other Details
                    </a>
                  </li>
                )}

                {this.props.viewEmpDet ? (
                  <li
                    className={
                      this.state.activeTab === "bank-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#bank-details"
                      name="bank-details"
                      className={
                        this.state.activeTab === "bank-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Bank Details
                    </a>
                  </li>
                ) : this.props.data.OnboardingStatus === "APPROVED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED" ||
                  this.props.data.OnboardingStatus === "SUBMITTED" ||
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "SUBMITTED" ? (
                  <li
                    className={
                      this.state.activeTab === "bank-details"
                        ? "nav-item active"
                        : "nav-item"
                    }
                    onClick={this.handleTab}
                  >
                    <a
                      data-toggle="tab"
                      href="#bank-details"
                      name="bank-details"
                      className={
                        this.state.activeTab === "bank-details"
                          ? "nav-link active"
                          : "nav-link"
                      }
                    >
                      Bank Details
                    </a>
                  </li>
                ) : (
                  <li class="nav-item" onClick={this.handleTab}>
                    <a
                      data-toggle="tab"
                      href="#bank-details"
                      name="bank-details"
                      className={
                        this.state.activeTab === "bank-details"
                          ? this.state.childProp === "other" ||
                            this.state.childProp === "bank"
                            ? "nav-link active 1"
                            : "nav-link disabled 2"
                          : this.state.childProp === "bank"
                          ? "nav-link 3"
                          : "nav-link disabled disable-icon 4"
                      }
                      ref={(input) => (this.aBank = input)}
                    >
                      Bank Details
                    </a>
                  </li>
                )}
              </ul>

              {/* Tab panes */}
              <div class="tab-content onboardTab">
                {/* Personal Details tab */}
                <div
                  id="personal-info"
                  className={
                    this.state.activeTab === "personal-info"
                      ? "tab-pane card personal-Info active"
                      : "tab-pane"
                  }
                >
                  <div class="card-header personal-header">
                    Personal Information
                  </div>
                  <div class="card-body">
                    <div class="row identity-info">
                      <span class="pispan-text">
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Office Email
                      </span>
                      <div class="col info-col">
                        <input
                          type="email"
                          name="OfficeMail"
                          disabled
                          value={this.state.OfficeMail}
                          onChange={this.handleChange}
                          class="form-control"
                          placeholder="Office Email"
                          maxLength={30}
                        />
                      </div>

                      <span class="pisecondcoltxt-wid">
                        {" "}
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Personal Email
                      </span>
                      <div class="col info-col">
                        <input
                          type="email"
                          name="PersonalEmail"
                          value={this.state.PersonalEmail}
                          onChange={this.handleChange}
                          class="form-control"
                          placeholder="Personal Email"
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div class="row contact-info">
                      <span class="pispan-text">
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          * &nbsp;
                        </span>
                        Contact no:
                      </span>
                      <div class="col info-col">
                        <input
                          type="text"
                          name="PersonalMobile"
                          disabled
                          value={this.state.PersonalMobile}
                          onChange={this.handleChange}
                          class="form-control contact-input"
                          placeholder="Contact no:"
                          maxlength="13"
                        />
                      </div>

                      <span class="pisecondcoltxt-wid">
                        {" "}
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Emergency Contact no:
                      </span>
                      <div class="col info-col">
                        {/* <input
                          type="text"
                          name="EmergencyNo"
                          value={this.state.EmergencyNo}
                          onChange={this.handleChange}
                          class="form-control contact-input"
                          placeholder="Emergency Contact no:"
                          maxlength="20"
                        /> */}

                 <PhoneInput
                  country={"in"}
                  enableSearch={true}
                  inputProps={{
                    name: "EmergencyNo",
                    required: true,
                  }}
                  inputClass="phoneClass"
                  value={this.state.EmergencyNo}
                  onChange={this.handleChangeEmgNo}
                  onClick={this.currentIp}
                  placeholder="Emergency Contact no:"
                  maxLength={20}
                />
                        {this.state.errorMsg.hasOwnProperty("EmergencyNo") && (
                          <span
                            style={{
                              color: "#ff0000",
                              fontSize: ".8em",
                            }}
                          >
                            Only Numbers are Allowed
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="row dob-row">
                      <span class="pispan-text">
                        {" "}
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Date of birth
                      </span>
                      <div class="col info-col">
                        <input
                          type="date"
                          name="DOB"
                          value={this.state.DOB}
                          onChange={this.handleChange}
                          class="form-control"
                          placeholder="DD/MM/YYYY"
                          maxlength="10"
                        />
                      </div>

                      <span class="gender-span">
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Gender
                      </span>
                      <form className="col info-col">
                        <select
                          name="Gender"
                          value={this.state.Gender}
                          onChange={this.handleChange}
                          class="custom-select gender-select"
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </form>
                      <span class="bGroup-span">
                        {" "}
                        <span
                          className="color-red"
                          style={{ marginLeft: "-6px" }}
                        >
                          *&nbsp;
                        </span>
                        Blood Group
                      </span>
                      <form className="col info-col">
                        <select
                          name="BloodGroup"
                          value={this.state.BloodGroup}
                          onChange={this.handleChange}
                          class="custom-select gender-select"
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option value="O-">O-</option>
                          <option value="O+">O+</option>
                          <option value="A-">A-</option>
                          <option value="A+">A+</option>
                          <option value="B-">B-</option>
                          <option value="B+">B+</option>
                          <option value="AB-">AB-</option>
                          <option value="AB+">AB+</option>
                        </select>
                      </form>
                    </div>

                    <div class="card  adress-card">
                      <div class="card-header localAdd-header">
                        Local Address:
                      </div>
                      <div class="card-body">
                        <div class="row address-row">
                          <span className="address-span">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Address Line 1
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="LocAddress1"
                              value={this.state.LocAddress1}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Address Line 1"
                              maxLength={200}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span className="address-span"> Address Line 2</span>
                          <div class="col ">
                            <input
                              type="text"
                              name="LocAddress2"
                              value={this.state.LocAddress2}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Address Line 2"
                              maxLength={200}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span className="city-span">
                            {" "}
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            City
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="LocCity"
                              value={this.state.LocCity}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="City"
                              maxLength={20}
                            />
                          </div>

                          <span className="state-span">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            State
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="LocState"
                              value={this.state.LocState}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="State"
                              maxLength={15}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span class="postal-span">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Postal code
                          </span>
                          <div class="col">
                            <input
                              type="text"
                              name="LocPIN"
                              value={this.state.LocPIN}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Postal code"
                              maxLength={6}
                            />
                            {this.state.errorMsg.hasOwnProperty("LocPIN") && (
                              <span
                                style={{
                                  color: "#ff0000",
                                  fontSize: ".8em",
                                }}
                              >
                                Only Numbers are Allowed
                              </span>
                            )}
                          </div>

                          <span class="">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Country
                          </span>

                          <div class="col">
                            <ReactFlagSelectCustom
                              countries={COUNTRY_LIST}
                              customLabels={COUNTRY_NAME}
                              searchable={true}
                              ref="locCountryRef"
                              onSelect={(e) => {
                                this.setState({ LocCountry: e });
                                console.log(LocCountry);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card  adress-card">
                      <div class="card-header localAdd-header">
                        Permanent Address:
                      </div>
                      <div class="card-body">
                        <div class="row address-row">
                          <span className="address-span">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Address Line 1
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="PerAddress1"
                              value={this.state.PerAddress1}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Address Line 1"
                              maxLength={200}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span className="address-span"> Address Line 2</span>
                          <div class="col ">
                            <input
                              type="text"
                              name="PerAddress2"
                              value={this.state.PerAddress2}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Address Line 2"
                              maxLength={200}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span className="city-span">
                            {" "}
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            City
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="PerCity"
                              value={this.state.PerCity}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="City"
                              maxLength={20}
                            />
                          </div>

                          <span className="state-span">
                            {" "}
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            State
                          </span>
                          <div class="col ">
                            <input
                              type="text"
                              name="PerState"
                              value={this.state.PerState}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="State"
                              maxLength={20}
                            />
                          </div>
                        </div>
                        <div class="row address-row">
                          <span class="postal-span">
                            {" "}
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Postal code
                          </span>
                          <div class="col">
                            <input
                              type="text"
                              name="PerPIN"
                              value={this.state.PerPIN}
                              onChange={this.handleChange}
                              class="form-control"
                              placeholder="Postal code"
                              maxLength={6}
                            />
                            {this.state.errorMsg.hasOwnProperty("PerPIN") && (
                              <span
                                style={{
                                  color: "#ff0000",
                                  fontSize: ".8em",
                                }}
                              >
                                Only Numbers are Allowed
                              </span>
                            )}
                          </div>

                          <span class="">
                            <span
                              className="color-red"
                              style={{ marginLeft: "-6px" }}
                            >
                              *&nbsp;
                            </span>
                            Country
                          </span>
                          <div class=" col">
                            <ReactFlagSelectCustom
                              countries={COUNTRY_LIST}
                              customLabels={COUNTRY_NAME}
                              searchable={true}
                              ref="perCountryRef"
                              onSelect={(e) => {
                                this.setState({ PerCountry: e });
                                console.log(PerCountry);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="button-div">
                      {
                        this.props.viewEmpDet ? (
                          <div style={{ textAlign: "right", marginBottom: "10px" }}>
                            <button className="btn btn-primary" onClick={()=>{this.props.viewhistoryProp.history.goBack()}}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
                            <Mutation
                              mutation={ADD_EMP_DATA}
                              onCompleted={(data) => {
                                this.handleMutation(data);
                                //this.setState({ activeTab: "family-details" });
                                //this.aFamily.click(); // temp solution to cause app to re-render
                              }}
                            >
                              {(addPersonalDet, { data, loading, error }) => (
                               
                                  <button
                                    type="submit"
                                    className={"btn btn-primary btn-cursor"}
                                    name="personal"
                                    
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addPersonalDet({
                                        variables: {
                                          LocState: this.state.LocState,
                                          PerCountry: this.state.PerCountry,
                                          LocPIN: this.state.LocPIN,
                                          LocCountry: this.state.LocCountry,
                                          LocCity: this.state.LocCity,
                                          PerAddress1: this.state.PerAddress1,
                                          PersonalEmail: this.state.PersonalEmail,
                                          PersonalMobile: this.state.PersonalMobile,
                                          EmpID: this.state.EmpID,
                                          Gender: this.state.Gender,
                                          PerAddress2: this.state.PerAddress2,
                                          PerState: this.state.PerState,
                                          EmpName: this.state.EmpName,
                                          EmergencyNo: this.state.EmergencyNo,
                                          PerCity: this.state.PerCity,
                                          LocAddress1: this.state.LocAddress1,
                                          PerPIN: this.state.PerPIN,
                                          BloodGroup: this.state.BloodGroup,
                                          LocAddress2: this.state.LocAddress2,
                                          OfficeMob: this.state.OfficeMob,
                                          Branch: this.state.Branch,
                                          DOB: this.state.DOB,
                                        },
                                      });
                                    }}
                                  >
                                    Update Emp Details
                                  </button>
                                
                              )}
                            </Mutation>
                          </div>
                        ) : this.props.data.OnboardingStatus === "APPROVED" || JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus === "APPROVED" || this.props.data.OnboardingStatus === "SUBMITTED" ||JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus === "SUBMITTED" ? ("") : 
                          (
                            <Mutation
                              mutation={ADD_EMP_DATA}
                              onCompleted={(data) => {
                                this.handleMutation(data);
                                this.setState({ activeTab: "family-details" });
                                this.aFamily.click(); // temp solution to cause app to re-render
                              }}
                            >
                              {(addPersonalDet, { data, loading, error }) => (
                                <div>
                                  <button
                                    type="submit"
                                    className={"btn btn-primary btn-cursor"}
                                    name="personal"
                                    disabled={activePersonal ? "" : "disabled"}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      addPersonalDet({
                                        variables: {
                                          LocState: this.state.LocState,
                                          PerCountry: this.state.PerCountry,
                                          LocPIN: this.state.LocPIN,
                                          LocCountry: this.state.LocCountry,
                                          LocCity: this.state.LocCity,
                                          PerAddress1: this.state.PerAddress1,
                                          PersonalEmail: this.state.PersonalEmail,
                                          PersonalMobile: this.state.PersonalMobile,
                                          EmpID: this.state.EmpID,
                                          Gender: this.state.Gender,
                                          PerAddress2: this.state.PerAddress2,
                                          PerState: this.state.PerState,
                                          EmpName: this.state.EmpName,
                                          EmergencyNo: this.state.EmergencyNo,
                                          PerCity: this.state.PerCity,
                                          LocAddress1: this.state.LocAddress1,
                                          PerPIN: this.state.PerPIN,
                                          BloodGroup: this.state.BloodGroup,
                                          LocAddress2: this.state.LocAddress2,
                                          OfficeMob: this.state.OfficeMob,
                                          Branch: this.state.Branch,
                                          DOB: this.state.DOB,
                                        },
                                      });
                                    }}
                                  >
                                    Save and Next
                                  </button>
                                </div>
                              )}
                            </Mutation>
                          )
                      }
                    </div>
                  </div>
                </div>

                {/* Family */}
                <div
                  id="family-details"
                  className={
                    this.state.activeTab === "family-details"
                      ? "tab-pane active"
                      : "tab-pane "
                  }
                >
                  <FamilyTab
                    parentCallback={this.callbackFunction}
                    onboardingStatus={
                      this.props?.viewEmpDet
                        ? ""
                        : this.props.data.OnboardingStatus
                    }
                    viewEmpDet={this.props?.viewEmpDet ? true : false}
                    //empProfData={this.state.empProfData}
                    empData={
                      this.props?.viewEmpDet
                        ? this.props.empData
                        : this.state.empProfData
                    }
                    tabName={this.handleTabFromChild}
                  />
                </div>

                {/* OtherDetails tab */}
                <div
                  id="other-details"
                  className={
                    this.state.activeTab === "other-details"
                      ? "tab-pane active"
                      : "tab-pane "
                  }
                >
                  <OtherDetailTab
                    parentCallback={this.callbackFunction}
                    onboardingStatus={
                      this.props?.viewEmpDet
                        ? ""
                        : this.props.data.OnboardingStatus
                    }
                    viewEmpDet={this.props?.viewEmpDet ? true : false}
                    empData={
                      this.props?.viewEmpDet
                        ? this.props.empData
                        : this.state.empProfData
                    }
                    tabName={this.handleTabFromChild}
                  />
                </div>
                {/* BankDetails tab */}
                <div
                  id="bank-details"
                  className={
                    this.state.activeTab === "bank-details"
                      ? "tab-pane active"
                      : "tab-pane"
                  }
                >
                  <BankDetailTab
                    onboardingStatus={
                      this.props?.viewEmpDet
                        ? ""
                        : this.props.data.OnboardingStatus
                    }
                    viewEmpDet={this.props?.viewEmpDet ? true : false}
                    empData={
                      this.props?.viewEmpDet
                        ? this.props.empData
                        : this.state.empProfData
                    }
                    historyProp={
                      this.props?.viewEmpDet
                        ? this.props.viewhistoryProp
                        : this.props
                    }
                    // historyProp={this.props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
