import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import { Context } from "../../Utils/Context";
import ReactFlagSelectCustom from "../../Utils/ReactFlagSelectCustom";
//import ReactFlagsSelect from "react-flags-select";
//import "react-flags-select/css/react-flags-select.css";

import { COUNTRY_LIST, CUSTOM_LABELS, COUNTRY_NAME } from "../../Utils/Data";

export default class SuperadminConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addrLine1: "",
      addrLine2: "",
      city: "",
      zipCode: "",
      country: "",
      countryCode: "",
      adminMail: "",
      organisation: "",
      logo: "",
      minorVersion: "",
      companyId: "",
      baseUrl: "",
      matrixServername: "",
    };
  }

  handleChange = (e, type = "") => {
    if (
      typeof e === "string" &&
      type === "countryCode" &&
      typeof e.target === "undefined"
    )
      this.setState({ countryCode: e });
    else if (
      typeof e === "string" &&
      type === "countryName" &&
      typeof e.target === "undefined"
    )
      this.setState({ country: e });
    else {
      this.setState({ [e.target.name]: e.target.value });
    }
    // setting country code on selecting coutry
    this.refs.userFlag.updateSelected(e);
    this.refs.countryNameflag.updateSelected(e);
  };

  render() {
    return (
      <div>
        <div className="card cmp-card-wid">
          <div className="card-header cmp-headr-styl">Company Profile</div>
          <div className="card-body">
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Organization</label>
                <input
                  type="text"
                  class="form-control"
                  name="organisation"
                  value={this.state.organisation}
                  onChange={this.handleChange}
                  placeholder="Organization"
                />
              </div>
              <div class="form-group col">
                <label className="labl-font">Admin</label>
                <input
                  type="email"
                  class="form-control"
                  name="adminMail"
                  value={this.state.adminMail}
                  onChange={this.handleChange}
                  placeholder="admin@kibbcom.in"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Address</label>
                <input
                  type="text"
                  class="form-control"
                  name="addrLine1"
                  value={this.state.addrLine1}
                  onChange={this.handleChange}
                  placeholder="Address Line no 1"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <input
                  type="text"
                  class="form-control "
                  name="addrLine2"
                  value={this.state.addrLine2}
                  onChange={this.handleChange}
                  placeholder="Address Line no 2"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col-md-4">
                <label className="labl-font">City</label>
                <input
                  type="text"
                  class="form-control"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleChange}
                  placeholder="City"
                />
              </div>
              <div class="form-group col-md-4">
                <label className="labl-font">Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  value={this.state.zipCode}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Zip Code"
                />
              </div>
              <div class="form-group col-md-4">
                <label className="labl-font">Company Logo</label>
                <form>
                  <div class="custom-file">
                    <input
                      style={{ overflow: "hidden" }}
                      type="file"
                      accept="image/*"
                      name="logo"
                      onChange={this.handleChange}
                      className="form-control pading-ht"
                      id="logo"
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Country</label>
                <div>
                  <ReactFlagSelectCustom
                    countries={COUNTRY_LIST}
                    customLabels={COUNTRY_NAME}
                    ref="countryNameflag"
                    searchable={true}
                    onSelect={(e) => {
                      this.handleChange(e, "countryName");
                    }}
                  />
                </div>
              </div>
              <div class="form-group col">
                <label className="labl-font">Country Code</label>
                <div>
                  <ReactFlagSelectCustom
                    countries={COUNTRY_LIST}
                    customLabels={CUSTOM_LABELS}
                    ref="userFlag"
                    searchable={true}
                    onSelect={(e) => {
                      this.handleChange(e, "countryCode");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card cmp-card-wid">
          <div className="card-header cmp-headr-styl">Intuit Details</div>
          <div className="card-body">
            <div className="row">
              <div class="form-group col">
                <label className="labl-font"> Minor Version</label>
                <input
                  type="text"
                  class="form-control"
                  name="minorVersion"
                  value={this.state.minorVersion}
                  onChange={this.handleChange}
                />
              </div>
              <div class="form-group col">
                <label className="labl-font">Company Id</label>
                <input
                  type="text"
                  class="form-control"
                  name="companyId"
                  value={this.state.companyId}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Base URL</label>
                <input
                  type="text"
                  class="form-control"
                  name="baseUrl"
                  value={this.state.baseUrl}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card cmp-card-wid">
          <div className="card-header cmp-headr-styl">Matrix Details</div>
          <div className="card-body">
            <div className="row">
              <div class="form-group col">
                <label className="labl-font"> Matrix Server Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="matrixServername"
                  value={this.state.matrixServername}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary">Save </button>
      </div>
    );
  }
}
