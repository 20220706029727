import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Dashboard from "./Dashboard.js";
import AddUser from "./AddUser";
import EmployeeData from "./EmployeeData";
import EmployeeProfile from "./EmployeeProfile";
import UpdateProfile from "./UpdateProfile";
import SelfOnboarding from "./SelfOnboarding";
import "./index.css";
import ViewDetails from "./ViewDetails";
import UserPayroll from "./UserPayroll";
import Settings from "./Settings";
import ManageProfile from "./ManageProfile";
import { Context } from "../../Utils/Context";
import gql from "graphql-tag";
import OnboardedEmp from "./OnboardedEmp";
import PendingRequest from "./PendingRequest";
import SuperadminConfig from "../SuperAdmin/SuperadminConfig";

export default class Home extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    console.log(this.props);
    if (window.performance) {
      if (performance.navigation.type == 1) {
        if (props.location.pathname === "/home/settings")
          this.state = {
            headerText: "Settings",
            selectedItem: 5,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (
          props.location.pathname === "/home/employeeData" ||
          props.location.pathname === "/home/viewdetails" ||
          props.location.pathname === "/home/userPayrollConfig"
        )
          this.state = {
            headerText: "Employee Data",
            selectedItem: 4,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (props.location.pathname === "/home/addUser")
          this.state = {
            headerText: "Add User",
            selectedItem: 3,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (
          props.location.pathname === "/home/superadmin/superadminconfig"
        )
          this.state = {
            headerText: "Superadmin Configuration",
            selectedItem: 7,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (props.location.pathname === "/home/manageProfile")
          this.state = {
            headerText: "Profile",
            selectedItem: 6,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (props.location.pathname === "/home/dashboard")
          this.state = {
            headerText: "Dashboard",
            selectedItem: 0,
            profilePic: "",
            adminFetchPic: "",
          };
        else if (props.location.pathname === "/home/selfOnboarding")
          this.state = {
            headerText: "Self-Onboarding",
            selectedItem: 1,
            profilePic: "",
          };
        else if (props.location.pathname === "/home/employeeProfile") {
          this.state = {
            headerText: "My Profile",
            selectedItem: 2,
            profilePic: "",
          };
          console.log("This page is reloaded", this.props);
          console.log(this.state);
        } else {
          this.state = {
            profilePic: "",
            adminFetchPic: "",
          };
        }
      } else if (
        (this.props.superadmin &&
          this.props.superadmin.UserType === "SUPERADMIN") ||
        (localStorage.getItem("EmployeeData") != null &&
          JSON.parse(localStorage.getItem("EmployeeData")).UserType ===
            "SUPERADMIN")
      ) {
        console.log("This page is not reloaded superadmin");
        this.state = {
          headerText: "Superadmin Config",
          selectedItem: 7,
          profilePic: "",
          adminFetchPic: "",
        };
      } else {
        if (props.location.pathname === "/home/employeeData") {
          console.log("employeeeeeee headr", this.props);
          this.state = {
            headerText: "Employee Data",
            selectedItem: 4,
            profilePic: "",
            adminFetchPic: "",
          };
        } else {
          console.log("This page is not reloaded normal");
          this.state = {
            headerText: "Dashboard",
            selectedItem: 0,
            profilePic: "",
            adminFetchPic: "",
          };
        }
      }
    }
  }
  async componentDidMount() {
    // Query to fetch company profile data

    let GET_PHOTO = "";

    if (this.props.userData && this.props.userData.EmpID) {
      GET_PHOTO = gql`
        {
          GetBase64String(EmpID:"${this.props.userData.EmpID}") {
            Base64String
          }
        }`;
    } else {
      if (localStorage.getItem("EmployeeData") != null) {
        GET_PHOTO = gql`
          {
            GetBase64String(EmpID: "${
              JSON.parse(localStorage.getItem("EmployeeData")).EmpId
            }") {
              Base64String
            }
          }
        `;
      }
    }

    var resPhoto = await this.context.client.query({
      query: GET_PHOTO,
    });

    if (resPhoto && resPhoto.data && resPhoto.data.GetBase64String.length > 0) {
      if (
        typeof resPhoto.data?.GetBase64String[0]?.Base64String !==
          "undefined" &&
        resPhoto.data?.GetBase64String[0].Base64String !== "N/A"
      ) {
        let str = resPhoto.data?.GetBase64String[0].Base64String;
        this.setState({ adminFetchPic: str });

        let res1 = str.substr(18, str.length);
        let res = res1.slice(0, -1);
        this.setState({ profilePic: res });
      }
    }
  }

  setHeaderText = (headerName) => {
    switch (headerName) {
      case "Dashboard":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 0,
        }));
        break;
      case "Self-Onboarding":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 1,
        }));
        break;
      case "My Profile":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 2,
        }));
        break;
      case "Add User":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 3,
        }));
        break;
      case "Employee Data":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 4,
        }));
        break;
      case "Settings":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 5,
        }));
        break;
      case "Profile":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 6,
        }));
        break;
      case "Superadmin Config":
        this.setState((state) => ({
          headerText: headerName,
          selectedItem: 7,
        }));
        break;
    }
  };

  render() {
    //  super-admin
    if ((this.props.superadmin && this.props.superadmin.UserType === "SUPERADMIN") ||(localStorage.getItem("EmployeeData") != null && JSON.parse(localStorage.getItem("EmployeeData")).UserType ==="SUPERADMIN")) {
      return (
        <Router>
          <div className="wrapper">
            <div
              class="sidebar"
              data-color="azure"
              data-image="/assets/img/sidebar-4.jpg"
            >
              <div class="sidebar-wrapper">
                <div className="logo kico-logo-bg">
                  <img className="kicologo" src="/Images/kibbcom.png" />
                </div>
                <div
                  style={{
                    backgroundImage: "url('/Images/sidebarInner.jpg')",
                    padding: "66px",
                    backgroundSize: "260px 131px",
                  }}
                >
                  {this.state.adminFetchPic !== "" ? (
                    <img
                      src={this.state.adminFetchPic}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  ) : (
                    <img
                      src={"/Images/avtar.jpg"}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  )}
                </div>
                <ul className="nav">
                  <li
                    className={
                      this.state.selectedItem == 6
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/manageProfile"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Profile")}
                    >
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <p>Profile</p>
                    </Link>
                  </li>
                  {(this.props.userData &&
                    this.props.userData.UserType === "SUPERADMIN") ||
                  (localStorage.getItem("EmployeeData") != null &&
                    JSON.parse(localStorage.getItem("EmployeeData"))
                      .UserType === "SUPERADMIN") ? (
                    <li
                      className={
                        this.state.selectedItem == 7
                          ? "nav-item active "
                          : "nav-item"
                      }
                    >
                      <Link
                        to={"/home/superadmin/superadminconfig"}
                        className="nav-link"
                        onClick={() => this.setHeaderText("Superadmin Config")}
                      >
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <p>Admin configurations</p>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div
                class="sidebar-background"
                style={{
                  backgroundImage: "url(/assets/img/sidebar-4.jpg)",
                }}
              ></div>
            </div>
            <div className="main-panel">
              <Header
                data={this.props.superadmin}
                headerName={this.state.headerText}
              />
              <div className="content">
                <div className="container-fluid">
                  <Switch>
                    <Route
                      exact
                      path={"/home/superadmin/superadminconfig"}
                      render={(props) => (
                        <SuperadminConfig
                          superadmin={this.props.superadmin}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={"/home/manageProfile"}
                      render={(props) => (
                        <ManageProfile data={this.props.userData} {...props} />
                      )}
                    />
                  </Switch>
                </div>
              </div>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </Router>
      );
    }
    // admin
    else if ((this.props.userData && this.props.userData.UserType === "admin") ||(localStorage.getItem("EmployeeData") != null && JSON.parse(localStorage.getItem("EmployeeData")).UserType === "admin")) {
      return (
        <Router>
          <div className="wrapper">
            <div class="sidebar" data-color="azure" data-image="/assets/img/sidebar-4.jpg">
              <div class="sidebar-wrapper">
                <div className="logo kico-logo-bg">
                  <img className="kicologo" src="/Images/kibbcom.png" />
                </div>
                <div style={{backgroundImage: "url('/Images/sidebarInner.jpg')",padding: "66px",backgroundSize: "260px 131px",}}>
                  {this.state.adminFetchPic !== "" ? (
                    <img
                      src={this.state.adminFetchPic}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  ) : (
                    <img
                      src={"/Images/avtar.jpg"}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  )}
                </div>
                <ul className="nav">
                  <li
                    className={
                      this.state.selectedItem == 0
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/dashboard"}
                      className="nav-link "
                      onClick={() => this.setHeaderText("Dashboard")}
                    >
                      <i className="nc-icon nc-chart-pie-35"></i>
                      <p>Dashboard</p>
                    </Link>
                  </li>

                  <li
                    className={
                      this.state.selectedItem == 3
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/addUser"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Add User")}
                    >
                      <i className="nc-icon nc-circle-09"></i>
                      <p> Add User </p>
                    </Link>
                  </li>
                  <li
                    className={
                      this.state.selectedItem == 4
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/employeeData"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Employee Data")}
                    >
                      <i className="nc-icon nc-notes"></i>
                      <p>Employee Data</p>
                    </Link>
                  </li>
                  <li
                    className={
                      this.state.selectedItem == 5
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/settings"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Settings")}
                    >
                      <i class="fa fa-cogs" aria-hidden="true"></i>
                      <p>Settings</p>
                    </Link>
                  </li>

                  <li
                    className={
                      this.state.selectedItem == 6
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/manageProfile"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Profile")}
                    >
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <p>Profile</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                class="sidebar-background"
                style={{
                  backgroundImage: "url(/assets/img/sidebar-4.jpg)",
                }}
              ></div>
            </div>
            <div className="main-panel">
              <Header data={this.props.userData} headerName={this.state.headerText}/>
              <div className="content">
                <div className="container-fluid">
                  <Switch>
                    <Route
                      exact
                      path={"/home/dashboard"}
                      render={(props) => (
                        <Dashboard data={this.props.userData} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path={"/home"}
                      render={(props) => <Redirect to="/home/dashboard" />}
                    />
                    <Route
                      path={"/home/addUser"}
                      render={(props) => (
                        <AddUser setHeader={this.setHeaderText} {...props} />
                      )}
                    />
                    <Route
                      path={"/home/employeeData"}
                      render={(props) => (
                        <EmployeeData empData={this.props} {...props} />
                      )}
                    />

                    <Route
                      path={"/home/settings"}
                      render={(props) => <Settings {...props} />}
                    />
                    <Route
                      path={"/home/manageProfile"}
                      render={(props) => (
                        <ManageProfile data={this.props.userData} {...props} />
                      )}
                    />
                    <Route
                      path={"/home/viewdetails"}
                      render={(props) => <ViewDetails {...props} />}
                    />
                    <Route
                      path={"/home/onboardedEmp"}
                      render={(props) => <OnboardedEmp {...props} />}
                    />

                    <Route
                      path={"/home/pendingEmp"}
                      render={(props) => <PendingRequest {...props} />}
                    />
                    <Route
                      path={"/home/userPayrollConfig"}
                      render={(props) => <UserPayroll {...props} />}
                    />
                  </Switch>
                </div>
              </div>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </Router>
      );
    }
    // employee
    else if ((this.props.userData && this.props.userData.UserType === "employee") || (localStorage.getItem("EmployeeData") != null && JSON.parse(localStorage.getItem("EmployeeData")).UserType ==="employee")) {
      return (
        <Router>
          <div className="wrapper">
            <div
              class="sidebar"
              data-color="azure"
              data-image="/assets/img/sidebar-4.jpg"
            >
              <div class="sidebar-wrapper">
                <div className="logo kico-logo-bg">
                  <img className="kicologo" src="/Images/kibbcom.png" />
                </div>

                <div
                  style={{
                    backgroundImage: "url('/Images/sidebarInner.jpg')",
                    padding: "66px",
                    backgroundSize: "260px 131px",
                  }}
                >
                  {this.state.profilePic !== "" ? (
                    <img
                      src={this.state.profilePic}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  ) : (
                    <img
                      src={"/Images/avtar.jpg"}
                      style={{
                        position: "absolute",
                        width: "70px",
                        height: "70px",
                        top: "101px",
                        borderRadius: "50%",
                        left: "35px",
                      }}
                    />
                  )}
                </div>
                <ul className="nav">
                  <li
                    className={
                      this.state.selectedItem == 0
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/dashboard"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("Dashboard")}
                    >
                      <i className="nc-icon nc-chart-pie-35"></i>
                      <p>Dashboard</p>
                    </Link>
                  </li>

                  {localStorage.getItem("EmployeeData") != null &&
                  JSON.parse(localStorage.getItem("EmployeeData"))
                    .OnboardingStatus === "APPROVED" ? (
                    ""
                  ) : (
                    <li
                      className={
                        this.state.selectedItem == 1
                          ? "nav-item active "
                          : "nav-item"
                      }
                    >
                      <Link
                        to={"/home/selfOnboarding"}
                        className="nav-link"
                        onClick={() => this.setHeaderText("Self-Onboarding")}
                      >
                        <i className="nc-icon nc-single-02"></i>
                        <p>Self-Onboarding</p>
                      </Link>
                    </li>
                  )}

                  <li
                    className={
                      this.state.selectedItem == 2
                        ? "nav-item active "
                        : "nav-item"
                    }
                  >
                    <Link
                      to={"/home/employeeProfile"}
                      className="nav-link"
                      onClick={() => this.setHeaderText("My Profile")}
                    >
                      <i className="nc-icon nc-paper-2"></i>
                      <p>My Profile</p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                class="sidebar-background"
                style={{
                  backgroundImage: "url(/assets/img/sidebar-4.jpg)",
                }}
              ></div>
            </div>
            <div className="main-panel">
              <Header
                data={this.props.userData}
                headerName={this.state.headerText}
              />
              <div className="content">
                <div className="container-fluid">
                  <Switch>
                    <Route
                      exact
                      path={"/home/dashboard"}
                      render={(props) => (
                        <Dashboard data={this.props.userData} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path={"/home/selfOnboarding"}
                      render={(props) => (
                        <SelfOnboarding data={this.props.userData} {...props} />
                      )}
                    />

                    <Route
                      exact
                      path={"/home/EmployeeProfile"}
                      render={(props) => (
                        <EmployeeProfile
                          data={this.props.userData}
                          {...props}
                        />
                      )}
                    />

                    <Route
                      exact
                      path={"/home/updateProfile"}
                      render={(props) => <UpdateProfile {...props} />}
                    />
                  </Switch>
                </div>
              </div>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </Router>
      );
    }
  }
}
