import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import { Context } from "../../Utils/Context";

import EmployeeProfile from "../../Services/EmpProfileData";

// Mutation for adding employee details(self-onboarding)
const ADD_EMP_DATA = gql`
  mutation(
    $EmpID: String!
    $BankAcNo: String!
    $IFSC: String!
    $EmpBank: String!
    $OnboardingStatus: String!
  ) {
    BankDet(
      EmpID: $EmpID
      BankAcNo: $BankAcNo
      IFSC: $IFSC
      EmpBank: $EmpBank
      OnboardingStatus: $OnboardingStatus
    ) {
      Message
      Confirmed
    }
  }
`;
// Mutation for approving employee
const APPROVE_EMP = gql`
  mutation($EmpID: String!, $OnboardingStatus: String!) {
    ApproveDet(EmpID: $EmpID, OnboardingStatus: $OnboardingStatus) {
      Message
      Confirmed
    }
  }
`;

// Mutation for rejecting employee
const REJECT_EMP = gql`
  mutation($EmpID: String!, $OnboardingStatus: String!) {
    RejectDet(EmpID: $EmpID, OnboardingStatus: $OnboardingStatus) {
      Message
      Confirmed
    }
  }
`;

export default class BankDetailTab extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      executeEmpService: true,
      updateText: false,
      errorMsg: {},
      disableSubmitBtn: false,
      EmpID:
        typeof this.props?.empData == "undefined"
          ? JSON.parse(localStorage.getItem("EmployeeData")).EmpId
          : this.props.empData.EmpID,
      IFSC:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.IFSCcode,
      EmpBank:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.EmployeeBank,
      BankAcNo:
        typeof this.props?.empData == "undefined"
          ? ""
          : this.props.empData.BankAcNo,
      OnboardingStatus:typeof this.props?.empData == "undefined" ? "": this.props?.empData?.OnboardingStatus,
    };
    this.handleMutationApprove = this.handleMutationApprove.bind(this);
  }

  componentDidMount() {
    this.checkApproveBtnState();
    this.checkSubmitBtnStatus();
  }

  //Function for handling approve mutation
  handleMutationApprove(data) {
    console.log("inside handle approve");
    console.log(data.ApproveDet);
    if (data.ApproveDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        `${this.state.EmpID}Employee approved Successfully`,
        "Successful!",
        2000
      );
      setTimeout(function () {
        window.location.reload();
      }, 2000);
      this.props.historyProp.history.push("/home/employeeData");
    } else if (data.ApproveDet.Confirmed === "1") {
      NotificationManager.error(
        `Error while ading ${this.state.EmpID}`,
        "Error!"
      );
    }
  }

  // Function to handle change
  handleChange = (e) => {
    if (e.target.name === "BankAcNo") {
      this.setState({ [e.target.name]: e.target.value.replace(/[^0-9]/g, "") });
      if (!/^[0-9]+$/.test(e.target.value)) {
        this.setState({
          errorMsg: { ...this.state.errorMsg, [e.target.name]: e.target.name },
        });
      } else {
        if (this.state.errorMsg.hasOwnProperty(e.target.name)) {
          const { [e.target.name]: name, ...newObject } = this.state.errorMsg;
          this.setState({ errorMsg: {} });
          this.setState({ errorMsg: newObject });
          console.log(this.state.errorMsg);
        }
      }
    } else this.setState({ updateText: true });
    this.setState({ [e.target.name]: e.target.value });
  };

  // Function to handle reject mutation
  handleMutationReject(data) {
    console.log("inside handle reject");
    console.log(data.RejectDet);
    if (data.RejectDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        `${this.state.EmpID}Employee details rejected`,
        "Rejected!",
        2000
      );
      setTimeout(function () {
        window.location.reload();
      }, 2000);
      this.props.historyProp.history.push("/home/employeeData");
    } else if (data.RejectDet.Confirmed === "1") {
      NotificationManager.error(
        `Error while rejecting ${this.state.EmpID}'s details`,
        "Error!"
      );
    }
  }

  //Function to handle Self-onboarding mutation for bank details
  handleMutation = (data, navigate=true) => {
    if (data.BankDet.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Bank Details added Successfully",
        "Successful!",
        2000
      );
      if(navigate) this.props.historyProp.history.push("/home/dashboard");
    } else if (data.BankDet.Confirmed === "1") {
      NotificationManager.error("Error while adding data", "Error!");
    }
  };

  // function to disable Approve and Reject button for admin
  checkApproveBtnState() {
    if (this.props.viewEmpDet) {
      if (
        (this.props.empData &&
          this.props.empData.OnboardingStatus &&
          this.props.empData.OnboardingStatus === "APPROVED") ||
        (this.props.empData &&
          this.props.empData.OnboardingStatus &&
          this.props.empData.OnboardingStatus === "REJECTED")
      ) {
        this.setState({ disableApprovebtn: true });
        this.setState({ disableRejectBtn: true });
      } else {
        this.setState({ disableApprovebtn: false });
        this.setState({ disableRejectBtn: false });
      }
    }
  }

  // function to disable Submit button for employee
  checkSubmitBtnStatus() {
    if (
      (this.props.data &&
        this.props.data.OnboardingStatus &&
        this.props.data.OnboardingStatus === "APPROVED") ||
      JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus ===
        "APPROVED" ||
      (this.props.data &&
        this.props.data.OnboardingStatus &&
        this.props.data.OnboardingStatus === "SUBMITTED") ||
      JSON.parse(localStorage.getItem("EmployeeData")).OnboardingStatus ===
        "SUBMITTED"
    ) {
      this.setState({ disableSubmitBtn: true });
    } else {
      this.setState({ disableSubmitBtn: false });
    }
  }

  // Function to call quickbooks api
  connectToQb = async (empid) => {
    console.log(empid);
    const CONNECT2qb = gql`
      {
        Connect2QB(EmpID:"${empid}"){
          Message
        }
      }
    `;
    var res = await this.context.client.query({
      query: CONNECT2qb,
    });
    NotificationManager.success("Data sent  Successfully", "Successful!", 2000);
    this.props.historyProp.history.push("/home/employeeData");
  };

  // Function to set state for previously added data
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (!this.props.viewEmpDet && !this.state.updateText) {
      this.setState({
        IFSC: nextProps.empData.IFSCcode,
      });
      this.setState({
        EmpBank: nextProps.empData.EmployeeBank,
      });
      this.setState({
        BankAcNo: nextProps.empData.BankAcNo,
      });
    }
  }
  render() {
    const { EmpBank, BankAcNo, IFSC } = this.state;
    const isEnabledBank =
      typeof EmpBank !== "undefined" &&
      EmpBank.length > 0 &&
      typeof BankAcNo !== "undefined" &&
      BankAcNo.length > 0 &&
      typeof IFSC !== "undefined" &&
      IFSC.length > 0;
    return (
      <div>
        <div class="card personal-Info">
          <div class="card-header bank-header">BankDetails</div>
          <div class="card-body">
            <div class="row">
              <span class="banktxt-width">
                <span className="color-red">*&nbsp;</span>Bank Name
              </span>
              <div class="col info-col">
                <input
                  type="text"
                  class="form-control"
                  name="EmpBank"
                  value={this.state.EmpBank}
                  onChange={this.handleChange}
                  placeholder="Bank Name"
                  maxLength={20}
                />
              </div>

              <span class="banktxt-width">
                <span className="color-red">*&nbsp;</span>Account No.
              </span>
              <div class="col info-col">
                <input
                  type="text"
                  class="form-control"
                  name="BankAcNo"
                  value={this.state.BankAcNo}
                  onChange={this.handleChange}
                  placeholder="Account No."
                  maxLength={30}
                />
                {this.state.errorMsg.hasOwnProperty("BankAcNo") && (
                  <span
                    style={{
                      color: "#ff0000",
                      fontSize: ".8em",
                    }}
                  >
                    Only Numbers are Allowed
                  </span>
                )}
              </div>
            </div>
            <div class="row address-info">
              <span class="banktxt-width">
                <span className="color-red">*&nbsp;</span>IFSC Code
              </span>
              <div class="col info-col">
                <input
                  type="text"
                  class="form-control"
                  name="IFSC"
                  value={this.state.IFSC}
                  onChange={this.handleChange}
                  placeholder="IFSC Code"
                  maxLength={11}
                />
              </div>
              <span class="banktxt-width"></span>
              <div class="col info-col"></div>
            </div>

            {/* Submit button for employee */}
            {this.props.viewEmpDet ? (
               <Mutation
               mutation={ADD_EMP_DATA}
               onCompleted={(data) => {
                 this.handleMutation(data, false);
                 
               }}
             >
               {(addEmpDetails, { data, loading, error }) => (
                 <div
                   className={
                     this.state.disableSubmitBtn ? "btn-cursor" : "", "approve-btn"
                   }
                 >
                   <button
                     type="submit"
                     className={
                       !isEnabledBank
                         ? "btn btn-primary disablebtn "
                         : "btn btn-primary"
                     }
                     disabled={this.state.BankAcNo =='' || this.state.EmpBank=='' || this.state.IFSC==''}
                     onClick={(e) => {
                       e.preventDefault();
                       addEmpDetails({
                         variables: {
                           EmpID: this.state.EmpID,
                           BankAcNo: this.state.BankAcNo,
                           EmpBank: this.state.EmpBank,
                           IFSC: this.state.IFSC,
                           OnboardingStatus:this.state.OnboardingStatus
                         },
                       });
                     }}
                   >
                      Update Emp Detials 
                   </button>
                 </div>
               )}
             </Mutation>
            ) : (
              <div className="button-div">
                <Mutation
                  mutation={ADD_EMP_DATA}
                  onCompleted={(data) => {
                    this.handleMutation(data);
                    this.setState({ activeTab: "bank-details" });
                  }}
                >
                  {(addEmpDetails, { data, loading, error }) => (
                    <div
                      className={
                        this.state.disableSubmitBtn ? "btn-cursor" : ""
                      }
                    >
                      <button
                        type="submit"
                        className={
                          !isEnabledBank
                            ? "btn btn-primary disablebtn "
                            : "btn btn-primary"
                        }
                        disabled={!isEnabledBank || this.state.disableSubmitBtn}
                        onClick={(e) => {
                          e.preventDefault();
                          addEmpDetails({
                            variables: {
                              EmpID: JSON.parse(
                                localStorage.getItem("EmployeeData")
                              ).EmpId,
                              BankAcNo: this.state.BankAcNo,
                              EmpBank: this.state.EmpBank,
                              IFSC: this.state.IFSC,
                              OnboardingStatus: "SUBMITTED",
                            },
                          });
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </Mutation>
              </div>
            )}

            {/* Approve button for admin */}
            {this.props.viewEmpDet ? (
              <div className="approve-btn">
                <Mutation
                  mutation={APPROVE_EMP}
                  onCompleted={(data) => {
                    this.handleMutationApprove(data);
                  }}
                >
                  {(addEmpDetails, { data, loading, error }) => (
                    <div
                      className={
                        this.state.disableApprovebtn ? "btn-cursor" : ""
                      }
                    >
                      <button
                        type="submit"
                        className={
                          this.state.disableApprovebtn
                            ? "btn btn-primary disablebtn "
                            : "btn btn-primary"
                        }
                        disabled={this.state.disableApprovebtn}
                        onClick={(e) => {
                          e.preventDefault();
                          addEmpDetails({
                            variables: {
                              EmpID: this.state.EmpID,
                              OnboardingStatus: "APPROVED",
                            },
                          });
                        }}
                      >
                        {" "}
                        Approve
                      </button>
                    </div>
                  )}
                </Mutation>
              </div>
            ) : (
              ""
            )}

            {this.props.viewEmpDet ? (
              <div className="reject-btn" style={{ display: "inline-block" }}>
                <Mutation
                  mutation={REJECT_EMP}
                  onCompleted={(data) => {
                    this.handleMutationReject(data);
                  }}
                >
                  {(rejectEmpDetails, { data, loading, error }) => (
                    <div
                      className={
                        this.state.disableApprovebtn ? "btn-cursor" : ""
                      }
                    >
                      <button
                        type="submit"
                        className={
                          this.state.disableApprovebtn
                            ? "btn btn-primary disablebtn "
                            : "btn btn-primary"
                        }
                        disabled={this.state.disableRejectBtn}
                        onClick={(e) => {
                          e.preventDefault();
                          rejectEmpDetails({
                            variables: {
                              EmpID: this.state.EmpID,
                              OnboardingStatus: "REJECTED",
                            },
                          });
                        }}
                      >
                        {" "}
                        Reject
                      </button>
                    </div>
                  )}
                </Mutation>
              </div>
            ) : (
              ""
            )}

            {this.props.viewEmpDet ? (
              <div className="send-btn" style={{ float: "right" }}>
                <button
                  className="btn btn-success"
                  onClick={() => this.connectToQb(this.state.EmpID)}
                >
                  <img
                    src="/Images/qb_logo.png"
                    width="20"
                    height="20"
                    alt="Quickbooks"
                    style={{ marginRight: "10px" }}
                  />
                  Send Data
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
