import React,{useEffect} from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import ModalpopUp from "../Utils/ModalpopUp";

export default function EmployeeData(props) {
  const EMP_DATA = gql`
    {
      getAllEmpDet {
        EmpID
        AdhaarNo
        BankAcNo
        BloodGroup
        DOB
        DOJ
        Emergency_No
        EmployeeBank
        EmpID
        Fathersname
        EmpName
        Gender
        IFSCcode
        LocAdd1
        LocAdd2
        LocCity
        LocCountry
        LocPIN
        LocState
        MotherName
        OfficeMail
        OnboardingStatus
        PAN
        PassportNo
        PerAdd1
        PerAdd2
        PerCity
        PerCountry
        PerState
        PerPIN
        PersonalEmail
        PersonalMobile
        SpouseName
        SpouseAge
        Child
        Child2
        ChildAge
        ChildAge2
        MotherName
        VisaNO
      }
    }
  `;

  // Mutation for Deleting user
  const DEL_EMP = gql`
    mutation($EmpID: String!) {
      DeleteEmployee(EmpID: $EmpID) {
        Message
        Confirmed
      }
    }
  `;

  const history = useHistory();
  const { data, loading, error, refetch  } = useQuery(EMP_DATA);
  useEffect(()=> {
    refetch()
  }, [refetch])
  function showViewDetails(empData) {
    history.push({
      pathname: "/home/viewdetails",
      empData,
    });
  }

  function showaddDetails(idofcmail) {
    history.push({
      pathname: "/home/userPayrollConfig",
      idofcmail,
    });
  }

  

  const [deleteEmployee] = useMutation(DEL_EMP);

  //Function to delete user
  async function deleteEmp(emp) {
    console.log(emp.EmpID);

    var response = await deleteEmployee({
      variables: {
        EmpID: emp.EmpID,
      },
    });
    NotificationManager.success(
      `Employee with ${emp.EmpID} deleted`,
      "Successful!",
      3000
    );
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  if (loading) return <p>Loading...</p>;
  if (error) {
    return <span>Something went wrong</span>;
  }
  if (data) {
    if (
      data.getAllEmpDet.length !== 0 &&
      typeof data.getAllEmpDet[0] !== "undefined"
    ) {
      data.getAllEmpDet.sort((a, b) => (a.EmpID > b.EmpID ? 1 : -1));

      return (
        <>
          <table class="table table-striped emp-mng-table">
            <thead>
              <tr>
                <th scope="col">Employee Id</th>
                <th scope="col">Employee name</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th className="action-style" colSpan="4" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.getAllEmpDet.map((emp) => (
                <tr key={emp.EmpID}>
                  <td>{emp.EmpID}</td>
                  <td>{emp.EmpName}</td>
                  <td>{emp.OfficeMail}</td>
                  <td>{emp.OnboardingStatus}</td>
                  <td className="cursor">
                    <i
                      title="View Details"
                      class="fa fa-eye"
                      aria-hidden="true"
                      onClick={() => showViewDetails(emp)}
                    ></i>
                  </td>
                  <td className="cursor">
                    <i
                      title="Add Details"
                      class="fa fa-plus-circle"
                      onClick={() =>
                        showaddDetails({
                          id: emp.EmpID,
                          ofcmail: emp.OfficeMail,
                        })
                      }
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td>
                    <ModalpopUp empid={emp.EmpID} />
                  </td>
                  <td>
                    <i
                      title="Delete User"
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      onClick={() => deleteEmp(emp)}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    } else {
      return (
        <>
          <table class="table table-striped emp-mng-table">
            <thead>
              <tr>
                <th scope="col">EmpID</th>
                <th scope="col">Employee name</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5">No data found</td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
  }
}
