import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import "./ManageProfile.css";
import { Context } from "../../Utils/Context";

//  let EmpID = JSON.parse(localStorage.getItem("EmployeeData")).EmpId;

// mutation for company branch details
const UPLOAD_PHOTO = gql`
  mutation($EmpID: String!, $Base64String: String!) {
    UpdateImageString(EmpID: $EmpID, Base64String: $Base64String) {
      Message
      Confirmed
    }
  }
`;

export default class ManageProfile extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      pofilePicture: "",
      profileImage_upload: "",
      profilePic_fetch: "",
    };
  }

  async componentDidMount() {
    // Query to fetch company profile data
    const GET_PHOTO = gql`
         {
          GetBase64String(EmpID:"${
            JSON.parse(localStorage.getItem("EmployeeData")).EmpId
          }") {
          Base64String
      }
    }
    `;
    var resPhoto = await this.context.client.query({
      query: GET_PHOTO,
    });
    if (resPhoto && resPhoto.data && resPhoto.data.GetBase64String.length > 0) {
      if (
        typeof resPhoto.data?.GetBase64String[0].Base64String !== "undefined" &&
        resPhoto.data?.GetBase64String[0].Base64String !== "N/A"
      ) {
        let str = resPhoto.data?.GetBase64String[0].Base64String;
        let res1 = str.substr(18, str.length);
        let res = res1.slice(0, -1);
        this.setState({ profilePic_fetch: str });
      }
    }
  }

  handleChange = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    var evt = e.target.name;
    reader.onload = (e) => {
      this.setState({ profileImage_upload: reader.result });
    };
    reader.onerror = function (error) {};
  };

  handleUpload = (data) => {
    if (data.UpdateImageString.Confirmed === "0") {
      NotificationManager.success(
        "Profile photo uploaded Successfully",
        "Successful!",
        2000
      );
      window.location.reload();
    } else if (data.UpdateImageString.Confirmed === "1") {
      NotificationManager.error("Error while uploading photo ", "Error!");
    }
  };

  render() {
    return (
      <div className="card wid-40">
        <div className="card-body">
          <div className="employee-avtar">
            {this.state.profilePic_fetch !== "" ? (
              <img
                src={this.state.profilePic_fetch}
                alt="image"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <img
                src="/Images/avtar.jpg"
                alt="image"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                }}
              />
            )}
            <div className="emp-name">
              {JSON.parse(localStorage.getItem("EmployeeData")).EmpName}
            </div>
            <div className="display-marg">
              <div className="form-group wid-margBotm">
                <input
                  style={{ overflow: "hidden" }}
                  type="file"
                  accept="image/*"
                  className="form-control pading-ht"
                  name="pofilePicture"
                  onChange={this.handleChange}
                  placeholder="Enter Doc Id Proof"
                  required
                />
              </div>
              <Mutation
                mutation={UPLOAD_PHOTO}
                onCompleted={(data) => {
                  this.handleUpload(data);
                }}
              >
                {(uploadPhoto, { data, loading, error }) => (
                  <div>
                    <button
                      type="submit"
                      disabled={
                        this.state.profileImage_upload === "" ? true : false
                      }
                      className="btn btn-primary pading-marg "
                      onClick={(e) => {
                        e.preventDefault();
                        uploadPhoto({
                          variables: {
                            EmpID: JSON.parse(
                              localStorage.getItem("EmployeeData")
                            ).EmpId,
                            Base64String: this.state.profileImage_upload,
                          },
                        });
                      }}
                    >
                      Save
                    </button>
                  </div>
                )}
              </Mutation>
            </div>
          </div>
          <hr />
          <div>
            <ul class="list-group list-group-flush">
              <span>
                <i className="ion-person"></i> CEO
              </span>
              <li class="list-group-item">Kibbcom India</li>
              <span>
                <i className="ion-location"></i> Location
              </span>
              <li class="list-group-item">Sweden</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
