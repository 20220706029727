import React, { Component } from "react";
import Login from "../Login";

export default class SuperAdmin extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div>
        <Login
          {...this.props}
          handleLogin={this.props.handleLogin}
          loggedInStatus={this.props.loggedInStatus}
          usertype="superadmin"
        />
      </div>
    );
  }
}
