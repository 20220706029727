import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer class="footer">
          <div class="container-fluid">
            <nav>
              <p class="copyright text-center">
                <b> Copyright © 2016-20 KibbCom India Pvt Ltd.</b>
              </p>
            </nav>
          </div>
        </footer>
      </div>
    );
  }
}
