// This component is responsible for getting Employee Id
import React, { Component } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export default function GetEmpId(props) {
  const EMP_COUNT = gql`
    {
      GetNewEmpID(Branch: "${props.branchCode}") {
        NewEmpID
      }
    }
  `;

  const { data, loading, error } = useQuery(EMP_COUNT);
  if (loading) return <p>Loading...</p>;
  if (error) {
    return <span>Error</span>;
  }
  if (data) {
    // console.log(data.GetNewEmpID);
    if (data?.GetNewEmpID?.NewEmpID) {
      props.handleEmpID(data);
      return <div>Employee Id : {data.GetNewEmpID.NewEmpID}</div>;
    } else {
      return <span style={{ color: "red" }}>Unable to fetch Employee Id</span>;
    }
  }
}
