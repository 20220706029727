import React, { Component } from "react";
import { Mutation } from "react-apollo";
import { NotificationManager } from "react-notifications";
import gql from "graphql-tag";
import "./UserPayroll.css";
import Defaultdata from "./Defaultdata";
import OfficeInfo from "./OfficeInfo";
import { Context } from "../../Utils/Context";

const PROLL_CONFIG = gql`
  mutation PayrollConfig(
    $PFNo: String
    $SalarTemplate: String
    $PFUAN: String
    $IsPFRestricted: String
    $Designation: String
    $HasESS: String
    $HasPF: String
    $EmpID: String!
    $ESINo: String
    $JobType: String
    $Varaiablepay: String
    $DOJ: String
    $CostCenter: String
    $PFDate: String
    $CTC: String
    $BASIC: String!
  ) {
    PayrollConfig(
      PFNo: $PFNo
      SalarTemplate: $SalarTemplate
      PFUAN: $PFUAN
      IsPFRestricted: $IsPFRestricted
      Designation: $Designation
      HasESS: $HasESS
      HasPF: $HasPF
      EmpID: $EmpID
      ESINo: $ESINo
      JobType: $JobType
      Varaiablepay: $Varaiablepay
      DOJ: $DOJ
      CostCenter: $CostCenter
      PFDate: $PFDate
      CTC: $CTC
      BASIC: $BASIC
    ) {
      Message
      Confirmed
    }
  }
`;

export default class UserPayroll extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    console.log(props);

    if (!localStorage.hasOwnProperty("idofcmail")) {
      localStorage.setItem(
        "idofcmail",
        JSON.stringify(this.props.location.idofcmail)
      );
    }

    this.state = {
      errorMsg: {},
      // payroll config state
      pfNo: "",
      salaryTemplate: "",
      pfUAN: "",
      isPfRestricted: "",
      designation: "",
      hasESS: "",
      hasPF: "",
      empID: "",
      esiNo: "",
      jobType: "",
      variablePay: "",
      joiningDate: "",
      costCenter: "",
      pfDate: "",
      ctc: "",
      // payroll default state
      empID: "",
      fbp: "",
      adminBank: "",
      payMode: "",
      isMetro: "",
      isEpsEntitled: "",
      hasESI: "",
      activeTab: "UserPayroll",
      basic:'',
    };
    this.goBack = this.goBack.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name === "pfNo" ||
      e.target.name === "ctc" ||
      e.target.name === "variablePay" ||
      e.target.name === "esiNo" ||
      e.target.name === "basic" 
    ) {
      this.setState({ [e.target.name]: e.target.value.replace(/[^0-9]/g, "") });
      if (!/^[0-9]+$/.test(e.target.value)) {
        this.setState({
          errorMsg: { ...this.state.errorMsg, [e.target.name]: e.target.name },
        });
      } else {
        if (this.state.errorMsg.hasOwnProperty(e.target.name)) {
          const { [e.target.name]: name, ...newObject } = this.state.errorMsg;
          this.setState({ errorMsg: {} });
          this.setState({ errorMsg: newObject });
          console.log(this.state.errorMsg);
        }
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentDidMount() { console.log("user payroll comp did mount called");
    let id;
    if (this.props?.location?.idofcmail?.id !== "undefined") {
      id = this.props?.location?.idofcmail?.id;
    }
    if (localStorage.hasOwnProperty("idofcmail") && typeof localStorage.getItem("idofcmail") != 'undefined') {
      console.log(localStorage.getItem("idofcmail") != "");
      console.log(JSON.parse(localStorage.getItem("idofcmail")).id);
      id = JSON.parse(localStorage.getItem("idofcmail")).id;
    }

    // Query to fetch payroll config data
    const GET_PCONFIG = gql`
      {
        GetPayrollConfigData(EmpID: "${id}") {
          PFNo
          ESINO
          JobTypeID
          DesignationID
          DOJ
          CostCenter
          PFDate
          CTC
          Variablepay
          SalaryTemplate
          HasPF
          HasESS
          PFUAN
          IsPFRestricted
          BASIC
        }
      }
    `;
    var res = await this.context.client.query({ query: GET_PCONFIG,fetchPolicy: 'network-only' });
    
    Object.keys(res.data.GetPayrollConfigData[0]).forEach(
      (k) =>
        (res.data.GetPayrollConfigData[0][k] =
          res.data.GetPayrollConfigData[0][k] === "N/A"
            ? ""
            : res.data.GetPayrollConfigData[0][k])
    );

    this.setState({ pfNo: res.data.GetPayrollConfigData[0].PFNo });
    this.setState({
      salaryTemplate: res.data.GetPayrollConfigData[0].SalaryTemplate,
    });
    this.setState({ pfUAN: res.data.GetPayrollConfigData[0].PFUAN });
    this.setState({
      isPfRestricted: res.data.GetPayrollConfigData[0].IsPFRestricted,
    });
    this.setState({
      designation: res.data.GetPayrollConfigData[0].DesignationID,
    });
    this.setState({ hasESS: res.data.GetPayrollConfigData[0].HasESS });
    this.setState({ hasPF: res.data.GetPayrollConfigData[0].HasPF });
    this.setState({ esiNo: res.data.GetPayrollConfigData[0].ESINO });
    this.setState({ jobType: res.data.GetPayrollConfigData[0].JobTypeID });
    this.setState({
      variablePay: res.data.GetPayrollConfigData[0].Variablepay,
    });
    this.setState({ joiningDate: res.data.GetPayrollConfigData[0].DOJ });
    this.setState({ costCenter: res.data.GetPayrollConfigData[0].CostCenter });
    this.setState({ pfDate: res.data.GetPayrollConfigData[0].PFDate });
    this.setState({ ctc: res.data.GetPayrollConfigData[0].CTC });
    this.setState({ basic: res.data.GetPayrollConfigData[0].BASIC });

    // Query to fetch payroll default
    const GET_PDEFAULT = gql`
      {
        GetOrganisationDefaultPayroll {
          AdminBank
          FBP
          Paymode
          IsMetro
          IsEPSEntitle
          HasESI
        }
      }
    `;
    var respdflt = await this.context.client.query({ query: GET_PDEFAULT,fetchPolicy: 'network-only'});
    
    console.log("GetOrganisationDefaultPayroll",respdflt);
    Object.keys(respdflt.data.GetOrganisationDefaultPayroll[0]).forEach(
      (k) =>
        (respdflt.data.GetOrganisationDefaultPayroll[0][k] =
          respdflt.data.GetOrganisationDefaultPayroll[0][k] === "N/A"
            ? ""
            : respdflt.data.GetOrganisationDefaultPayroll[0][k])
    );
    this.setState({ fbp: respdflt.data.GetOrganisationDefaultPayroll[0].FBP });
    this.setState({
      adminBank: respdflt.data.GetOrganisationDefaultPayroll[0].AdminBank,
    });
    this.setState({
      payMode: respdflt.data.GetOrganisationDefaultPayroll[0].Paymode,
    });
    this.setState({
      isMetro: respdflt.data.GetOrganisationDefaultPayroll[0].IsMetro,
    });
    this.setState({
      isEpsEntitled:
        respdflt.data.GetOrganisationDefaultPayroll[0].IsEPSEntitle,
    });
    this.setState({
      hasESI: respdflt.data.GetOrganisationDefaultPayroll[0].HasESI,
    });
  }

  // Function to handle payroll config data

  handlePConfigData(data) {
    console.log(data.PayrollConfig);
    if (data.PayrollConfig.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Data added Successfully",
        "Successful!",
        2000
      );
      setTimeout(function () {
        window.location.reload();
      }, 2000);
      this.props.history.goBack();
    } else if (data.PayrollConfig.Confirmed === "1") {
      NotificationManager.error(
        "Error while adding Payroll config data",
        "Error!"
      );
    }
  }
  componentWillUnmount() {
    localStorage.removeItem("idofcmail");
  }
  goBack() {
    this.props.history.goBack();
  }

  handleTab = (e) => {
    this.setState({ activeTab: e.target.name });
    e.preventDefault();
  };

  render() {
    return (
      <div class="">
        <div style={{ textAlign: "right" }}>
          <button className="btn btn-primary" onClick={this.goBack}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
        </div>
        <ul class="nav nav-tabs tabClass proll-nav" role="tablist">
          <li
            // class="nav-item"
            className={
              this.state.activeTab === "UserPayroll"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={this.handleTab}
          >
            <a
              className={
                this.state.activeTab === "UserPayroll"
                  ? "nav-link active"
                  : "nav-link"
              }
              // class="nav-link "
              data-toggle="tab"
              href="#UserPayroll"
              name="UserPayroll"
            >
              Payroll Information
            </a>
          </li>

          <li
            // class="nav-item"
            className={
              this.state.activeTab === "defaultData"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={this.handleTab}
          >
            <a
              // class="nav-link"
              className={
                this.state.activeTab === "defaultData"
                  ? "nav-link active"
                  : "nav-link"
              }
              data-toggle="tab"
              href="#defaultData"
              name="defaultData"
            >
              Access Default Data
            </a>
          </li>
          <li
            // class="nav-item"
            className={
              this.state.activeTab === "ofcInfo"
                ? "nav-item active"
                : "nav-item"
            }
            onClick={this.handleTab}
          >
            <a
              // class="nav-link"
              className={
                this.state.activeTab === "ofcInfo"
                  ? "nav-link active"
                  : "nav-link"
              }
              data-toggle="tab"
              href="#ofcInfo"
              name="ofcInfo"
            >
              Office Info
            </a>
          </li>
        </ul>

        {/* Tab panes */}
        {/* UserPayroll-tab */}
        <div class="tab-content proll">
          <div
            // class="active card tab-pane "
            className={
              this.state.activeTab === "UserPayroll"
                ? "tab-pane card  active"
                : "tab-pane"
            }
          >
            <div class="card-header proll-header">Payroll Information</div>
            <div class="card-body">
              <div className="card marg-bottom">
                <div class="card-header config-header">Configurable</div>
                <div class="card-body">
                  <div class="row identity-info">
                    <span class="prollspan-text">Joining Date</span>
                    <div class="col info-col">
                      <input
                        type="date"
                        name="joiningDate"
                        value={this.state.joiningDate}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                      />
                    </div>

                    <span class="prollsecondcoltxt-wid">Designation</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="designation"
                        value={this.state.designation}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Designation"
                      />
                    </div>
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">PF No</span>
                    <div class="col info-col">
                      <input
                        type="tel"
                        name="pfNo"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        value={this.state.pfNo}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="PF No"
                      />
                      {this.state.errorMsg.hasOwnProperty("pfNo") && (
                        <span
                          style={{
                            color: "#ff0000",
                            fontSize: ".8em",
                          }}
                        >
                          Only Numbers are Allowed
                        </span>
                      )}
                    </div>

                    <span class="prollsecondcoltxt-wid"> PF Date</span>
                    <div class="col info-col">
                      <input
                        type="date"
                        name="pfDate"
                        value={this.state.pfDate}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="PF Date"
                      />
                    </div>
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">Job Type</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="jobType"
                        value={this.state.jobType}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Job Type"
                      />
                    </div>

                    <span class="prollsecondcoltxt-wid"> CTC</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="ctc"
                        value={this.state.ctc}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="CTC"
                      />
                      {this.state.errorMsg.hasOwnProperty("ctc") && (
                        <span
                          style={{
                            color: "#ff0000",
                            fontSize: ".8em",
                          }}
                        >
                          Only Numbers are Allowed
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">Cost Center </span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="costCenter"
                        value={this.state.costCenter}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Cost Center"
                      />
                    </div>
                    <span class="prollsecondcoltxt-wid">Basic</span>
                    <div class="col info-col"><input type="text" name="basic" value={this.state.basic}  onChange={this.handleChange}  class="form-control"  placeholder="Basic" />
                      {/* {this.state.errorMsg.hasOwnProperty("variablePay") &&  <span style={{color: "#ff0000",fontSize: ".8em"}}> Only Numbers are Allowed</span>} */}
                    </div>      
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">Variable Pay</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="variablePay"
                        value={this.state.variablePay}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Variable Pay"
                      />
                      {this.state.errorMsg.hasOwnProperty("variablePay") && (
                        <span
                          style={{
                            color: "#ff0000",
                            fontSize: ".8em",
                          }}
                        >
                          Only Numbers are Allowed
                        </span>
                      )}
                    </div>
                    <span class="prollsecondcoltxt-wid">ESI No</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="esiNo"
                        value={this.state.esiNo}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="ESI No"
                      />
                      {this.state.errorMsg.hasOwnProperty("esiNo") && (
                        <span
                          style={{
                            color: "#ff0000",
                            fontSize: ".8em",
                          }}
                        >
                          Only Numbers are Allowed
                        </span>
                      )}
                    </div>        
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">Has ESS</span>
                    <form className="col info-col">
                      <select
                        name="hasESS"
                        value={this.state.hasESS}
                        onChange={this.handleChange}
                        class="custom-select gender-select"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>
                    <span class="prollsecondcoltxt-wid">Salary Template</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="salaryTemplate"
                        value={this.state.salaryTemplate}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="Salary Template"
                      />
                    </div>      
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text">PF UAN</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="pfUAN"
                        value={this.state.pfUAN}
                        onChange={this.handleChange}
                        class="form-control"
                        placeholder="PF UAN"
                      />
                    </div>
                    <span class="prollsecondcoltxt-wid">Has PF</span>
                    <form className="col info-col">
                      <select
                        name="hasPF"
                        value={this.state.hasPF}
                        onChange={this.handleChange}
                        class="custom-select gender-select"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>      
                  </div>
                  <div class="row identity-info">
                    <span class="prollspan-text"></span>
                    <div class="col info-col"></div>
                    <span class="prollsecondcoltxt-wid">Is PF Restricted</span>
                    <form className="col info-col">
                      <select
                        name="isPfRestricted"
                        value={this.state.isPfRestricted}
                        onChange={this.handleChange}
                        class="custom-select gender-select"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>
                  </div>        
                  <div className="button-div">
                    <div>
                      <Mutation
                        mutation={PROLL_CONFIG}
                        onCompleted={(data) => {
                          this.handlePConfigData(data);
                        }}
                      >
                        {(addProllCofig, { data, loading, error }) => (
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={(e) => {
                                e.preventDefault();
                                addProllCofig({
                                  variables: {
                                    PFNo: this.state.pfNo,
                                    SalarTemplate: this.state.salaryTemplate,
                                    PFUAN: this.state.pfUAN,
                                    IsPFRestricted: this.state.isPfRestricted,
                                    Designation: this.state.designation,
                                    HasESS: this.state.hasESS,
                                    HasPF: this.state.hasPF,
                                    EmpID: localStorage.hasOwnProperty(
                                      "idofcmail"
                                    )
                                      ? JSON.parse(
                                          localStorage.getItem("idofcmail")
                                        ).id
                                      : this.props?.empDet?.location?.idofcmail
                                          ?.id,
                                    ESINo: this.state.esiNo,
                                    JobType: this.state.jobType,
                                    Varaiablepay: this.state.variablePay,
                                    DOJ: this.state.joiningDate,
                                    CostCenter: this.state.costCenter,
                                    PFDate: this.state.pfDate,
                                    CTC: this.state.ctc,
                                    BASIC:this.state.basic,
                                  },
                                });
                              }}
                            >
                              Save
                            </button>
                            {/* {data && this.handlePConfigData(data)} */}
                          </div>
                        )}
                      </Mutation>
                    </div>
                  </div>
                </div>
              </div>
              {/* Access Default Tab */}
              <div className="card">
                <div class="card-header config-header">Default</div>
                <div className="card-body">
                  <div class="row identity-info">
                    <span class="pDfltspan-text">FBP</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="fbp"
                        value={this.state.fbp}
                        onChange={this.handleChange}
                        disabled
                        class="form-control"
                        placeholder="FBP"
                      />
                    </div>

                    <span class="pDfltsecondcoltxt-wid">Admin Bank</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="adminBank"
                        value={this.state.adminBank}
                        onChange={this.handleChange}
                        disabled
                        class="form-control"
                        placeholder="Admin Bank"
                      />
                    </div>
                  </div>
                  <div class="row identity-info">
                    <span class="pDfltspan-text">Pay Mode</span>
                    <div class="col info-col">
                      <input
                        type="text"
                        name="payMode"
                        value={this.state.payMode}
                        onChange={this.handleChange}
                        disabled
                        class="form-control"
                        placeholder="Pay Mode"
                      />
                    </div>

                    <span class="pDfltsecondcoltxt-wid"> Is Metro</span>
                    <form className="col info-col">
                      <select
                        name="isMetro"
                        value={this.state.isMetro}
                        onChange={this.handleChange}
                        disabled
                        class="custom-select gender-select"
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>
                  </div>
                  <div class="row identity-info">
                    <span class="pDfltspan-text">Is EPS Entitled </span>
                    <form className="col info-col">
                      <select
                        name="isEpsEntitled"
                        value={this.state.isEpsEntitled}
                        onChange={this.handleChange}
                        disabled
                        class="custom-select gender-select"
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>

                    <span class="pDfltsecondcoltxt-wid"> Has ESI</span>
                    <form className="col info-col">
                      <select
                        name="hasESI"
                        value={this.state.hasESI}
                        onChange={this.handleChange}
                        disabled
                        class="custom-select gender-select"
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Payroll Default Tab */}
          <div
            // id="defaultData"
            // className="tab-pane"
            className={
              this.state.activeTab === "defaultData"
                ? "tab-pane active"
                : "tab-pane"
            }
          >
            <Defaultdata empDet={this.props} />
          </div>

          {/*  Office Info Tab */}
          <div
            // id="ofcInfo"
            //class="tab-pane"
            className={
              this.state.activeTab === "ofcInfo"
                ? "tab-pane active"
                : "tab-pane"
            }
          >
            <OfficeInfo empDet={this.props} />
          </div>
        </div>
      </div>
    );
  }
}
