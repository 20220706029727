import React, { Component } from "react";
import "./Header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }

  render() {
    return (
      <div>
        <nav class="navbar navbar-expand-lg " color-on-scroll="500">
          <div class=" container-fluid  ">
            <a class="navbar-brand">{this.props.headerName}</a>
            <button
              href=""
              class="navbar-toggler navbar-toggler-right"
              type="button"
              data-toggle="collapse"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-bar burger-lines"></span>
              <span class="navbar-toggler-bar burger-lines"></span>
              <span class="navbar-toggler-bar burger-lines"></span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navigation"
            >
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <span class="nav-link font-clr">
                    {this.props.data.EmpName ||
                      JSON.parse(localStorage.getItem("EmployeeData")).EmpName}
                  </span>
                </li>

                <li class="nav-item" onClick={() => localStorage.clear()}>
                  <a class="nav-link" href="/">
                    <span class="no-icon">Log out</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
