import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import "./ForgotPasswordEmail.css";
import queryString from "query-string";
const FORGOT_PASSWORD = gql`
  mutation UpdatePW($newPW: String!, $cnfrmNewPW: String!, $EmpID: String!) {
    UpdatePW(newPW: $newPW, cnfrmNewPW: $cnfrmNewPW, EmpID: $EmpID) {
      Message
      Confirmed
    }
  }
`;

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPSW: "",
      cnfrmNewPSW: "",
      //   empID: "",
      isPasswordShown: false,
      isCnfrmPassShown: false,
      submitDisabled: true,
      msg: "",
    };
  }

  checkPasswords = () => {
    if (this.state.cnfrmNewPSW.length > 0) {
      if (this.state.newPSW != this.state.cnfrmNewPSW) {
        this.setState({ msg: "Passwords do not match " });
        this.setState({ submitDisabled: true });
      } else {
        this.setState({ msg: "" });
        this.setState({ submitDisabled: false });
      }
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Password toggle functionality
  togglePasswordVisibility = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  togglecnfPassword = () => {
    const { isCnfrmPassShown } = this.state;
    this.setState({ isCnfrmPassShown: !isCnfrmPassShown });
  };

  handleForgotPwdMut = (data) => {
    console.log(data.UpdatePW);
    if (data.UpdatePW.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Password updated successfully",
        "Successful!",
        2000
      );
      this.props.history.push("/");
    } else if (data.UpdatePW.Confirmed === "1") {
      NotificationManager.error("Error while updating password", "Error!");
    }
  };

  render() {
    const isPasswordShown = this.state.isPasswordShown;
    const isCnfrmPassShown = this.state.isCnfrmPassShown;
    return (
      <div className="div-style">
        <div className="left-style">
          <img
            src="/Images/forgot-password-reset.png"
            alt="forgot password"
            className="img-reset-style"
          />
        </div>
        <div className="right-style">
          <div className="forgotPwdReset-style">
            Reset Password<i class="fa fa-hand-o-down" aria-hidden="true"></i>
          </div>
          <div className="card card-reset">
            <div className="card-body">
              <div class="form-group">
                <label>New Password</label>
                <input
                  type={isPasswordShown ? "text" : "password"}
                  class="form-control"
                  placeholder="New Password"
                  name="newPSW"
                  value={this.state.newPSW}
                  onChange={this.handleChange}
                  onKeyUp={this.checkPasswords}
                  required
                />
                <span className="password-icon">
                  <i
                    className={isPasswordShown ? "ion-eye" : "ion-eye-disabled"}
                    onClick={this.togglePasswordVisibility}
                  ></i>
                </span>
              </div>
              <div class="form-group">
                <label>Confirm Password</label>
                <input
                  type={isCnfrmPassShown ? "text" : "password"}
                  class="form-control"
                  placeholder="Confirm Password"
                  name="cnfrmNewPSW"
                  value={this.state.cnfrmNewPSW}
                  onChange={this.handleChange}
                  onKeyUp={this.checkPasswords}
                  required
                />
                <span className="password-icon">
                  <i
                    className={
                      isCnfrmPassShown ? "ion-eye" : "ion-eye-disabled"
                    }
                    onClick={this.togglecnfPassword}
                  ></i>
                </span>
              </div>
              <span
                style={{
                  color: "red",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                {this.state.msg}
              </span>
              <div className="btn-centre">
                <Mutation mutation={FORGOT_PASSWORD}>
                  {(updatePwd, { data, loading, error }) => (
                    <div>
                      <button
                        type="submit"
                        className={
                          this.state.submitDisabled
                            ? "btn btn-primary cursor-style"
                            : "btn btn-primary"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          updatePwd({
                            variables: {
                              newPW: this.state.newPSW,
                              cnfrmNewPW: this.state.cnfrmNewPSW,
                              EmpID: queryString.parse(
                                this.props.location.search
                              ).empid,
                            },
                          });
                        }}
                      >
                        Save
                      </button>
                      {data && this.handleForgotPwdMut(data)}
                    </div>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
