import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import "./Settings.css";
import { Context } from "../../Utils/Context";
import ReactFlagSelectCustom from "../../Utils/ReactFlagSelectCustom";
//import ReactFlagsSelect from "react-flags-select";
// import "react-flags-select/css/react-flags-select.css";

import { COUNTRY_LIST, CUSTOM_LABELS, COUNTRY_NAME } from "../../Utils/Data";
import ManageBranch from "./ManageBranch";
import DefaultCompConfig from "./DefaultCompConfig";
import ManageDesignation from "./ManageDesignation";

// mutation for  company profile
const CMPNY_PROF = gql`
  mutation(
    $AddressLine2: String!
    $City: String!
    $ZipCode: String!
    $Country: String!
    $CountryCode: String!
    $Organisation: String!
    $Admin: String!
    $AddressLine1: String
  ) {
    UpdateCompanyProfile(
      AddressLine2: $AddressLine2
      City: $City
      ZipCode: $ZipCode
      Country: $Country
      CountryCode: $CountryCode
      Organisation: $Organisation
      Admin: $Admin
      AddressLine1: $AddressLine1
    ) {
      Message
      Confirmed
    }
  }
`;

export default class Settings extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      addrLine1: "",
      addrLine2: "",
      city: "",
      zipCode: "",
      country: "",
      countryCode: "",
      adminMail: "",
      organisation: "",
    };
  }

  handleChange = (e, type = "") => {
    if (
      typeof e === "string" &&
      type === "countryCode" &&
      typeof e.target === "undefined"
    )
      this.setState({ countryCode: e });
    else if (
      typeof e === "string" &&
      type === "countryName" &&
      typeof e.target === "undefined"
    )
      this.setState({ country: e });
    else {
      this.setState({ [e.target.name]: e.target.value });
    }
    // setting country code on selecting coutry
    this.refs.userFlag.updateSelected(e);
    this.refs.countryNameflag.updateSelected(e);
  };
  async componentDidMount() {
    // Query to fetch company profile data
    const GET_COMPPROF = gql`
      {
        GetCompanyProfile {
          Organisation
          AddressLine1
          AddressLine2
          City
          ZipCode
          Country
          CountryCode
          Admin
        }
      }
    `;
    var res = await this.context.client.query({ query: GET_COMPPROF });
    console.log(res);
    this.setState({ addrLine1: res.data.GetCompanyProfile[0]?.AddressLine1 });
    this.setState({ addrLine2: res.data.GetCompanyProfile[0]?.AddressLine2 });
    this.setState({ city: res.data.GetCompanyProfile[0]?.City });
    this.setState({ zipCode: res.data.GetCompanyProfile[0]?.ZipCode });
    this.setState({ country: res.data.GetCompanyProfile[0]?.Country });
    this.setState({
      countryCode: res.data.GetCompanyProfile[0]?.CountryCode,
    });
    this.setState({
      organisation: res.data.GetCompanyProfile[0]?.Organisation,
    });
    this.setState({ adminMail: res.data.GetCompanyProfile[0]?.Admin });

    this.refs.userFlag.updateSelected(this.state.countryCode);
    this.refs.countryNameflag.updateSelected(this.state.country);
  }
  // Function to handle company profile data

  handleCmpnyPrfData(data) {
    console.log(data.UpdateCompanyProfile);
    if (data.UpdateCompanyProfile.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Data added Successfully",
        "Successful!",
        2000
      );
    } else if (data.UpdateCompanyProfile.Confirmed === "1") {
      NotificationManager.error("Error while adding data", "Error!");
    }
  }

  render() {
    return (
      <div>
        <div className="card cmp-card-wid">
          <div className="card-header cmp-headr-styl">Company Profile</div>
          <div className="card-body">
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Organization</label>
                <input
                  type="text"
                  class="form-control"
                  name="organisation"
                  value={this.state.organisation}
                  onChange={this.handleChange}
                  placeholder="Organization"
                />
              </div>
              <div class="form-group col">
                <label className="labl-font">Admin</label>
                <input
                  type="email"
                  class="form-control"
                  name="adminMail"
                  value={this.state.adminMail}
                  onChange={this.handleChange}
                  placeholder="admin@kibbcom.in"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Address</label>
                <input
                  type="text"
                  class="form-control"
                  name="addrLine1"
                  value={this.state.addrLine1}
                  onChange={this.handleChange}
                  placeholder="Address Line no 1"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <input
                  type="text"
                  class="form-control "
                  name="addrLine2"
                  value={this.state.addrLine2}
                  onChange={this.handleChange}
                  placeholder="Address Line no 2"
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col">
                <label className="labl-font">City</label>
                <input
                  type="text"
                  class="form-control"
                  name="city"
                  value={this.state.city}
                  onChange={this.handleChange}
                  placeholder="City"
                />
              </div>
              <div class="form-group col">
                <label className="labl-font">Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  value={this.state.zipCode}
                  onChange={this.handleChange}
                  class="form-control"
                  placeholder="Zip Code"
                />
              </div>
            </div>

            <div className="row">
              <div class="form-group col">
                <label className="labl-font">Country</label>
                <div>
                  <ReactFlagSelectCustom
                    countries={COUNTRY_LIST}
                    customLabels={COUNTRY_NAME}
                    ref="countryNameflag"
                    searchable={true}
                    onSelect={(e) => {
                      this.handleChange(e, "countryName");
                    }}
                  />
                </div>
              </div>
              <div class="form-group col">
                <label className="labl-font">Country Code</label>
                <div>
                  <ReactFlagSelectCustom
                    countries={COUNTRY_LIST}
                    customLabels={CUSTOM_LABELS}
                    ref="userFlag"
                    searchable={true}
                    onSelect={(e) => {
                      this.handleChange(e, "countryCode");
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <Mutation mutation={CMPNY_PROF}>
                  {(addCmpProf, { data, loading, error }) => (
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary marg-lft-15"
                        onClick={(e) => {
                          e.preventDefault();
                          addCmpProf({
                            variables: {
                              AddressLine2: this.state.addrLine2,
                              City: this.state.city,
                              ZipCode: this.state.zipCode,
                              Country: this.state.country,
                              CountryCode: this.state.countryCode,
                              Organisation: this.state.organisation,
                              Admin: this.state.adminMail,
                              AddressLine1: this.state.addrLine1,
                            },
                          });
                        }}
                      >
                        Save
                      </button>
                      {data && this.handleCmpnyPrfData(data)}
                    </div>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
        <ManageBranch />
         <ManageDesignation/>
        <DefaultCompConfig />
      </div>
    );
  }
}
