import React, { Component, useState } from "react";
import "./Adduser.css";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";
import EmployeeIDService from "../../Services/GetEmpId";
import SpeechRecognition from "react-speech-recognition";
import { Context } from "../../Utils/Context";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ADD_USER_QUERY = gql`
  mutation Employ(
    $EmpID: String!
    $Fname: String!
    $Lname: String!
    $Email: String!
    $Password: String!
    $MobileNo: String!
    $UserType: String!
    $VoiceGuidance: String!
    $Designation: String!
    $OnboardingStatus: String!
    $FirstLogin: String!
    $BranchCode: String!
  ) {
    Employ(
      EmpID: $EmpID
      Fname: $Fname
      Lname: $Lname
      Email: $Email
      MobileNo: $MobileNo
      Designation: $Designation
      OnboardingStatus: $OnboardingStatus
      UserType: $UserType
      VoiceGuidance: $VoiceGuidance
      Password: $Password
      FirstLogin: $FirstLogin
      BranchCode: $BranchCode
    ) {
      Message
      Confirmed
    }
  }
`;

class AddUser extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      empid: "",
      fname: "",
      lname: "",
      email: "",
      password: "",
      mobile: "",
      designation:"",
      submitButtonClicked: false,
      showA: false,
      showB: false,
      checked: false,
      isPasswordShown: false,
      empIdFlag: false,
      currentElem: {},
      branchDetails: [],
      degDetails:[],
      branchCode: "",
      disableSubmitBtn: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMutation = this.handleMutation.bind(this);
  }

  async componentDidMount() {
    // Query to fetch company branch
    const GET_BRANCH = gql`
      {
        GetManageBranch {
          BranchName
          BranchLocation
          BranchCode
          InitialID
          CountryCode
        }
      }
    `;
    const res = await this.context.client.query({
      query: GET_BRANCH,
    });

    if (res.data && res.data.GetManageBranch) {
      this.setState({ branchDetails: [...res.data.GetManageBranch] });
    }

    // Query to fetch Designations

    const Get_All_Designations = gql`
    {
      GetAllDesignations {
        Designation
      }
    }
  `;

  const resdeg = await this.context.client.query({
    query: Get_All_Designations,
  });
  console.log(resdeg);
  if (resdeg.data && resdeg.data.GetAllDesignations) {
    this.setState({ degDetails: [...resdeg.data.GetAllDesignations] });
  }


  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handledesignationChange = (e) =>{
    //this.setState({ [event.target.name]: event.target.value });
    this.setState({ designation: e.target.value }, this.validateFields);
  };

  handleSubmit = () => {
    console.log("add user submit button",this.state.designation);
  }

  handleMutation(data) {
    console.log('mutation data',data)
    if (data.Employ.Confirmed === "0") {
      const empCreated = { data };

      NotificationManager.success(
        "User Created Successfully",
        "Successful!",
        3000
      );
      this.props.history.push("/home/dashboard");
      this.props.setHeader("Dashboard");
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else if (data.Employ.Confirmed === "1") {
      NotificationManager.error("Error while Creating User", "Error!");
    }
  }

  toggleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };
  // Password toggle functionality
  togglePasswordVisibility = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  handleEmpID = (data) => {
    if (!this.state.empIdFlag) {
      this.setState({
        empid: data.GetNewEmpID.NewEmpID,
      });
      this.setState({ empIdFlag: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.transcript !== this.props.transcript) {
      let arrPrev = prevProps.transcript.split(" ");
      let arrCurr = this.props.transcript.split(" ");
      let arrDiff = arrCurr.filter((x) => !arrPrev.includes(x));

      let strDiff = arrDiff.join(" ");

      this.setState({
        [this.state?.currentElem?.target?.name]: strDiff,
      });
      let myprop = this.props;
      setTimeout(function () {
        myprop.stopListening();
      }, 5000);
    }
  }

  currentIp = (e) => {
    e.persist();
    this.setState({ currentElem: e });
    this.props.startListening();
  };
  onBranchChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ branchCode: event.target.value }, this.validateFields);
  };

  validateFields = () => {
    if (
      this.state.fname.length > 0 &&
      this.state.lname.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.mobile.length > 0 &&
      this.state.branchCode != "" &&
      this.state.designation !=""
    ) {
      this.setState({ disableSubmitBtn: false });
    } else {
      this.setState({ disableSubmitBtn: true });
    }
  };

  render() {
    let empID;

    if (this.state.branchCode != "") {
      empID = (
        <EmployeeIDService
          handleEmpID={this.handleEmpID}
          branchCode={this.state.branchCode}
        />
      );
    } else {
      empID = "N/A";
    }

    const isPasswordShown = this.state.isPasswordShown;
    return (
      <div class="card wid">
        <div class="card-header addUsr-header">Add user</div>
        <div class="card-body">
          <form onSubmit={this.handleSubmit}>
            {/* <span style={{ fontWeight: "500" }}>General Information</span>
            <div> {empID}</div> */}
            <div className="form-row">
              <div className="form-group col-md-4">
              <span style={{ fontWeight: "500" }}>General Information</span>
            <div> {empID}</div> 
              </div>
              <div className="form-group col-md-4">
              <label htmlFor="brnach">Branch</label>
                <select
                  name="branchCode"
                  value={this.state.branchCode}
                  onChange={this.onBranchChange}
                  class="custom-select gender-select"
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {this.state.branchDetails.length > 0
                    ? this.state.branchDetails.map((branch) => (
                        <option
                          value={branch.BranchCode}
                          key={branch.BranchCode}
                        >
                          {branch.BranchCode}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="brnach">Designation</label>

                <select
                  name="degCode"
                 // value={this.state.branchCode}
                  value={this.state.designation}
                  onChange={this.handledesignationChange}
                  class="custom-select gender-select"
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {this.state.degDetails.length > 0
                    ? this.state.degDetails.map((deg) => (
                        <option
                          value={deg.Designation}
                          key={deg.Designation}
                        >
                          {deg.Designation}
                        </option>
                      ))
                    : ""}
                </select>



                    {/* <select
                      name="designation"
                      onChange={this.handledesignationChange}
                      value={this.state.designation}
                      class="custom-select gender-select"
                    >
                      <option value="">Select</option>
                      <option value="Architech">Architech</option>
                      <option value="Customer Skill Area Account leader">Customer Skill Area Account leader</option>
                      <option value="Data Scientist">Data Scientist</option>
                      <option value="Deployment Manager">Deployment Manager</option>
                      <option value="Director">Director</option>
                      <option value="Intern">Intern</option>
                      <option value="Junior Engineer">Junior Engineer</option>
                      <option value="Principal Engineer">Principal Engineer</option>
                      <option value="Product Manager">Product Manager</option>
                      <option value="Project Manager">Project Manager</option>
                      
                    </select> */}
                {/* <select
                  name="branchCode"
                  value={this.state.branchCode}
                  onChange={this.onBranchChange}
                  class="custom-select gender-select"
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {this.state.branchDetails.length > 0
                    ? this.state.branchDetails.map((branch) => (
                        <option
                          value={branch.BranchCode}
                          key={branch.BranchCode}
                        >
                          {branch.BranchCode}
                        </option>
                      ))
                    : ""}
                </select> */}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="inputEmail4">First Name</label>
                <input
                  type="text"
                  name="fname"
                  className="form-control"
                  value={this.state.fname}
                  onClick={this.currentIp}
                  onChange={this.handleChange}
                  onKeyUp={this.validateFields}
                  placeholder="First name"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inputPassword4">Last Name</label>
                <input
                  type="text"
                  name="lname"
                  value={this.state.lname}
                  onClick={this.currentIp}
                  onChange={this.handleChange}
                  className="form-control"
                  onKeyUp={this.validateFields}
                  placeholder="Last name"
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inputPassword4">Email</label>
                <input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onClick={this.currentIp}
                  onChange={this.handleChange}
                  className="form-control"
                  onKeyUp={this.validateFields}
                  placeholder="Email"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="inputAddress2">Mobile</label>

                <PhoneInput
                  country={"in"}
                  enableSearch={true}
                  inputProps={{
                    name: "mobile",
                    required: true,
                  }}
                  inputClass="phoneClass"
                  value={this.state.mobile}
                  onChange={(mobile) => {
                    console.log(this.state);
                    this.setState({ mobile }, this.validateFields);
                  }}
                  onClick={this.currentIp}
                  maxLength={12}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inputPassword4">Password</label>
                <input
                  type={isPasswordShown ? "text" : "password"}
                  name="password"
                  value={this.state.password}
                  onClick={this.currentIp}
                  onChange={this.handleChange}
                  onKeyUp={this.validateFields}
                  className="form-control"
                  placeholder="Password"
                />
                <span className="password-icon">
                  <i
                    className={isPasswordShown ? "ion-eye" : "ion-eye-disabled"}
                    onClick={this.togglePasswordVisibility}
                  ></i>
                </span>
              </div>
              <div className="form-group col-md-4">
                {/* <label htmlFor="brnach">Designation</label> */}
                {/* <select
                  name="branchCode"
                  value={this.state.branchCode}
                  onChange={this.onBranchChange}
                  class="custom-select gender-select"
                >
                  <option value="" disabled>
                    Select
                  </option>

                  {this.state.branchDetails.length > 0
                    ? this.state.branchDetails.map((branch) => (
                        <option
                          value={branch.BranchCode}
                          key={branch.BranchCode}
                        >
                          {branch.BranchCode}
                        </option>
                      ))
                    : ""}
                </select> */}
              </div>
            </div>
            <div className="form-row">
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    fname: "",
                    lname: "",
                    email: "",
                    password: "",
                    mobile: "",
                    designation:"",
                  });
                }}
              >
                Clear
              </button>
              <Mutation
                mutation={ADD_USER_QUERY}
                onCompleted={(data) => {
                  this.handleMutation(data);
                  this.setState({
                    fname: "",
                    lname: "",
                    email: "",
                    password: "",
                    mobile: "",
                    designation:"",
                  });
                }}
              >
                {(addUser, { data, loading, error }) => (
                  <div>
                    <button
                      type="submit"
                      disabled={this.state.disableSubmitBtn}
                      className={
                        this.state.disableSubmitBtn
                          ? " btn btn-primary btn-cursor"
                          : "btn btn-primary"
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        addUser({
                          variables: {
                            EmpID: this.state.empid,
                            Fname: this.state.fname,
                            Lname: this.state.lname,
                            Email: this.state.email,
                            MobileNo: this.state.mobile,
                            Password: this.state.password,
                            // Designation: "Software Engineer",
                            Designation:this.state.designation,
                            UserType: this.state.checked ? "admin" : "employee",
                            VoiceGuidance: "YES",
                            FirstLogin: "TRUE",
                            OnboardingStatus: "INITIAL",
                            BranchCode: this.state.branchCode,
                          },
                         
                        });
                      }}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                )}
              </Mutation>
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={this.state.checked}
                    onChange={this.toggleChange}
                  />
                  Admin
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const options = {
  autoStart: false,
};

export default SpeechRecognition(options)(AddUser);
