// This component is responsible for rebdering each employees detail(INITIAL/PENDING/SUBMITTED/APPROVED) from admin login > EMployee data>View details button click in the table
import React, { Component } from "react";
import "./ViewDetails.css";

import SelfOnboarding from "./SelfOnboarding";

export default class ViewDetails extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    console.log(this.props);
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    return (
      <div>
        <SelfOnboarding
          viewEmpDet="true"
          viewhistoryProp={this.props}
          empData={this.props.location.empData}
        />
        {/* <div style={{ textAlign: "right", marginBottom: "10px" }} className="tab-row">
          <button className="btn btn-primary" onClick={this.goBack}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
          <button className="btn btn-primary">Save</button>
        </div> */}
      </div>
    );
  }
}
