import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import "./Dashboard.css";
import DashboardService from "../../Services/Dashboard";
import PendingRequest from "./PendingRequest";
import gql from "graphql-tag";
import { Context } from "../../Utils/Context";
import store from "../../js/store/index";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return { articles: state.articles };
};

// const simpleChartData = {
//   labels: ["2016", "2017", "2018", "2019", "2020"],
//   series: [[800, 200, 700, 1000, 600]],
// };
const options = {
  stackBars: true,
  axisY: {
    ticks: [0, 50, 100],
  },
};

export default class Dashboard extends Component {
  resPending = [];
  resOnboard = [];
  static contextType = Context;
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      EmpName: JSON.parse(localStorage.getItem("EmployeeData")).EmpName,
      EmpId: JSON.parse(localStorage.getItem("EmployeeData")).EmpId,
      Email: JSON.stringify(JSON.parse(localStorage.getItem("EmployeeData")).OfficeMail),
      result_pending: [],
      result_onboard: [],
      result_totalEmp: [],
      simpleChartData: {},
      result_deg: ''
    };
  }
  async componentDidMount() {
    // Query to fetch all pending employees
    const Get_AllPENDING = gql`
      {
        GetAllSUBMITTED {
          EmpID
          EmpName
        }
      }
    `;
    var resPending = await this.context.client.query({
      query: Get_AllPENDING,
    });

    this.setState({ result_pending: resPending });

    const GET_AllAPPROVED = gql`
      {
        GetAllAPPROVED {
          EmpID
          EmpName
        }
      }
    `;
    var resOnboard = await this.context.client.query({
      query: GET_AllAPPROVED,
    });

    this.setState({ result_onboard: resOnboard });






 const GET_DEG = gql`
      {
        dashEmp(email:${this.state.Email}) {
          FirstName
          LastName
          Designation
        }
      }
    `;
    var resDeg = await this.context.client.query({
      query: GET_DEG,
    });
    console.log('GET_DEG', GET_DEG);
    console.log('resDeg',resDeg);
 
    this.setState({ result_deg: resDeg?.data?.dashEmp?.Designation });

    const GET_AllEMP = gql`
      {
        getAllEmpDet {
          EmpID
          EmpName
          DOJ
        }
      }
    `;
    var resTotalEmp = await this.context.client.query({
      query: GET_AllEMP,
    });
    console.log(resTotalEmp);
    let year = [];
    let noEmp = [];

    for (let i = 0; i < resTotalEmp.data.getAllEmpDet.length; i++) {
      console.log(resTotalEmp.data.getAllEmpDet[i]);
      if (resTotalEmp.data.getAllEmpDet[i].DOJ !== "N/A") {
        let yr = resTotalEmp.data.getAllEmpDet[i].DOJ.substr(0, 4);
        if (year.length === 0) {
          year.push(yr);
          noEmp.push(1);
        } else if (year.includes(yr)) {
          let index = year.indexOf(yr);
          noEmp[index] = ++noEmp[index];
        } else {
          year.push(yr);
          noEmp.push(1);
        }
      }
    }
    console.log(noEmp);
    this.setState({ result_totalEmp: resTotalEmp });
    this.setState({
      simpleChartData: {
        labels: year,
        series: [noEmp],
      },
    });
    console.log(this.state.simpleChartData);
  }

  navigateToOnboardedEmp = () => {
    this.props.history.push("/home/onboardedEmp");
  };

  navigateToPendingEmp = () => {
    this.props.history.push("/home/pendingEmp");
  };

  navigateToAllEmp = () => {
    this.props.history.push({
      pathname: "/home/employeeData",
      state: { backBtnEnable: "true" },
    });
  };

  render() {
    if (
      this.props.data.UserType === "admin" ||
      JSON.parse(localStorage.getItem("EmployeeData")).UserType === "admin"
    )
      return (
        <div>
          <div className="row">
            <div className="pendingCard col-md-4" style={{ width: "18rem", cursor: "pointer" }} onClick={this.navigateToOnboardedEmp}>
              <div className="card1">
                <h5 class="card-header">Onboarded employees</h5>
                <div className="cardBody">
                  <div style={{ cursor: "pointer" }}>
                    <i className="ion-android-checkbox-outline" aria-hidden="true"></i>
                    <h1 className="cardNum">
                      {this.state.result_onboard?.data?.GetAllAPPROVED.length}
                    </h1>
                  </div>
                </div>
              </div>  
            </div>
            <div className="pendingCard col-md-4" style={{ width: "18rem", cursor: "pointer" }} onClick={this.navigateToPendingEmp}>
              <div  className="card2">
                <h5 class="card-header">Pending employees</h5>
                <div className="cardBody">
                  <i className="ion-person-add" aria-hidden="true"></i>
                  <h1 className="cardNum">
                    {this.state.result_pending?.data?.GetAllSUBMITTED.length}
                  </h1>
                </div>
              </div>  
            </div>
            <div className="pendingCard col-md-4" style={{ width: "18rem", cursor: "pointer" }} onClick={this.navigateToAllEmp}>
              <div  className="card3">
                <h5 class="card-header">Total Employees</h5>
                <div className="cardBody">
                  <i className="ion-person-stalker" aria-hidden="true"></i>
                  <h1 className="cardNum">
                    {this.state.result_totalEmp.data?.getAllEmpDet.length}
                  </h1>
                </div>
              </div>  
            </div>
          </div>

          <div className="row">
            <div class="graphCard ">
              <div class="graphHeader ">
                <h4 class="card-title">Employees Onboarding </h4>
                <p class="card-category">Year-wise </p>
              </div>
              <div className="card-body">
                <ChartistGraph
                  data={this.state.simpleChartData}
                  options={options}
                  type={"Bar"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    else if (
      this.props.data.UserType === "employee" ||
      JSON.parse(localStorage.getItem("EmployeeData")).UserType === "employee"
    ) {
      return (
        <div className="table-responsive table-full-width">
          <table className="table">
            <tr>
              <td>
                <i className="nc-icon nc-circle-09 userProflie"></i>
              </td>
              <td>Name : {this.state.EmpName}</td>
              <td>Emp Id: {this.state.EmpId}</td>
              {/* <td>Designation: Software Engineer</td> */}
              <td>Designation: {this.state.result_deg}</td>
            </tr>
          </table>
        </div>
      );
    }
  }
}
