import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "./Components/Login";
import Home from "./Components/Dashboard";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import MyProvider from "./Utils/Context";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import ForgotPasswordEmail from "./Components/ForgotPassword/ForgotPasswordEmail";
import SuperAdmin from "./Components/SuperAdmin/SuperAdmin";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedInStatus: "NOT_LOGGED_IN",
      user: {},
      superadmin: {},
    };
  }

  handleLogin = (data) => {
    console.log("normal", data);
    this.setState({
      loggedInStatus: "LOGGED_IN",
      user: data,
    });
  };
  handleSuperradminLogin = (data) => {
    console.log("superadmin", data);
    this.setState({
      loggedInStatus: "LOGGED_IN",
      superadmin: data,
    });
  };

  render() {
    return (
      <MyProvider client={this.props.client}>
        <div className="app">
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path={"/"}
                render={(props) => {
                  return localStorage.getItem("EmployeeData") == null ? (
                    <Login
                      {...props}
                      handleLogin={this.handleLogin}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  ) : (
                    <Redirect to="/home/dashboard" />
                  );
                }}
              />
              <Route
                exact
                path={"/forgotPassword"}
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path={"/forgotPasswordemail"}
                render={(props) => <ForgotPasswordEmail {...props} />}
              />
              <Route
                exact
                path={"/resetPassword"}
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path={"/superadmin"}
                render={(props) => {
                  return localStorage.getItem("EmployeeData") == null ? (
                    <SuperAdmin
                      {...props}
                      handleLogin={this.handleSuperradminLogin}
                      loggedInStatus={this.state.loggedInStatus}
                    />
                  ) : (
                    <Redirect to="/home/superadmin/superadminconfig" />
                  );
                }}
              />
              <Route
                path={"/home"}
                render={(props) => (
                  <Home
                    {...props}
                    userData={this.state.user}
                    superadmin={this.state.superadmin}
                    loggedInStatus={this.state.loggedInStatus}
                  />
                )}
              />
            </Switch>
          </BrowserRouter>
          <NotificationContainer />
        </div>
      </MyProvider>
    );
  }
}
