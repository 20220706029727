import React, { Component } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export default function Dashboard() {
  const EMP_COUNT = gql`
    {
      dashAdmin {
        Count
      }
    }
  `;

  const { data, loading, error } = useQuery(EMP_COUNT);
  if (loading) return <p>Loading...</p>;
  if (error) {
    console.log("Something went wrong");
    return <span>-</span>;
  }
  if (data) {
    console.log(data.dashAdmin.Count);
    if (data.dashAdmin.Count > 0) {
      return <span>{data.dashAdmin.Count}</span>;
    } else {
      return <span>0</span>;
    }
  }
}
