import React, { Component } from "react";
import gql from "graphql-tag";
import { Context } from "../../Utils/Context";
import "./PendingRequest.css";
import ModalpopUp from "../../Utils/ModalpopUp";
import { NotificationManager } from "react-notifications";
import { Mutation } from "react-apollo";


// Mutation for Deleting user
const DEL_EMP = gql`
     mutation($EmpID: String!) {
       DeleteEmployee(EmpID: $EmpID) {
         Message
         Confirmed
       }
     }
   `;

export default class PendingRequest extends Component {
  res = [];
  static contextType = Context;
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      result: [],
    };
    this.goBack = this.goBack.bind(this);
    this.handleMutation = this.handleMutation.bind(this);
  }
  async componentDidMount() {
    // Query to fetch all pending employees
    const Get_AllPENDING = gql`
      {
        GetAllSUBMITTED {
          EmpID
          AdhaarNo
          BankAcNo
          BloodGroup
          DOB
          DOJ
          Emergency_No
          EmployeeBank
          EmpID
          Fathersname
          EmpName
          Gender
          IFSCcode
          LocAdd1
          LocAdd2
          LocCity
          LocCountry
          LocPIN
          LocState
          MotherName
          OfficeMail
          OnboardingStatus
          PAN
          PassportNo
          PerAdd1
          PerAdd2
          PerCity
          PerCountry
          PerState
          PerPIN
          PersonalEmail
          PersonalMobile
          SpouseName
          SpouseAge
          Child
          Child2
          ChildAge
          ChildAge2
          MotherName
          VisaNO
        }
      }
    `;

    var res = await this.context.client.query({
      query: Get_AllPENDING,
    });

    this.setState({ result: res });
  }
  goBack() {
    this.props.history.goBack();
  }

  showViewDetails = (empData) => {
    console.log(empData);
    this.props.history.push({
      pathname: "/home/viewdetails",
      empData,
    });
  };

  showaddDetails = (idofcmail) => {
    console.log(idofcmail);
    this.props.history.push({
      pathname: "/home/userPayrollConfig",
      idofcmail,
    });
  };

  deleteEmp = (emp) => {
    //  var response = await DEL_EMP({
    //       variables: {
    //      EmpID: emp.EmpID,
    //    },
    //     });
    NotificationManager.success(
      `Employee with ${emp.EmpID} deleted`,
      "Successful!",
      3000
    );
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  handleMutation(data) {
    console.log('mutation data', data)
    if (data.DeleteEmployee.Confirmed === "0") {
      const empCreated = { data };

      NotificationManager.success(
        "User Created Successfully",
        "Successful!",
        3000
      );
      this.props.history.push("/home/dashboard");
      // this.props.setHeader("Dashboard");
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else if (data.Employ.Confirmed === "1") {
      NotificationManager.error("Error while Creating User", "Error!");
    }
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "right" }}>
          <button className="btn btn-primary " onClick={this.goBack}>
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back
          </button>
        </div>
        <div class="card pendingEmp-card ">
          <div class="card-header pendinghdr-clr">Pending Employees </div>
          <div class="card-body">
            <table class="table table-striped emp-mng-table">
              <thead>
                <tr>
                  <th scope="col">Employee ID</th>
                  <th scope="col">Employee name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Status</th>
                  <th className="action-style" colSpan="4" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              {this.state.result.data?.GetAllSUBMITTED.length > 0 ? (
                <tbody>
                  {this.state.result.data?.GetAllSUBMITTED.map((emp) => (
                    <tr key={emp.EmpID}>
                      <td>{emp.EmpID}</td>
                      <td>{emp.EmpName}</td>
                      <td>{emp.OfficeMail}</td>
                      <td>{emp.OnboardingStatus}</td>
                      <td className="cursor">
                        <i
                          title="View Details"
                          class="fa fa-eye"
                          aria-hidden="true"
                          onClick={() => this.showViewDetails(emp)}
                        ></i>
                      </td>
                      <td className="cursor">
                        <i
                          title="Add Details"
                          class="fa fa-plus-circle"
                          onClick={() =>
                            this.showaddDetails({
                              id: emp.EmpID,
                              ofcmail: emp.OfficeMail,
                            })
                          }
                          aria-hidden="true"
                        ></i>
                      </td>
                      <td>
                        <ModalpopUp empid={emp.EmpID} />
                      </td>
                      <td>
                        <Mutation
                          mutation={DEL_EMP}
                          onCompleted={(data) => {
                            this.handleMutation(data);
                          }}
                        >
                          {(deleteUser, { data, loading, error }) => (
                            <div>
                              <i
                                title="Delete User"
                                class="fa fa-trash-o"
                                aria-hidden="true"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deleteUser({
                                    variables: {
                                      EmpID: emp.EmpID,
                                    },
                                  });
                                }}
                              ></i>
                            </div>
                          )}
                        </Mutation>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="3" scope="col">
                      No Data found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  }
}



