import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

export default function Service(props) {
  console.log(props);

  const LOGIN_QUERY = gql`{
        login(email:"${props.email}", password:"${props.password}"){
        Message
        Confirmed
        UserType
        EmpID
        EmpName
        OfficeMail
        PersonalMobile 
        VoiceGuidance
        UserType
        FirstLogin
        OnboardingStatus
        }
    }`;

  const LOGIN_QUERY_SUPERADMIN = gql`{
        SuperAdminLogin(email:"${props.email}", password:"${props.password}"){
        Message
        Confirmed
        UserType
        EmpID
        EmpName
        OfficeMail
        }
    }`;
  const { data, loading, error } = useQuery(
    props.usertype !== "undefined" && props.usertype === "superadmin"
      ? LOGIN_QUERY_SUPERADMIN
      : LOGIN_QUERY
  );
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error </p>;
  if (data) {
    // For Superadmin login
    if (props.usertype !== "undefined" && props.usertype === "superadmin") {
      if (data.SuperAdminLogin.Confirmed === "0") {
        console.log(data.SuperAdminLogin.UserType);

        if (
          data.SuperAdminLogin.UserType === "admin" ||
          data.SuperAdminLogin.UserType === "employee"
        )
          return (
            <div>
              <p style={{ color: "#9a0101" }}>
                <strong>Unauthorized Login</strong>
              </p>
            </div>
          );
        props.handleData(data.SuperAdminLogin);
        return null;
      } else if (data.SuperAdminLogin.Confirmed === "1") {
        return (
          <div>
            <p style={{ color: "#9a0101" }}>
              <strong>Check your Email or Password</strong>
            </p>
          </div>
        );
      }
    } else {
      //for admin & emp //
      if (data.login.Confirmed === "0") {
        console.log(data.login.UserType);
        if (data.login.UserType === "SUPERADMIN")
          return (
            <div>
              <p style={{ color: "#9a0101" }}>
                <strong>Unauthorized Login</strong>
              </p>
            </div>
          );
        props.handleData(data.login);
        return null;
      } else if (data.login.Confirmed === "1") {
        return (
          <div>
            <p style={{ color: "#9a0101" }}>
              <strong>Check your Email or Password</strong>
            </p>
          </div>
        );
      }
    }
  }
}
