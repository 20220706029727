import React, { Component } from "react";
import gql from "graphql-tag";
import { Context } from "../../Utils/Context";

export default class Defaultdata extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      empID: "",
      scheduleGroupID: "",
      weekOffGroupID: "",
      leaveGroup: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async componentDidMount() {
    // Query to fetch payroll config data
    const GET_DEFAULT_DATA = gql`
      {
        GetOrganisationDefaultAccess {
          WeekOffGroupID
          ScheduleGroupID
          LeaveGroup
        }
      }
    `;

    var res = await this.context.client.query({
      query: GET_DEFAULT_DATA,
    });
    console.log(res);
    Object.keys(res.data.GetOrganisationDefaultAccess[0]).forEach(
      (k) =>
        (res.data.GetOrganisationDefaultAccess[0][k] =
          res.data.GetOrganisationDefaultAccess[0][k] === "N/A"
            ? ""
            : res.data.GetOrganisationDefaultAccess[0][k])
    );

    this.setState({
      scheduleGroupID: res.data.GetOrganisationDefaultAccess[0].ScheduleGroupID,
    });
    this.setState({
      weekOffGroupID: res.data.GetOrganisationDefaultAccess[0].WeekOffGroupID,
    });
    this.setState({
      leaveGroup: res.data.GetOrganisationDefaultAccess[0].LeaveGroup,
    });
  }

  render() {
    return (
      <div class="card">
        <div class="card-header default-header"> Access Default Data</div>
        <div class="card-body">
          {" "}
          <div class="row identity-info">
            <span class="defaultData-span">Schedule Group Id</span>
            <div class="col info-col">
              <input
                type="text"
                name="scheduleGroupID"
                value={this.state.scheduleGroupID}
                onChange={this.handleChange}
                disabled
                class="form-control"
                placeholder="Schedule Group Id"
              />
            </div>

            <span class="defaultDatascndcol-wid">Weekoff Group</span>
            <div class="col info-col">
              <input
                type="text"
                name="weekOffGroupID"
                value={this.state.weekOffGroupID}
                onChange={this.handleChange}
                disabled
                class="form-control"
                placeholder="Weekoff Group"
              />
            </div>
          </div>
          <div class="row identity-info">
            <span class="defaultData-span">Leave Group</span>
            <div class="col info-col">
              <input
                type="text"
                name="leaveGroup"
                value={this.state.leaveGroup}
                onChange={this.handleChange}
                disabled
                class="form-control"
                placeholder="Leave Group"
              />
            </div>
            <span class="defaultDatascndcol-wid"></span>
            <div class="col info-col"></div>
          </div>
        </div>
      </div>
    );
  }
}
