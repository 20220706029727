import React, { Component, useState, useEffect, useRef } from "react";
import { NotificationManager } from "react-notifications";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

import { COUNTRY_LIST, CUSTOM_LABELS, COUNTRY_NAME } from "../../Utils/Data";
import ReactFlagSelectCustom from "../../Utils/ReactFlagSelectCustom";
//import "react-flags-select/css/react-flags-select.css";

export default function ManageBranch() {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [code, setCode] = useState("");
  const [initialId, setInitialId] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [disableSave, setdisableSave] = useState(true);

  const countryCodeRefs = useRef([]); // multiple which is used inside the map
  const countryCodeRef = useRef([]); // ref for single country code slect flag

  const Get_Manage_Branch1 = gql`
    {
      GetManageBranch {
        BranchName
        BranchLocation
        BranchCode
        InitialID
        CountryCode
      }
    }
  `;

  // Mutation to add a branch
  const INSERT_Manage_Branch = gql`
    mutation InsertManageBranch(
      $BranchName: String!
      $BranchLocation: String!
      $BranchCode: String
      $InitialID: String!
      $CountryCode: String!
    ) {
      InsertManageBranch(
        BranchName: $BranchName
        BranchLocation: $BranchLocation
        BranchCode: $BranchCode
        InitialID: $InitialID
        CountryCode: $CountryCode
      ) {
        Message
        Confirmed
      }
    }
  `;

  // Mutation to update a branch
  const UPDATE_Manage_Branch = gql`
    mutation UpdateManageBranch(
      $BranchName: String!
      $BranchLocation: String!
      $BranchCode: String
      $InitialID: String!
      $CountryCode: String!
    ) {
      UpdateManageBranch(
        BranchName: $BranchName
        BranchLocation: $BranchLocation
        BranchCode: $BranchCode
        InitialID: $InitialID
        CountryCode: $CountryCode
      ) {
        Message
        Confirmed
      }
    }
  `;

  // Mutation to delete branch
  const DELETE_Manage_Branch = gql`
    mutation DeleteBranch(
      $BranchName: String!
      $BranchLocation: String!
      $BranchCode: String
      $CountryCode: String!
    ) {
      DeleteBranch(
        BranchName: $BranchName
        BranchLocation: $BranchLocation
        BranchCode: $BranchCode
        CountryCode: $CountryCode
      ) {
        Message
        Confirmed
      }
    }
  `;

  const { data = { GetManageBranch: [] }, refetch: refetchBranch } = useQuery(
    Get_Manage_Branch1
  );

  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (Object.keys(data.GetManageBranch).length !== 0) {
      console.log(data);
      setAllData(data);
    }
    checkInput();
  });

  const [createBranch] = useMutation(INSERT_Manage_Branch);
  const [deleteBranch] = useMutation(DELETE_Manage_Branch);
  const [updateBranch] = useMutation(UPDATE_Manage_Branch);
  const buttonRef = useRef(null);

  async function createNewBranch() {
    var response = await createBranch({
      variables: {
        BranchName: name,
        BranchLocation: location,
        BranchCode: code,
        InitialID: initialId,
        CountryCode: countryCode,
      },
    });
    NotificationManager.success("Branch added", "Successful!", 2000);
    setName("");
    setLocation("");
    setCode("");
    setInitialId("");
    setCountryCode("");
    countryCodeRef.current.state.selected = "";
    refetchBranch();
  }

  async function updateNewBranch(branchDetail) {
    console.log(branchDetail);
    var response = await updateBranch({
      variables: {
        BranchName: branchDetail.BranchName,
        BranchLocation: branchDetail.BranchLocation,
        BranchCode: branchDetail.BranchCode,
        InitialID: branchDetail.InitialID,
        CountryCode: branchDetail.CountryCode,
      },
    });
    NotificationManager.success("Branch updated", "Successful!", 2000);
    refetchBranch();
  }
  async function deleteOldBranch(branchDetail) {
    console.log(countryCodeRefs);

    var response = await deleteBranch({
      variables: {
        BranchName: branchDetail.BranchName,
        BranchLocation: branchDetail.BranchLocation,
        BranchCode: branchDetail.BranchCode,
        CountryCode: branchDetail.CountryCode,
      },
    });
    NotificationManager.success("Branch deleted", "Successful!", 2000);
    refetchBranch();
  }

  function handleChange(e) {
    let arr = e.target.getAttribute("data-key").split("_");
    allData.GetManageBranch[arr[1]][arr[0]] = e.target.value;
    setAllData({ ...allData });
  }

  const onSelectFlag = (e, i) => {
    console.log(e, i);
    allData.GetManageBranch[i].CountryCode = e;
    setAllData({ ...allData });
    console.log(allData);
  };

  function checkInput() {
    if (
      name.length > 0 &&
      location.length > 0 &&
      code.length > 0 &&
      initialId.length > 0 &&
      countryCode.length > 0
    ) {
      setdisableSave(false);
    } else {
      setdisableSave(true);
    }
  }

  return (
    <div className="card cmp-card-wid ">
      <div className="card-header cmp-headr-styl">Manage Branch</div>
      <div className="card-body">
        <div class="row">
          <div className="col-md-2 form-group">
            <label className="labl-font"> Name </label>
          </div>
          <div className="col-md-2 form-group">
            <label className="labl-font"> Location </label>
          </div>
          <div className="col-md-2 form-group">
            <label className="labl-font"> Code </label>
          </div>
          <div className="col-md-2 form-group">
            <label className="labl-font"> Initial Id </label>
          </div>
          <div className="col-md-2 form-group">
            <label className="labl-font">Country Code </label>
          </div>

          <div className="col-md-2 form-group">
            <label className="labl-font">
              {typeof allData?.GetManageBranch !== "undefined" &&
              Object.keys(allData?.GetManageBranch).length > 0
                ? "Action"
                : ""}
            </label>
          </div>
        </div>
        {typeof allData?.GetManageBranch !== "undefined" &&
        Object.keys(allData?.GetManageBranch).length > 0
          ? allData.GetManageBranch.map((branchDetail, i) => {
              return (
                <div className="row" key={i} style={{ marginTop: "15px" }}>
                  <div className="col-md-2 form-group">
                    <input
                      type="text"
                      data-key={"BranchName_" + i}
                      value={branchDetail.BranchName}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <input
                      type="text"
                      data-key={"BranchLocation_" + i}
                      value={branchDetail.BranchLocation}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <input
                      type="text"
                      data-key={"BranchCode_" + i}
                      value={branchDetail.BranchCode.toUpperCase()}
                      onChange={handleChange}
                      className="form-control" disabled
                    />
                    {/* <label className="labl-font">{branchDetail.BranchCode} </label> */}
                  </div>
                  <div className="col-md-2 form-group">
                    <input
                      type="text"
                      data-key={"InitialID_" + i}
                      value={branchDetail.InitialID}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <ReactFlagSelectCustom
                      searchable={true}
                      onSelect={onSelectFlag}
                      id={i}
                      ref={(el) => {
                        countryCodeRefs.current[i] = el;

                        if (countryCodeRefs.current[i] !== null)
                          countryCodeRefs.current[i].updateSelected(
                            branchDetail.CountryCode
                          );
                        return countryCodeRefs.current[i];
                      }}
                      countries={COUNTRY_LIST}
                      customLabels={CUSTOM_LABELS}
                    />
                  </div>
                  <div className="col-md-2 form-group">
                    <i
                      title="Delete"
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      onClick={() => deleteOldBranch(branchDetail)}
                    ></i>
                    <button
                      type="button"
                      className="btn btn-danger btn-clr-font"
                      onClick={() => updateNewBranch(branchDetail)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              );
            })
          : ""}
        <hr />
        <div className="row">
          <div className="col-md-2 form-group">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              name="name"
            />
          </div>
          <div className="col-md-2 form-group">
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="form-control"
              name="location"
            />
          </div>
          <div className="col-md-2 form-group">
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="form-control"
              name="code"
            />
          </div>
          <div className="col-md-2 form-group">
            <input
              type="text"
              value={initialId}
              onChange={(e) => setInitialId(e.target.value)}
              className="form-control"
              name="initialId"
            />
          </div>
          <div className="col-md-4 form-group">
            <ReactFlagSelectCustom
              countries={COUNTRY_LIST}
              customLabels={CUSTOM_LABELS}
              searchable={true}
              ref={countryCodeRef}
              onSelect={(e) => {
                setCountryCode(e);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 form-group">
            <button
              className="btn btn-primary"
              onClick={createNewBranch}
              disabled={disableSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
