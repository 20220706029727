import React from "react";
export const Context = React.createContext(); //exporting context object

export default class MyProvider extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Context.Provider
        value={{
          client: this.props.client,
        }}
      >
        {this.props.children}
        {
          //   this indicates that the global store is accessible to all the child tags with MyProvider as Parent /
        }
      </Context.Provider>
    );
  }
}
