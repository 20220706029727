import React, { Component } from "react";
import EmployeeDataService from "../../Services/EmpProfile";
import "./EmployeeProfile.css";

export default class EmployeeProfile extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }

  render() {
    return (
      <>
        <EmployeeDataService history={this.props.history} />
      </>
    );
  }
}
