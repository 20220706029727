import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { NotificationManager } from "react-notifications";

export default function EmpProfile(props) {
  
  const [profilePicture, setprofilePicture] = useState("");
  const [profileImg, setprofileImg] = useState("");
  const [designation, setdesignation] = useState("");
  var EmpID = JSON.parse(localStorage.getItem("EmployeeData")).EmpId; 
  var EmpEmail = JSON.parse(localStorage.getItem("EmployeeData")).OfficeMail;
  var Email = JSON.stringify(JSON.parse(localStorage.getItem("EmployeeData")).OfficeMail);
  
  console.log(EmpEmail);

  const EMP_PROFILE = gql`
    {
      GetEmpDet(EmpID: "${EmpID}") {
        EmpID
        AdhaarNo
        BankAcNo
        BloodGroup
        DOB
        DOJ
        Emergency_No
        EmployeeBank
        EmpID
        Fathersname
        EmployeeName
        Gender
        IFSCcode
        LocAdd1
        LocAdd2
        LocCity
        LocCountry
        LocPIN
        LocState
        MotherName
        OfficeMail
        OnboardingStatus
        PAN
        PassportNo
        PerAdd1
        PerAdd2
        PerCity
        PerCountry
        PerState
        PerPIN
        PersonalEmail
        PersonalMobile
        SpouseName
        SpouseAge
        Child
        Child2
        ChildAge
        ChildAge2
        MotherName
        VisaNo
      }
    }
  `;

  const UPLOAD_PHOTO = gql`
    mutation($EmpID: String!, $Base64String: String!) {
      UpdateImageString(EmpID: $EmpID, Base64String: $Base64String) {
        Message
        Confirmed
      }
    }
  `;

  const GET_PHOTO = gql`
    {
      GetBase64String(EmpID:"${EmpID}") {
        Base64String
      }
    }
  `;


  const GET_DEG = gql`
      {
        dashEmp(email:${Email}) {
          FirstName
          LastName
          Designation
        }
      }
    `;

    

   


 
   

  let handleChange = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    var evt = e.target.name;
    console.log(e.target.files);
    console.log(reader);
    reader.onload = (e) => {
      console.log(e);
      console.log(evt);
      setprofilePicture({ [evt]: reader.result });
    };
    reader.onerror = function (error) { };
  };

  let handleUpload = (data) => {
    if (data.UpdateImageString.Confirmed === "0") {
      console.log("Confirmed 0");
      NotificationManager.success(
        "Profile photo uploaded Successfully",
        "Successful!",
        2000
      );
      window.location.reload();
    } else if (data.UpdateImageString.Confirmed === "1") {
      NotificationManager.error("Error while uploading photo ", "Error!");
    }
  };

  const { data, loading, error } = useQuery(EMP_PROFILE);
  const { ...data_profile } = useQuery(GET_PHOTO);
  const { ...data_deg } = useQuery(GET_DEG);
  var resDeg = data_deg?.data?.dashEmp?.Designation
    console.log(data_deg, "emp deg");
    console.log(resDeg, "emp resDeg");
 

  var image = "";
  if (
    typeof data_profile.data?.GetBase64String[0].Base64String !== "undefined" &&
    data_profile.data?.GetBase64String[0].Base64String !== "N/A"
  ) {
    let str = data_profile.data?.GetBase64String[0].Base64String;
    let res1 = str.substr(18, str.length);
    let res = res1.slice(0, -1);
    image = res;
  }
  if (loading) return <p>Loading...</p>;
  if (error) {
    return <span>-</span>;
  }
  if (data) {
    if (data && data.GetEmpDet[0] && typeof data.GetEmpDet[0] !== "undefined") {
      console.log(data.GetEmpDet[0]);
      return (
        <div>
          <p style={{ fontFamily: "sans-serif" }}>
            Onboarding status : {data.GetEmpDet[0].OnboardingStatus}
          </p>
          <div class="row">
            <div class="card profile-card col-4">
              <div class="card-body">
                <div className="employee-avtar">
                  {data_profile.data?.GetBase64String[0].Base64String === "" ||
                    data_profile.data?.GetBase64String[0].Base64String ===
                    "N/A" ? (
                      <img
                        src={"/Images/avtar.jpg"}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <img
                        src={image}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                </div>
                <div className="emp-name">
                  {JSON.parse(localStorage.getItem("EmployeeData")).EmpName ||
                    data.GetEmpDet[0].EmpName}
                </div>

                <div className="emp-id">{data.GetEmpDet[0].EmpID}</div>
                <div className="display-marg">
                  <div className="form-group wid-margBotm">
                    <input
                      type="file"
                      className="form-control pading-ht"
                      name="pofilePicture"
                      onChange={handleChange}
                      placeholder="Enter Doc Id Proof"
                      required
                    />
                  </div>
                  <Mutation mutation={UPLOAD_PHOTO}>
                    {(uploadPhoto, { data, loading, error }) => (
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary pading-marg "
                          onClick={(e) => {
                            e.preventDefault();
                            uploadPhoto({
                              variables: {
                                EmpID: EmpID,
                                Base64String: profilePicture,
                              },
                            });
                          }}
                        >
                          Save
                        </button>
                        {data && handleUpload(data)}
                      </div>
                    )}
                  </Mutation>
                  {/* <button className="btn btn-primary pading-marg">Save</button> */}
                </div>
                <hr />
                <div>
                  <ul class="list-group list-group-flush">
                    <span>
                      <i className="ion-person"></i> Designation
                    </span>
                    <li class="list-group-item">{resDeg}</li>
                    <span>
                      <i className="ion-location"></i> Location
                    </span>
                    <li class="list-group-item">
                      {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                        JSON.parse(localStorage.getItem("EmployeeData"))
                          .OnboardingStatus === "APPROVED"
                        ? data.GetEmpDet[0].LocCity
                        : "N/A"}
                    </li>
                  </ul>
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-primary"
                    style={{ width: "-webkit-fill-available" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        JSON.parse(localStorage.getItem("EmployeeData"))
                          .OnboardingStatus === "APPROVED"
                      ) {
                        props.history.push("/home/updateProfile");
                      } else {
                        NotificationManager.error(
                          "Error, First you must Self Onboard to Update Profile "
                        );
                      }
                    }}
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
            <div class="card tab-card col">
              <div class="card-body">
                <ul class="nav nav-tabs tabClass col" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link  active"
                      data-toggle="tab"
                      href="#personal-info"
                    >
                      Personal Information
                    </a>
                  </li>

                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#family-details"
                    >
                      Family
                    </a>
                  </li>

                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#other-details">
                      Other Details
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#bank-details">
                      Bank Details
                    </a>
                  </li>
                </ul>

                {/* Tab panes */}

                <div class="tab-content">
                  <div
                    id="personal-info"
                    class="active card personal-Info tab-pane "
                  >
                    <div class="card-header personal-header">
                      <strong>Personal Information</strong>
                    </div>
                    <div
                      class="card-body"
                      style={{ overflow: "auto", height: "400px" }}
                    >
                      <div class="row ">
                        <span class="text-width text-bold">Office Email</span>
                        <div class="col  text-color">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].OfficeMail
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Personal Email
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PersonalEmail
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Contact no:
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PersonalMobile
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Emergency Contact no:
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].Emergency_No
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Date of birth
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].DOB
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">Gender</span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].Gender
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Blood Group
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].BloodGroup
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row lcl-per-add">Local Address :</div>
                      <div class="row address-row">
                        <span class="text-width marg-10 text-bold">
                          Address Line 1
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocAdd1
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Address Line 2
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocAdd2
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">City</span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocCity
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">State</span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocState
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Postal code
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocPIN
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Country
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].LocCountry
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row lcl-per-add">Permanent Address :</div>

                      <div class="row address-row">
                        <span class="text-width marg-10 text-bold">
                          Address Line 1
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerAdd1
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Address Line 2
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerAdd2
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">City</span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerCity
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">State</span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerState
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Postal code
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerPIN
                            : "N/A"}
                        </div>
                      </div>

                      <div class="row ">
                        <span class="text-width marg-10 text-bold">
                          Country
                        </span>
                        <div class="col text-color marg-10 ">
                          {data.GetEmpDet[0].OnboardingStatus === "APPROVED" ||
                            JSON.parse(localStorage.getItem("EmployeeData"))
                              .OnboardingStatus === "APPROVED"
                            ? data.GetEmpDet[0].PerCountry
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Family Tab */}
                  <div id="family-details" className="tab-pane">
                    <div class="card personal-Info">
                      <div class="card-header family-header">
                        Family Details
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <span class="text-width text-bold">
                            Father's Name
                          </span>
                          <div class="col text-color">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].Fathersname
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Mother's Name
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].MotherName
                              : "N/A "}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Spouse's Name
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].SpouseName
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Spouse's Age
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].SpouseAge
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Child's Name
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].Child
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Child's Age
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].ChildAge
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Child's Name
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].Child2
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row">
                          <span class="text-width marg-10 text-bold">
                            Child's Age
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].ChildAge2
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Other Details Tab */}

                  <div id="other-details" class="tab-pane">
                    <div class="card personal-Info">
                      <div class="card-header other-header">Other Details</div>
                      <div class="card-body">
                        <div class="row ">
                          <span class="text-width text-bold">
                            Adhaar Number
                          </span>
                          <div class="col text-color ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].AdhaarNo
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row ">
                          <span class="text-width marg-10 text-bold">
                            PAN Number
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].PAN
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row ">
                          <span class="text-width marg-10 text-bold">
                            Visa Number
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].VisaNo
                              : "N/A"}
                          </div>
                        </div>

                        <div class="row ">
                          <span class="text-width marg-10 text-bold">
                            Passport Number
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].PassportNo
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Bank Details Tab */}
                  <div id="bank-details" class="tab-pane">
                    <div class="card personal-Info">
                      <div class="card-header bank-header">Bank Details</div>
                      <div class="card-body">
                        <div class="row">
                          <span class="text-width text-bold">Bank Name</span>
                          <div class="col text-color ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].EmployeeBank
                              : "N/A"}
                          </div>
                        </div>
                        <div class="row">
                          <span class="marg-10 text-width text-bold">
                            Account Number
                          </span>
                          <div class="col text-color marg-10 ">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].BankAcNo
                              : "N/A"}
                          </div>
                        </div>
                        <div class="row ">
                          <span class="marg-10 text-width text-bold">
                            IFSC Code
                          </span>
                          <div class="col text-color marg-10">
                            {data.GetEmpDet[0].OnboardingStatus ===
                              "APPROVED" ||
                              JSON.parse(localStorage.getItem("EmployeeData"))
                                .OnboardingStatus === "APPROVED"
                              ? data.GetEmpDet[0].IFSCcode
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <span>Something went wrong</span>;
    }
  }
}
