import { createStore} from "redux";
import rootReducer from "../reducers/index";


function saveToLocalStorage(state){
    try{
        const serializeData = JSON.stringify(state)
        localStorage.setItem('state', serializeData)
    }catch (err){
        console.error(err)
    }

}

const store = createStore(rootReducer);

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store;