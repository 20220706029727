export const COUNTRY_LIST = [
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CG",
  "CD",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MK",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UM",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
];

export const CUSTOM_LABELS = {
  AD: "AD",
  AE: "AE",
  AF: "AF",
  AG: "AG",
  AG: "AG",
  AI: "AI",
  AL: "AL",
  AM: "AM",
  AO: "AO",
  AQ: "AQ",
  AR: "AR",
  AS: "AS",
  AT: "AT",
  AU: "AU",
  AW: "AW",
  AX: "AX",
  AZ: "AZ",
  BA: "BA",
  BB: "BB",
  BD: "BD",
  BE: "BE",
  BF: "BF",
  BG: "BG",
  BH: "BH",
  BI: "BI",
  BJ: "BJ",
  BL: "BL",
  BM: "BM",
  BN: "BN",
  BO: "BO",
  BQ: "BQ",
  BR: "BR",
  BS: "BS",
  BT: "BT",
  BV: "BV",
  BW: "BW",
  BY: "BY",
  BZ: "BZ",
  CA: "CA",
  CC: "CC",
  CD: "CD",
  CF: "CF",
  CG: "CG",
  CH: "CH",
  CI: "CI",
  CK: "CK",
  CL: "CL",
  CM: "CM",
  CN: "CN",
  CO: "CO",
  CR: "CR",
  CU: "CU",
  CV: "CV",
  CW: "CW",
  CX: "CX",
  CY: "CY",
  CZ: "CZ",
  DE: "DE",
  DJ: "DJ",
  DK: "DK",
  DM: "DM",
  DO: "DO",
  DZ: "DZ",
  EC: "EC",
  EE: "EE",
  EG: "EG",
  EH: "EH",
  ER: "ER",
  ES: "ES",
  ET: "ET",
  FI: "FI",
  FJ: "FJ",
  FK: "FK",
  FM: "FM",
  FO: "FO",
  FR: "FR",
  GA: "GA",
  GB: "GB",
  GD: "GD",
  GE: "GE",
  GF: "GF",
  GG: "GG",
  GH: "GH",
  GI: "GI",
  GL: "GL",
  GM: "GM",
  GN: "GN",
  GP: "GP",
  GQ: "GQ",
  GR: "GR",
  GS: "GS",
  GT: "GT",
  GU: "GU",
  GW: "GW",
  GY: "GY",
  HK: "HK",
  HM: "HM",
  HN: "HN",
  HR: "HR",
  HT: "HT",
  HU: "HU",
  ID: "ID",
  IE: "IE",
  IL: "IL",
  IM: "IM",
  IN: "IN",
  IO: "IO",
  IQ: "IQ",
  IR: "IR",
  IS: "IS",
  IT: "IT",
  JE: "JE",
  JM: "JM",
  JO: "JO",
  JP: "JP",
  KE: "KE",
  KG: "KG",
  KH: "KH",
  KI: "KI",
  KM: "KM",
  KN: "KN",
  KP: "KP",
  KR: "KR",
  KW: "KW",
  KY: "KY",
  KZ: "KZ",
  LA: "LA",
  LB: "LB",
  LC: "LC",
  LI: "LI",
  LK: "LK",
  LR: "LR",
  LS: "LS",
  LT: "LT",
  LU: "LU",
  LV: "LV",
  LY: "LY",
  MA: "MA",
  MC: "MC",
  MD: "MD",
  ME: "ME",
  MF: "MF",
  MG: "MG",
  MH: "MH",
  MK: "MK",
  ML: "ML",
  MM: "MM",
  MN: "MN",
  MO: "MO",
  MP: "MP",
  MQ: "MQ",
  MR: "MR",
  MS: "MS",
  MT: "MT",
  MU: "MU",
  MV: "MV",
  MW: "MW",
  MX: "MX",
  MY: "MY",
  MZ: "MZ",
  NA: "NA",
  NC: "NC",
  NE: "NE",
  NF: "NF",
  NG: "NG",
  NI: "NI",
  NL: "NL",
  NO: "NO",
  NP: "NP",
  NR: "NR",
  NU: "NU",
  NZ: "NZ",
  OM: "OM",
  PA: "PA",
  PE: "PE",
  PF: "PF",
  PG: "PG",
  PH: "PH",
  PK: "PK",
  PL: "PL",
  PM: "PM",
  PN: "PN",
  PR: "PR",
  PS: "PS",
  PT: "PT",
  PW: "PW",
  PY: "PY",
  QA: "QA",
  RE: "RE",
  RO: "RO",
  RS: "RS",
  RU: "RU",
  RW: "RW",
  SA: "SA",
  SB: "SB",
  SC: "SC",
  SD: "SD",
  SE: "SE",
  SG: "SG",
  SH: "SH",
  SI: "SI",
  SJ: "SJ",
  SK: "SK",
  SL: "SL",
  SM: "SM",
  SN: "SN",
  SO: "SO",
  SR: "SR",
  SS: "SS",
  ST: "ST",
  SV: "SV",
  SX: "SX",
  SY: "SY",
  SZ: "SZ",
  TC: "TC",
  TD: "TD",
  TF: "TF",
  TG: "TG",
  TH: "TH",
  TJ: "TJ",
  TK: "TK",
  TL: "TL",
  TM: "TM",
  TN: "TN",
  TO: "TO",
  TR: "TR",
  TT: "TT",
  TV: "TV",
  TW: "TW",
  TZ: "TZ",
  UA: "UA",
  UG: "UG",
  UM: "UM",
  US: "US",
  UY: "UY",
  UZ: "UZ",
  VA: "VA",
  VC: "VC",
  VE: "VE",
  VG: "VG",
  VI: "VI",
  VN: "VN",
  VU: "VU",
  WF: "WF",
  WS: "WS",
  YE: "YE",
  YT: "YT",
  ZA: "ZA",
  ZM: "ZM",
  ZW: "ZW",
};

export const COUNTRY_NAME = {
  AD: "ANDORRA",
  AE: "UNITED ARAB EMIRATES",
  AF: "AFGHANISTAN",
  AG: "ANTIGUA AND BARBUDA",
  AI: "ANGUILLA",
  AL: "ALBANIA",
  AM: "ARMENIA",
  AO: "ANGOLA",
  AQ: "ANTARCTICA",
  AR: "ARGENTINA",
  AS: "AMERICAN SAMOA",
  AT: "AUSTRIA",
  AU: "AUSTRALIA",
  AW: "ARUBA",
  AX: "ÅLAND ISLANDS",
  AZ: "AZERBAIJAN",
  BA: "BOSNIA AND HERZEGOVINA",
  BB: "BARBADOS",
  BD: "BANGLADESH",
  BE: "BELGIUM",
  BF: "BURKINA FASO",
  BG: "BULGARIA",
  BH: "BAHRAIN",
  BI: "BURUNDI",
  BJ: "BENIN",
  BL: "SAINT BARTHÉLEMY",
  BM: "BERMUDA",
  BN: "BRUNEI ,DARUSSALAM",
  BO: "BOLIVIA",
  BQ: "BONAIRE",
  BR: "BRAZIL",
  BS: "BAHAMAS",
  BT: "BHUTAN",
  BV: "BOUVET ,ISLAND",
  BW: ",BOTSWANA",
  BY: "BELARUS",
  BZ: "BELIZE",
  CA: "CANADA",
  CC: "COCOS (,KEELING) ISLANDS",
  CD: "CONGO",
  CF: "CENTRAL AFRICAN REPUBLIC",
  CG: "CONGO",
  CH: "SWITZERLAND",
  CI: "CÔTE D'IVOIRE",
  CK: "COOK ISLANDS",
  CL: "CHILE",
  CM: "CAMEROON",
  CN: "CHINA",
  CO: "COLOMBIA",
  CR: "COSTA RICA",
  CU: "CUBA",
  CV: "CABO VERDE",
  CW: "CURAÇAO",
  CX: "CHRISTMAS ISLAND",
  CY: "CYPRUS",
  CZ: "CZECHIA",
  DE: "GERMANY",
  DJ: "DJIBOUTI",
  DK: "DENMARK",
  DM: "DOMINICA",
  DO: "DOMINICAN REPUBLIC",
  DZ: "ALGERIA",
  EC: "ECUADOR",
  EE: "ESTONIA",
  EG: "EGYPT",
  EH: "WESTERN SAHARA",
  ER: "ERITREA",
  ES: "SPAIN",
  ET: "ETHIOPIA",
  FI: "FINLAND",
  FJ: "FIJI",
  FK: "FALKLAND ISLANDS",
  FM: "MICRONESIA",
  FO: "FAROE ISLANDS",
  FR: "FRANCE",
  GA: "GABON",
  GB: "UNITED KINGDOM ",
  GD: "GRENADA",
  GE: "GEORGIA",
  GF: "FRENCH GUIANA",
  GG: "GUERNSEY",
  GH: "GHANA",
  GI: "GIBRALTAR",
  GL: "GREENLAND",
  GM: "GAMBIA",
  GN: "GUINEA",
  GP: "GUADELOUPE",
  GQ: "EQUATORIAL GUINEA",
  GR: "GREECE",
  GS: "SOUTH GEORGIA ",
  GT: "GUATEMALA",
  GU: "GUAM",
  GW: "GUINEA-BISSAU",
  GY: "GUYANA",
  HK: "HONG KONG",
  HM: "HEARD ISLAND ",
  HN: "HONDURAS",
  HR: "CROATIA",
  HT: "HAITI",
  HU: "HUNGARY",
  ID: "INDONESIA",
  IE: "IRELAND",
  IL: "ISRAEL",
  IM: "ISLE OF MAN",
  IN: "INDIA",
  IO: "BRITISH INDIAN OCEAN TERRITORY",
  IQ: "IRAQ",
  IR: "IRAN ",
  IS: "ICELAND",
  IT: "ITALY",
  JE: "JERSEY",
  JM: "JAMAICA",
  JO: "JORDAN",
  JP: "JAPAN",
  KE: "KENYA",
  KG: "KYRGYZSTAN",
  KH: "CAMBODIA",
  KI: "KIRIBATI",
  KM: "COMOROS",
  KN: "SAINT KITTS AND NEVIS",
  KP: "KOREA ",
  KR: "KOREA, REPUBLIC OF",
  KW: "KUWAIT",
  KY: "CAYMAN ISLANDS",
  KZ: "KAZAKHSTAN",
  LA: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  LB: "LEBANON",
  LC: "SAINT LUCIA",
  LI: "LIECHTENSTEIN",
  LK: "SRI LANKA",
  LR: "LIBERIA",
  LS: "LESOTHO",
  LT: "LITHUANIA",
  LU: "LUXEMBOURG",
  LV: "LATVIA",
  LY: "LIBYA",
  MA: "MOROCCO",
  MC: "MONACO",
  MD: "MOLDOVA, REPUBLIC OF",
  ME: "MONTENEGRO",
  MF: "SAINT MARTIN ",
  MG: "MADAGASCAR",
  MH: "MARSHALL ISLANDS",
  MK: "NORTH MACEDONIA",
  ML: "MALI",
  MM: "MYANMAR",
  MN: "MONGOLIA",
  MO: "MACAO",
  MP: "NORTHERN MARIANA ISLANDS",
  MQ: "MARTINIQUE",
  MR: "MAURITANIA",
  MS: "MONTSERRAT",
  MT: "MALTA",
  MU: "MAURITIUS",
  MV: "MALDIVES",
  MW: "MALAWI",
  MX: "MEXICO",
  MY: "MALAYSIA",
  MZ: "MOZAMBIQUE",
  NA: "NAMIBIA",
  NC: "NEW CALEDONIA",
  NE: "NIGER",
  NF: "NORFOLK ISLAND",
  NG: "NIGERIA",
  NI: "NICARAGUA",
  NL: "NETHERLANDS",
  NO: "NORWAY",
  NP: "NEPAL",
  NR: "NAURU",
  NU: "NIUE",
  NZ: "NEW ZEALAND",
  OM: "OMAN",
  PA: "PANAMA",
  PE: "PERU",
  PF: "FRENCH POLYNESIA",
  PG: "PAPUA NEW GUINEA",
  PH: "PHILIPPINES",
  PK: "PAKISTAN",
  PL: "POLAND",
  PM: "SAINT PIERRE AND MIQUELON",
  PN: "PITCAIRN",
  PR: "PUERTO RICO",
  PS: "PALESTINE, STATE OF",
  PT: "PORTUGAL",
  PW: "PALAU",
  PY: "PARAGUAY",
  QA: "QATAR",
  RE: "RÉUNION",
  RO: "ROMANIA",
  RS: "SERBIA",
  RU: "RUSSIAN FEDERATION",
  RW: "RWANDA",
  SA: "SAUDI ARABIA",
  SB: "SOLOMON ISLANDS",
  SC: "SEYCHELLES",
  SD: "SUDAN",
  SE: "SWEDEN",
  SG: "SINGAPORE",
  SH: "SAINT HELENA",
  SI: "SLOVENIA",
  SJ: "SVALBARD AND JAN MAYEN",
  SK: "SLOVAKIA",
  SL: "SIERRA LEONE",
  SM: "SAN MARINO",
  SN: "SENEGAL",
  SO: "SOMALIA",
  SR: "SURINAME",
  SS: "SOUTH SUDAN",
  ST: "SAO TOME AND PRINCIPE",
  SV: "EL SALVADOR",
  SX: "SINT MAARTEN ",
  SY: "SYRIAN ARAB REPUBLIC",
  SZ: "ESWATINI",
  TC: "TURKS AND CAICOS ISLANDS",
  TD: "CHAD",
  TF: "FRENCH SOUTHERN TERRITORIES",
  TG: "TOGO",
  TH: "THAILAND",
  TJ: "TAJIKISTAN",
  TK: "TOKELAU",
  TL: "TIMOR-LESTE",
  TM: "TURKMENISTAN",
  TN: "TUNISIA",
  TO: "TONGA",
  TR: "TURKEY",
  TT: "TRINIDAD AND TOBAGO",
  TV: "TUVALU",
  TW: "TAIWAN, PROVINCE OF CHINA",
  TZ: "TANZANIA, UNITED REPUBLIC OF",
  UA: "UKRAINE",
  UG: "UGANDA",
  UM: "UNITED STATES MINOR OUTLYING ISLANDS",
  US: "UNITED STATES OF AMERICA",
  UY: "URUGUAY",
  UZ: "UZBEKISTAN",
  VA: "HOLY SEE",
  VC: "SAINT VINCENT AND THE GRENADINES",
  VE: "VENEZUELA",
  VG: "VIRGIN ISLANDS (BRITISH)",
  VI: "VIRGIN ISLANDS (U.S.)",
  VN: "VIET NAM",
  VU: "VANUATU",
  WF: "WALLIS AND FUTUNA",
  WS: "SAMOA",
  YE: "YEMEN",
  YT: "MAYOTTE",
  ZA: "SOUTH AFRICA",
  ZM: "ZAMBIA",
  ZW: "ZIMBABWE",
};

export default {
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia, Plurinational State of",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Democratic Republic of the Congo",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  PF: "French Polynesia",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  HT: "Haiti",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "North Korea",
  KR: "South Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Republic of Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  MX: "Mexico",
  FM: "Micronesia, Federated States of",
  MD: "Republic of Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RO: "Romania",
  RU: "Russian",
  RW: "Rwanda",
  KN: "Saint Kitts and Nevis",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome and Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SX: "Sint Maarten",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  SS: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syria",
  TW: "Taiwan, Province of China",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad and Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks and Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela, Bolivarian Republic of",
  VN: "Viet Nam",
  VI: "Virgin Islands",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};
