import React, { useState } from "react";
import gql from "graphql-tag";

import { Button, Modal } from "react-bootstrap";
import { Context } from "../Utils/Context";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export default function ModalpopUp(props) {
  const mydata = React.useContext(Context);

  const [show, setShow] = useState(false);
  const [exportOTData, setexportOTData] = useState(false);
  const [exportQBData, setexportQBData] = useState(false);
  const [exportPBData, setexportPBData] = useState(false);
  const [exportMATData, setexportMATData] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setexportOTData(false);
    setexportQBData(false);
    setexportPBData(false);
    setexportMATData(false);
    setShow(false);
  };

  const handleDownload = async (e) => {
    //   office timer
    const EXPORT_OT = gql`
      {
        ExportOT(EmpID: "${props.empid}") {
            EmployeeCode
            EmpName
            HiredDate
            AddressLine1
            City
            State
            Country
            PerAddress1
            HomePhoneNo
            MobilePhoneNo
            EMailAddress
            CustomField1
        }
      }
        `;

    //   Paybooks
    const EXPORT_PB_S1 = gql`
    {
      ExportPB(EmpID: "${props.empid}") {
        Sl_No
        Code
        Employee_Name
        Fathers_name
        Gender
        DOB
        email
        Address
        City
        State
        Pin
        Emergency_No
        DOJ
        Confirmation_period
        JobType
        Branch
        PF_No
        PF_Date
        Bank_Ac_No
        PAN
        ESI_No
        Department
        Designation
        Level    
        Cost_Center
        CTC
        FBP
        Variable_pay
        Admin_Bank
        Employee_Bank
        IFSC_code
        Pay_mode
        II_Reporting_Manager
        II_Reporting_Manager_email
        Passport_No
        Passport_Expiry_Date
        Personal_Email
        Blood_Group
        Marital_status
        Salary_Template
        Mobile
        Phone
        Is_Reporting_Manager
        Spouse_Name
        Relationship
        Is_Metro
        Has_ESS
        Has_PF
        PF_UAN
        Is_EPS_Entitle
        Has_ESI
        Is_PF_Restricted
        Grade
        Re_Joiners
        Aadhaar_No
        Is_PF_Employer_Restricted  
      }
    }`;
    const EXPORT_PB_S2 = gql`
    {
      ExportPaybooksPay(EmpID: "${props.empid}") {
        Sl_No
        Code
        Name
        BASIC
        BOOKPERIOD
        CONV
        HRA
        INTERNET
        LTA
        Mobile
        PETRFUEL
        PFEMPYER
        SPLALL
        Varpay
        AdvSal
        OT
        PF
        PT
      }
    }`;

    const EXPORT_PB_S3 = gql`
    {
      ExportPaybooksLeave(EmpID: "${props.empid}") {
        Sl_No
        Code
        Name
        LOP
        SL
        EL20
        WFH
        BL
        ML
      }
    }`

    //  Quickbooks
    const EXPORT_QB = gql`
      {
        ExportQB(EmpID: "${props.empid}") {
            EmployeeID
            Employee
            Gender
            DateofBirth
            HiredDate
            Address
            City
            State
            Country
            DepartmentCode
            Role
            JobTitle
            Phone
            PhoneNumbers
            Email
            Postalcode
        }
      }
   `;

    //   Matrix

    const EXPORT_MAT = gql`
      {
        ExportMat(EmpID: "${props.empid}") {
          UserID
          UserName
          ReferenceID
          Gender
          BloodGroup
          BirthDate
          JoiningDate
          LocAddress1
          LocCity
          LocState
          LocCountry
          PerAddress1
          PerAddress2
          PerCity
          PerState
          PerCountry
          PFNo
          PAN
          VisaNO
          VisaExpiry
          PersonalMobile
          PersonalEmail
          OfficeMobile
          OfficeEmail
        }
      }
    `;
    let funcOT = mydata.client.query({
      query: EXPORT_OT,
    });

    let funcPBS1 = mydata.client.query({
      query: EXPORT_PB_S1,
    });

    let funcPBS2 = mydata.client.query({
      query: EXPORT_PB_S2,
    });

    let funcPBS3 = mydata.client.query({
      query: EXPORT_PB_S3,
    });

    let funcQB = mydata.client.query({
      query: EXPORT_QB,
    });

    let funcMAT = mydata.client.query({
      query: EXPORT_MAT,
    });
    let arrexportfn = [];
    if (exportOTData) arrexportfn.push(funcOT);
    if (exportPBData){arrexportfn.push(funcPBS1); arrexportfn.push(funcPBS2);arrexportfn.push(funcPBS3);} 
    if (exportQBData) arrexportfn.push(funcQB);
    if (exportMATData) arrexportfn.push(funcMAT);

    const [...resultAll] = await Promise.allSettled(arrexportfn); 
    
    let paybookData = [];
    for (let i = 0; i < resultAll.length; i++) {
      let data = [];
      let filename = "";
      let d = new Date();
      let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
      if (resultAll[i].value?.data?.ExportOT) {
        data.push([Object.keys(resultAll[i].value?.data?.ExportOT[0])]);
        data.push([Object.values(resultAll[i].value?.data?.ExportOT[0])]);
        filename = props.empid + "_" + dformat + "_OT.csv";
      }
      //console.log(resultAll[i]);
      // if (resultAll[i].value?.data?.ExportPB) {
      //  // console.log('test',Object.keys(resultAll[i].value?.data?.ExportPB[0]));
      //   let keyArr = Object.keys(resultAll[i].value?.data?.ExportPB[0]);
       
       
      //   for(let k = 0; k< keyArr.length; k++){
      //     if(keyArr[k].includes("_")){
      //       if(!(keyArr[k].includes("Emergency"))){
      //         keyArr[k] = keyArr[k].replaceAll("_", '%20');
      //         console.log(keyArr[k]);
      //         // if(keyArr[k].includes("typename")){
      //         //   keyArr.splice(k, 1);
      //         // }
      //         console.log(keyArr[k]);
      //         if(keyArr[k].includes("Fathers")){
      //           keyArr[k] = keyArr[k].replaceAll("Fathers", "Father's");
      //         }
      //         if(keyArr[k].includes("Marital")){
      //           keyArr[k] = keyArr[k].replaceAll("Marital", "marital");
      //         }
      //         if(keyArr[k].includes("typename")){
      //              keyArr.splice(k, 1);
      //            }
      //         console.log(keyArr[k]);
      //       }
           
      //     }
      //   }
      //   console.log('keyArr', keyArr);
      //   // data.push([Object.keys(resultAll[i].value?.data?.ExportPB[0])]);
      //   data.push(keyArr);

      //   var valueArr = Object.values(resultAll[i].value?.data?.ExportPB[0]);
      //   valueArr.pop();
      //   console.log('valueArr', valueArr);
      //  // console.log('valueArr 1', [Object.values(resultAll[i].value?.data?.ExportPB[0])]);
      //  // Object.values(resultAll[i].value?.data?.ExportPB[0]).pop();
      //  // data.push([Object.values(resultAll[i].value?.data?.ExportPB[0])]);
      //   data.push(valueArr);
      //   filename = props.empid + "_" + dformat + "_PB.csv";
      //   console.log(data);
      
      // }

      if (resultAll[i].value?.data?.ExportPB) {
        delete resultAll[i].value?.data?.ExportPB[0].__typename
        // let myobj = resultAll[i].value?.data?.ExportPB[0];
        // Object.keys(resultAll[i].value?.data?.ExportPB[0]).forEach( k =>{ 
        //   if(myobj[k]?.includes("N/A")){ myobj[k] = ''}
        //   if(k.includes("_") && k !=='Emergency_No'){
        //     if(k=='Fathers_name') myobj[`Father's name`] = myobj[k];
        //     else myobj[k.replaceAll("_", " ")] = myobj[k];
        //     delete myobj[k];
        // }});
        let result = {};
        Object.keys(resultAll[i]?.value?.data?.ExportPB[0]).forEach(k => {
          if(k.includes("_") && k !=='Emergency_No')
            result[k.replaceAll("_", " ")] = resultAll[i].value?.data?.ExportPB[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPB[0][k];
          else 
            result[k] = resultAll[i].value?.data?.ExportPB[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPB[0][k];
        }); 
        paybookData.push([{...result, 'Sl No':'1'}]);
        continue;
      }
      if (resultAll[i].value?.data?.ExportPaybooksPay) {
        delete resultAll[i]?.value?.data?.ExportPaybooksPay[0].__typename
        // let myobj = resultAll[i].value?.data?.ExportPaybooksPay[0];
        // Object.keys(resultAll[i].value?.data?.ExportPaybooksPay[0]).forEach(k =>{ 
        //   if(myobj[k]?.includes("N/A")){ myobj[k] = ''}
        //   if(k.includes("_")){ myobj[k.replaceAll("_", " ")] = myobj[k];delete myobj[k];}
        // });
        let result = {};
        Object.keys(resultAll[i]?.value?.data?.ExportPaybooksPay[0]).forEach(k => {
          if(k.includes("_"))
            result[k.replaceAll("_", " ")] = resultAll[i].value?.data?.ExportPaybooksPay[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPaybooksPay[0][k];
          else 
            result[k] = resultAll[i].value?.data?.ExportPaybooksPay[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPaybooksPay[0][k];
        });
        paybookData.push([{...result, 'Sl No':'1'}]);
        continue;
      }
      if (resultAll[i].value?.data?.ExportPaybooksLeave) {
        delete resultAll[i]?.value?.data?.ExportPaybooksLeave[0].__typename
        // let myobj = resultAll[i].value?.data?.ExportPaybooksLeave[0];
        // Object.keys(resultAll[i].value?.data?.ExportPaybooksLeave[0]).forEach(k =>{ 
        //   if(myobj[k]?.includes("N/A")){ myobj[k] = ''}
        //   if(k.includes("_")){ myobj[k.replaceAll("_", " ")] = myobj[k]; delete myobj[k];}
        // });
        // paybookData.push(resultAll[i].value?.data?.ExportPaybooksLeave);
        // continue;
        let result = {};
        Object.keys(resultAll[i]?.value?.data?.ExportPaybooksLeave[0]).forEach(k => {
          if(k.includes("_"))
            result[k.replaceAll("_", " ")] = resultAll[i].value?.data?.ExportPaybooksLeave[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPaybooksLeave[0][k];
          else 
            result[k] = resultAll[i].value?.data?.ExportPaybooksLeave[0][k]=="N/A" ? '' : resultAll[i].value?.data?.ExportPaybooksLeave[0][k];
        });
        paybookData.push([{...result, 'Sl No':'1'}]);
        continue;
      }

      if (resultAll[i].value?.data?.ExportQB) {
        data.push([Object.keys(resultAll[i].value?.data?.ExportQB[0])]);
        data.push([Object.values(resultAll[i].value?.data?.ExportQB[0])]);
        filename = props.empid + "_" + dformat + "_QB.csv";
      }
      if (resultAll[i].value?.data?.ExportMat) {
        data.push([Object.keys(resultAll[i].value?.data?.ExportMat[0])]);
        data.push([Object.values(resultAll[i].value?.data?.ExportMat[0])]);
        filename = props.empid + "_" + dformat + "_MAT.csv";
      }

      let csvRowData = [];
      for (let i = 0; i < data.length; i++) csvRowData.push(data[i].join(","));

      let csvString = csvRowData.join("%0A");
      console.log(data, csvRowData, csvString);

      let a = document.createElement("a");
      a.href = "data:attachment/csv," + csvString;
      a.target = "_blank";
      a.download = `${filename === "" ? "file.csv" : filename}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    if(paybookData.length > 0){
      // let d = new Date();
      // let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
      // let filename = props.empid + "_" + dformat + "_PB.xlsx";
      // const ws = XLSX.utils.json_to_sheet(paybookData[0]);
      // const ws1 = XLSX.utils.json_to_sheet(paybookData[1]);
      // const ws2 = XLSX.utils.json_to_sheet(paybookData[2]);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws,"Employee");
      // XLSX.utils.book_append_sheet(wb, ws1,"Pay");
      // XLSX.utils.book_append_sheet(wb, ws2,"Leave");
      // const excelBuffer = XLSX.writeFile(wb, { bookType: 'xlsx', type: 'array' }); 
      // const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      // FileSaver.saveAs(data, filename);
      console.log('handle PB',paybookData);
      let d = new Date();
      let dformat = `${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}`;
      const filename = props.empid + "_" + dformat + "_PB";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(paybookData[0],{origin:'A3'});
      const ws1 = XLSX.utils.json_to_sheet(paybookData[1],{origin:'B4'});
      const ws2 = XLSX.utils.json_to_sheet(paybookData[2],{origin:'B4'});
      const ws3 = XLSX.utils.json_to_sheet([{'Sl. No' :'', 'Employee Code':'','Name':''}],{origin:'A4'});
      XLSX.utils.book_append_sheet(wb, ws, 'Employee');
      XLSX.utils.book_append_sheet(wb, ws1, 'Pay');
      XLSX.utils.book_append_sheet(wb, ws2, 'Leave');
      XLSX.utils.book_append_sheet(wb, ws3, 'UDF');
      XLSX.writeFile(wb, `${filename}.xls`); 
    }
    handleClose();
  };

  const handleChange = (e) => {
    if (e.target.name === "exportOTData") setexportOTData(e.target.checked);
    if (e.target.name === "exportQBData") setexportQBData(e.target.checked);
    if (e.target.name === "exportPBData") setexportPBData(e.target.checked);
    if (e.target.name === "exportMATData") setexportMATData(e.target.checked);
  };

  return (
    <>
      {/* <button class="btn btn-primary view-btn" onClick={handleShow}>
        Export
      </button> */}
      <span style={{ cursor: "pointer" }}>
        <i
          title="Export Data"
          class="fa fa-file-excel-o "
          aria-hidden="true"
          onClick={handleShow}
        ></i>
      </span>
      {/* <img src="https://cdn-images-1.medium.com/max/2600/1*694y5GwOWsqflOtu_4Lj-g.png" class="imagepreview" style="width: 100%;"></img> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>EXPORT DATA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="exportname">
            <label class="">
              <input
                class=""
                type="checkbox"
                name="exportOTData"
                checked={exportOTData}
                onChange={handleChange}
              />
              Export Office timer
            </label>
          </div>
          <div class="exportname">
            <label>
              <input
                class=""
                type="checkbox"
                name="exportPBData"
                checked={exportPBData}
                onChange={handleChange}
              />
              Export Paybooks
            </label>
          </div>
          <div class="exportname">
            <label class="">
              <input
                class=""
                type="checkbox"
                name="exportQBData"
                checked={exportQBData}
                onChange={handleChange}
              />
              Export Quickbooks
            </label>
          </div>
          <div class="exportname">
            <label class="">
              <input
                class=""
                type="checkbox"
                name="exportMATData"
                checked={exportMATData}
                onChange={handleChange}
              />
              Matrix
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDownload}>
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
